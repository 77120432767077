import React from 'react';
import styled from 'styled-components';

import { DefaultCandidatePhoto } from '../../../assets/images';
import Conversation from '../../../types/Conversation';
import colors from '../../../theme/colors';
import { toHoursDate } from '../../../utils/dateUtils';

const PortraitContainer = styled.div`
  position: relative;

  max-width: 5rem;
`;

const StyledPicture = styled.img`
  width: 5rem;
  height: 5rem;

  border-radius: 50%;
`;

const StyledRadioDot = styled.div`
  position: absolute;
  top: 0;
  right: 0rem;
  width: 1rem;

  width: 1.2rem;
  height: 1.2rem;

  border-radius: 50%;
  border-width: 0px;

  background-color: ${colors.red};
`;

const StyledCandidateInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  margin: 0 1rem;
`;

const StyledTextLow = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};
`;

const StyledConversationListItem = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr 4rem;
  grid-template-rows: 6rem;
  margin: 1rem;

  cursor: pointer;
`;

interface ConversationListItemProps {
  conversation: Conversation;
}

const ConversationListItem: React.FC<ConversationListItemProps> = ({ conversation }) => {
  const recipient = conversation.recipient;

  return (
    <StyledConversationListItem>
      <PortraitContainer>
        <StyledPicture src={recipient.photo || DefaultCandidatePhoto} />
        {!conversation.seen && <StyledRadioDot />}
      </PortraitContainer>
      <StyledCandidateInformation>
        <div>{recipient.fullname}</div>
        <StyledTextLow>{recipient.title}</StyledTextLow>
      </StyledCandidateInformation>
      <StyledTextLow>{conversation.lastMessageDate && toHoursDate(conversation.lastMessageDate)}</StyledTextLow>
    </StyledConversationListItem>
  );
};

export default ConversationListItem;
