import React from 'react';
import styled from 'styled-components';

import Modal from '../../../components/Modal';
import TemplateListController from './TemplateListController';
import TemplateListItemNewController from './TemplateListItemNewController';
import { colors } from '../../../theme';

export interface TemplateListModalProps {
  onClick?: () => void;
  setModalOpen: (status: boolean) => void;
}

const StyledTitle = styled.div`
  padding: 2rem 1rem;
  font-size: 2rem;

  background: ${colors.gray_light_l1};
  border-bottom: 1px solid ${colors.gray_light};
`;

const TemplateListModal: React.FC<TemplateListModalProps> = ({ setModalOpen }) => {
  return (
    <Modal setModalOpen={setModalOpen}>
      <StyledTitle>Template Administration</StyledTitle>
      <TemplateListItemNewController />
      <TemplateListController />
    </Modal>
  );
};

export default TemplateListModal;
