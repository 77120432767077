import gql from 'graphql-tag';
import { CVParts } from './fragments.gql';

const query = gql`
  query DownloadCVById($uploadDocId: String!) {
    grad_upload_doc(where: { upload_doc_id: { _eq: $uploadDocId } }) {
      ...CVParts
    }
  }
  ${CVParts}
`;

export default query;
