import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import TextAreaInput from '../../../components/TextAreaInput';
import { Send } from '../../../assets/vectors';
import PrimaryButton from '../../../components/PrimaryButton';
import { colors } from '../../../theme';
import TemplatePickerController from '../template/TemplatePickerController';

const StyledMessageSender = styled.div`
  display: grid;
  grid-template-columns: 1fr 8rem;
  grid-template-rows: 10rem;

  &&& > * {
  }
`;

const StyledTextAreaInput = styled(TextAreaInput)`
  &&& {
    max-height: 5rem;
    resize: none;

    border-radius: 10px;
    border-style: none;
    background: ${colors.gray_l2};
  }
`;

const StyledSendButton = styled(PrimaryButton)<{ disabled: boolean }>`
  margin: auto;

  max-height: 5rem;
  max-width: 5rem;
  min-width: 5rem;
  min-height: 5rem;

  border-radius: 10px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const StyledSendIcon = styled(Send)`
  width: 2.4rem;
  height: 2.4rem;

  ::selection {
    background: none;
  }
`;

const StyledSMS = styled.div`
  position: relative;

  margin: auto 1rem;
`;

const StyledTemplatePicker = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 0;
`;

interface MessageSenderProps {
  disabled: boolean;
  onClickSend: (text: string) => void;
}

const MessageSender: React.FC<MessageSenderProps> = ({ onClickSend, disabled }) => {
  const [textArea, setTextArea] = useState('');

  const handleChange = (text: string) => {
    setTextArea(text);
  };

  const handleOnClick = () => {
    if (disabled) return;
    onClickSend(textArea);
    setTextArea('');
  };

  return (
    <StyledMessageSender>
      <StyledSMS>
        <StyledTextAreaInput value={textArea} onChange={handleChange} />
        <StyledTemplatePicker>
          <TemplatePickerController handleClick={setTextArea} />
        </StyledTemplatePicker>
      </StyledSMS>
      <StyledSendButton disabled={disabled || textArea.length === 0} onClick={handleOnClick}>
        <StyledSendIcon />
      </StyledSendButton>
    </StyledMessageSender>
  );
};

export default MessageSender;
