import React, { useEffect } from 'react';

import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';

const AuthLogout: React.FC = () => {
  const { doLogout } = useAuth();

  useEffect(() => {
    doLogout();
  }, [doLogout]);

  return <SpinnerIsoType fullPage text="Logout" />;
};

export default AuthLogout;
