import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import NotFoundView from 'elitegrad-common/src/components/NotFoundView';
import Job from 'elitegrad-common/src/types/Job';
import { EditJobForm } from 'views/job';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';

interface EditJobProps {
  job: Job;
  loadingJob: boolean;
  onSave: (job: Job) => void;
}

const EditJob: React.FC<EditJobProps> = ({ job, loadingJob, onSave }) => {
  const history = useHistory();

  const handleOnSave = useCallback(
    async (thejob: Job) => {
      await onSave(thejob);
      history.goBack();
    },
    [history, onSave],
  );

  if (loadingJob) {
    return <SpinnerIsoType text="Loading" />;
  }

  if (!job) {
    return <NotFoundView />;
  }

  return <EditJobForm job={job} onCancel={history.goBack} onSave={handleOnSave} />;
};

export default EditJob;
