import React from 'react';
import LaptopIcon from 'elitegrad-common/src/assets/vectors/Laptop.svg';
import CalendarIcon from 'elitegrad-common/src/assets/vectors/Calendar.svg';
import Candidates from 'elitegrad-common/src/assets/vectors/CandidatesWhite.svg';
import moment from 'moment';

import {
  CardContainer,
  CardHeader,
  CardHeaderIcons,
  DateContainer,
  HiddenTag,
  ActiveTag,
  HiddenContainer,
  Separator,
  TitleContainer,
} from './EventCard.styled';

interface EventCardProps {
  title: string;
  picture: string;
  startDate: Date;
  endDate: Date;
  isVirtual: boolean;
  capacity: number;
  isHidden: boolean;
}

const EventCard: React.FC<EventCardProps> = ({ title, picture, startDate, endDate, isVirtual, capacity, isHidden }) => {
  return (
    <CardContainer>
      <CardHeader>
        <HiddenContainer>
          {isHidden ? <HiddenTag>HIDDEN</HiddenTag> : <ActiveTag>ACTIVE</ActiveTag>}
          <Separator />
          <img width="90px" height="90px" src={picture || CalendarIcon} alt={title} />
        </HiddenContainer>
        <CardHeaderIcons>
          {isVirtual && (
            <span>
              <img width="16px" height="16px" src={LaptopIcon} alt="virtual event" />
            </span>
          )}
          {capacity > 0 && (
            <span>
              <img src={Candidates} width="12px" height="12px" alt="capacity" />
              {capacity}
            </span>
          )}
        </CardHeaderIcons>
      </CardHeader>
      <TitleContainer>{title}</TitleContainer>
      <DateContainer>
        {moment(startDate).format('MM/DD/YYYY')} {moment(startDate).format('hh:mma')} -{' '}
        {moment(endDate).format('MM/DD/YYYY')} {moment(endDate).format('hh:mma')}
      </DateContainer>
    </CardContainer>
  );
};

export default EventCard;
