import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import InternshipTag from 'elitegrad-common/src/components/InternshipTag';
import colors from 'elitegrad-common/src/theme/colors';
import Job from 'elitegrad-common/src/types/Job';

moment.updateLocale('en', {
  relativeTime: {
    future: '%s left',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

const isActive = (job: Job) => {
  return !isExpired(job) && job.active;
};

const isExpired = (job: Job) => {
  if (!job.deadline) {
    return false;
  }

  return moment(job.deadline).isBefore(moment());
};

const relativeDeadline = (job: Job) => {
  if (!job.deadline) {
    return null;
  }

  return moment(job.deadline).endOf('day').fromNow();
};

const StyledActiveTag = styled.span`
  margin-right: 0.5rem;

  font-size: 1.2rem;
  color: ${colors.green_light};
  line-height: 1.4rem;
  text-transform: uppercase;
`;

const StyledDescription = styled.div`
  flex-grow: 1;

  overflow: scroll;

  margin-top: 1.2rem;

  font-size: 1.5rem;
  line-height: 2.3rem;
  color: ${colors.black};
  opacity: 0.7;
`;

const StyledExpiredTag = styled.span`
  font-size: 1.2rem;
  color: ${colors.gray};
  line-height: 1.4rem;
  text-transform: uppercase;
`;

const StyledExpiration = styled.span`
  font-size: 1.2rem;
  color: ${colors.gray};
  line-height: 1.4rem;
`;

const StyledJobCard = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 26.5rem;
  height: 25.8rem;

  padding: 1.4rem 1.8rem;

  box-shadow: 0 2px 17px ${colors.gray_light};
  border-radius: 8px;

  background-color: ${colors.white};
`;

const StyledMeta = styled.div`
  min-height: 1.9rem;
`;

const StyledRegion = styled.div`
  min-height: 1.4rem;

  margin-top: 1rem;

  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${colors.black};
`;

const StyledRaisedHands = styled.div`
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.6rem;
  color: ${colors.black};
`;

const StyledTitle = styled.div`
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: ${colors.black};
`;

export interface JobCardProps {
  job: Job;
}

const RaisedHands: React.FC<JobCardProps> = ({ job }) => {
  const description = `${job.candidatesUserIds.length} Raised Hand${job.candidatesUserIds.length !== 1 ? 's' : ''}`;

  return <StyledRaisedHands>{description}</StyledRaisedHands>;
};

const JobCard: React.FC<JobCardProps> = ({ job }) => {
  return (
    <StyledJobCard>
      <StyledMeta>
        {isActive(job) && <StyledActiveTag>Active</StyledActiveTag>}
        {isExpired(job) && <StyledExpiredTag>Expired</StyledExpiredTag>}
        {!isExpired(job) && <StyledExpiration>{relativeDeadline(job)}</StyledExpiration>}
      </StyledMeta>
      <StyledRegion>{job.region}</StyledRegion>
      <StyledTitle>{job.title}</StyledTitle>
      <InternshipTag job={job} />
      <StyledDescription>{job.description}</StyledDescription>
      <RaisedHands job={job} />
    </StyledJobCard>
  );
};

export default JobCard;
