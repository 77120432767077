import { StylesConfig } from 'react-select';

import colors from './colors';
import { CSSProperties } from 'styled-components';

const selectorStyles: StylesConfig = {
  control: (provided: CSSProperties) => ({
    ...provided,
    borderColor: colors.gray_l1,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    maxHeight: '18rem',
    color: colors.gray_dark,
    textAlign: 'left',
  }),
};

export default selectorStyles;
