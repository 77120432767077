import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import GET_RECIPIENTS_BY_NAME from '../../../queries/getRecipientsByName.gql';
import { asRecipient } from '../../../transformers/recipient';
import { GetRecipientsByName, GetRecipientsByNameVariables } from '../../../generated/GetRecipientsByName';
import Recipient from '../../../types/Recipient';
import RecipientSelector from './RecipientSelector';

interface RecipientSelectorControllerProps {
  selectedRecipients?: Recipient[];
  setSelectedRecipients: (recipients: Recipient[]) => void;
}

const RecipientSelectorController: React.FC<RecipientSelectorControllerProps> = ({
  setSelectedRecipients,
  selectedRecipients = [],
}) => {
  const [localLoading, setLocalLoading] = useState(true);

  const [getNames, { data }] = useLazyQuery<GetRecipientsByName, GetRecipientsByNameVariables>(GET_RECIPIENTS_BY_NAME, {
    onCompleted: () => {
      setLocalLoading(false);
    },
    onError: () => {
      setLocalLoading(false);
    },
  });

  useEffect(() => {
    getNames({ variables: { name: `% %`, limit: 10 } });
  }, [getNames]);

  const recipients = data?.grad_profile.map(asRecipient) || [];

  const handleChangeSearchText = (value: string) => {
    setLocalLoading(true);
    setTimeout(() => {
      setLocalLoading(true);
      getNames({ variables: { name: `%${value}%`, limit: 100 } });
    }, 1000);
  };

  const addRecipient = (recipient: Recipient) => {
    if (selectedRecipients.some(({ id }) => recipient.id === id)) return;

    setSelectedRecipients([...selectedRecipients, recipient]);
  };

  const removeRecipient = (recipient: Recipient) => {
    setSelectedRecipients(selectedRecipients.filter(({ id }) => recipient.id !== id));
  };

  return (
    <RecipientSelector
      onChangeSearchText={handleChangeSearchText}
      removeRecipient={removeRecipient}
      addRecipient={addRecipient}
      selectedRecipients={selectedRecipients}
      loadingRecipients={localLoading}
      recipients={recipients}
    />
  );
};

export default RecipientSelectorController;
