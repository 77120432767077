import gql from 'graphql-tag';

import { VolunteerActivitiesParts } from './fragments.gql';

export default gql`
  query GetVolunteerActivities($candidateId: String!) {
    grad_volunteer(where: { user_id: { _eq: $candidateId } }) {
      ...VolunteerActivitiesParts
    }
  }
  ${VolunteerActivitiesParts}
`;
