import React from 'react';
import styled, { css } from 'styled-components';
import CandidateHeaderButtonIcon from './CandidateHeaderButtonIcon';

const StyledShowStatus = styled.div<{ disabled?: boolean }>`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 2.4rem;
  grid-template-areas: 'icon legend';

  min-width: 17rem;

  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const StyledText = styled.div`
  grid-area: legend;

  margin-left: 1rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
`;

interface CandidateHeaderButtonProps {
  onClick: () => void;
  text: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  disabled?: boolean;
}

const CandidateHeaderButton: React.FC<CandidateHeaderButtonProps> = ({
  onClick,
  text,
  icon,
  disabled = false,
  children,
}) => {
  return (
    <StyledShowStatus disabled={disabled} onClick={disabled ? () => null : onClick}>
      <CandidateHeaderButtonIcon Icon={icon} />

      <StyledText>{text}</StyledText>
      {children}
    </StyledShowStatus>
  );
};

export default CandidateHeaderButton;
