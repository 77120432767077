export default {
  black: '#000000',
  red: '#ee151b',
  pink_light: '#fef6f6',
  green: '#38A169',
  gray: '#848484',
  gray_l2: '#F2F2F2',
  gray_dark: '#4a4a4a',
  gray_extraLight: '#f6f6f6',
  gray_light: '#dddddd',
  gray_light_l1: '#eeeeee',
  gray_light_l2: '#dcdcdc',
  gray_l1: '#aaaaaa',
  blue: '#D7DFED',
  white: '#ffffff',
  green_light: '#81BB4E',
};
