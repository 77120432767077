import gql from 'graphql-tag';

import { AwardParts } from './fragments.gql';

export default gql`
  query GetProfessionalDesignations($candidateId: String!) {
    grad_award(
      where: { user_id: { _eq: $candidateId }, award_category: { _eq: "Professional designation" } }
      order_by: { year_received: desc }
    ) {
      ...AwardParts
    }
  }
  ${AwardParts}
`;
