import React from 'react';
import styled from 'styled-components';

import PrimaryButton from '../../components/PrimaryButton';
import { colors } from '../../theme';
import Group from '../../types/Group';

const StyledModalFooter = styled.div<{ deleteMode: boolean }>`
  display: grid;
  grid-template-areas: 'text cancel save';
  grid-template-columns: auto 10rem 10rem;
  grid-template-rows: 7.3rem;

  padding: 0 1.7rem;

  background-color: ${({ deleteMode }) => (deleteMode ? colors.pink_light : colors.gray_light_l1)};
`;

const StyledFooterText = styled.div`
  grid-area: text;

  margin: auto;

  font-size: 1.2rem;
  line-height: 1.4rem;
`;

const StyledSaveButton = styled(PrimaryButton)<{ deleteMode: boolean }>`
  grid-area: save;

  min-height: 4rem;
  min-width: 9.4rem;
  max-height: 4rem;
  margin: auto 0 auto 0.4rem;

  border-radius: 4px;
  background-color: ${({ deleteMode }) => (deleteMode ? colors.red : colors.black)};
`;

const StyledCancelButton = styled(PrimaryButton)<{ deleteMode: boolean }>`
  grid-area: cancel;

  min-height: 4rem;
  min-width: 9.4rem;
  max-height: 4rem;

  margin: auto 0 auto 0.4rem;

  border-radius: 4px;
  border-color: ${({ deleteMode }) => (deleteMode ? colors.gray_l1 : colors.white)};
  color: ${colors.black};
  background-color: ${colors.white};
`;

const StyledToRemoveGroups = styled.div`
  font-size: 1.2rem;
  line-height: 1.4rem;

  color: ${colors.red};
`;

export interface GroupModalFooterButtonsProps {
  onSave: () => void;
  onCancel: () => void;
  toDeleteGroups?: Group[];
}

const GroupModalFooterButtons: React.FC<GroupModalFooterButtonsProps> = ({ onSave, onCancel, toDeleteGroups = [] }) => {
  const deleteMode = toDeleteGroups.length > 0;
  return (
    <StyledModalFooter deleteMode={deleteMode}>
      {deleteMode && (
        <StyledFooterText>
          <div>Are you sure you want to remove the following groups:</div>
          <StyledToRemoveGroups>{toDeleteGroups.map(({ name }) => `"${name}"`).join(', ')}</StyledToRemoveGroups>
        </StyledFooterText>
      )}
      <StyledCancelButton deleteMode={deleteMode} onClick={onCancel} text={deleteMode ? 'No, Cancel' : 'Cancel'} />
      <StyledSaveButton deleteMode={deleteMode} onClick={onSave} text={deleteMode ? 'Yes, Delete' : 'Save'} />
    </StyledModalFooter>
  );
};

export default GroupModalFooterButtons;
