import React, { useCallback, useState } from 'react';

import JobRequirement from 'elitegrad-common/src/types/JobRequirement';
import queryGeneratorMap from 'elitegrad-common/src/components/filters/queryFilters/mappedQueries';
import { generateWhereQuery, WhereQueryInterface } from 'elitegrad-common/src/queries/queryUtils';
import { grad_profile_bool_exp } from 'elitegrad-common/src/generated/globalTypes';
import RelatedCandidatesController from '../../../components/RelatedCandidates';

import selectorStyles from 'elitegrad-common/src/theme/selectorStyles';
import { BestMatchesSelect } from './JobCandidatesDashboard.styled';

interface JobCandidatesDashboardControllerProps {
  candidatesUserIds: string[];
  jobRequirements: JobRequirement[];
}

const SELECT_VALUES = [
  { value: true, label: 'Best Matches' },
  { value: false, label: 'All' },
];

const JobCandidatesDashboardController: React.FC<JobCandidatesDashboardControllerProps> = ({
  candidatesUserIds,
  jobRequirements,
}) => {
  const [showCandidatesBestMatches, setShowCandidatesBestMatches] = useState(true);

  const getQuery = useCallback(() => {
    const rawQuery = (jobRequirements || [])
      .map((req: JobRequirement) => {
        try {
          const generator = queryGeneratorMap[req.name];
          if (!generator) return null;
          else return generator(req.value);
        } catch (error) {
          console.warn(`Error parsing queryFilter: ${error}`); // eslint-disable-line no-console
          return null;
        }
      })
      .filter((a) => a !== null) as WhereQueryInterface[];

    const userIdQuery: WhereQueryInterface = {
      enabled: true,
      operator: '_and',
      whereQuery: { user_id: { _in: candidatesUserIds } },
    };

    return generateWhereQuery([...rawQuery, userIdQuery]) as grad_profile_bool_exp;
  }, [candidatesUserIds, jobRequirements]);

  const onChangeShowBestMatches = (status: boolean) => setShowCandidatesBestMatches(status);

  return (
    <>
      <BestMatchesSelect
        onChange={({ value }: { value: boolean }) => onChangeShowBestMatches(value)}
        isSearchable={false}
        options={SELECT_VALUES}
        styles={selectorStyles}
        defaultValue={SELECT_VALUES[0]}
      />
      <RelatedCandidatesController
        toggleLabelLeft={'Raised Hand'}
        candidatesUserIds={candidatesUserIds}
        optionalQuery={showCandidatesBestMatches ? getQuery() : null}
      />
    </>
  );
};

export default JobCandidatesDashboardController;
