import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Pagination, { PaginationState } from 'elitegrad-common/src/components/pagination';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import { jobPath, Paths } from 'routes';
import JobType from 'elitegrad-common/src/types/Job';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import Page from '../Page';
import JobCard from './JobCard';
import Header from 'elitegrad-common/src/components/Header';
import { colors } from 'elitegrad-common/src/theme';

const StyledJobCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 26.5rem);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  padding: 2rem;

  @media only screen and (min-width: 900px) {
    grid-template-columns: repeat(3, 26.5rem);
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, 26.5rem);
  }

  @media only screen and (min-width: 1400px) {
    grid-template-columns: repeat(4, 26.5rem);
  }

  @media only screen and (min-width: 1600px) {
    grid-template-columns: repeat(5, 26.5rem);
  }
`;

const StyledCount = styled.div`
  margin-right: 1rem;
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};

  &&& {
    padding: 0 2rem 1rem;
  }
`;

const StyledHeaderButton = styled(PrimaryButton)`
  margin-left: 2rem;
`;

const StyledPaginationWrapper = styled.div`
  text-align: center;
`;

interface JobsProps {
  jobs: JobType[];
  loadingJobs: boolean;
  pagination: PaginationState;
  onChangePaginationOffset: (offset: number) => void;
}

const Jobs: React.FC<JobsProps> = ({ jobs, loadingJobs, pagination, onChangePaginationOffset }) => {
  const headerComponent = (
    <Header title="Jobs">
      <Link to={Paths.newJob}>
        <StyledHeaderButton big text="Create new" />
      </Link>
    </Header>
  );

  return (
    <Page headerComponent={headerComponent}>
      {loadingJobs ? (
        <SpinnerIsoType text="Loading" />
      ) : (
        <>
          <StyledCount>
            {loadingJobs ? '' : `Showing ${jobs.length} job${jobs.length === 1 ? '' : 's'} of ${pagination.count}`}
          </StyledCount>
          <StyledJobCards>
            {jobs.map((job) => (
              <Link to={jobPath(job.id)} key={job.id}>
                <JobCard job={job} />
              </Link>
            ))}
          </StyledJobCards>
          <StyledPaginationWrapper>
            <Pagination pagination={pagination} onPageChange={onChangePaginationOffset} />
          </StyledPaginationWrapper>
        </>
      )}
    </Page>
  );
};

export default Jobs;
