import React from 'react';
import styled from 'styled-components';

import { DefaultCandidatePhoto } from '../../../assets/images';

import colors from '../../../theme/colors';
import Recipient from '../../../types/Recipient';
import { Link } from 'react-router-dom';

const StyledPicture = styled.img`
  width: 10rem;
  height: 10rem;

  border-radius: 50%;
`;

const StyledCandidateInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  margin: 0 1rem;
`;

const StyledTextLow = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};
`;

const StyledChatRecipientIdentity = styled(Link)`
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-template-rows: 10rem;
  margin: 1rem;

  cursor: pointer;
`;

interface ChatRecipientIdentityProps {
  recipient: Recipient;
  candidatePath: string;
}

const ChatRecipientIdentity: React.FC<ChatRecipientIdentityProps> = ({ recipient, candidatePath }) => {
  return (
    <StyledChatRecipientIdentity to={candidatePath}>
      <StyledPicture src={recipient.photo || DefaultCandidatePhoto} />
      <StyledCandidateInformation>
        <div>{recipient.fullname}</div>
        <StyledTextLow>{recipient.title}</StyledTextLow>
      </StyledCandidateInformation>
    </StyledChatRecipientIdentity>
  );
};

export default ChatRecipientIdentity;
