import React from 'react';
import {
  ToggleContainer,
  ToggleLabelLeft,
  ToggleLabelRight,
  ButtonBackground,
  ButtonCircle,
  ButtonContainer,
} from './Toggle.styled';

interface ToggleProps {
  onChange: (status: boolean) => void;
  onLeft: boolean;
  labelLeft: string;
  labelRight: string;
}

const Toggle: React.FC<ToggleProps> = ({ onChange, onLeft, labelLeft, labelRight }) => {
  return (
    <ToggleContainer>
      <ToggleLabelLeft left={onLeft}>{labelLeft}</ToggleLabelLeft>
      <ButtonContainer onClick={() => onChange(!onLeft)}>
        <ButtonBackground />
        <ButtonCircle left={onLeft} />
      </ButtonContainer>
      <ToggleLabelRight left={onLeft}>{labelRight}</ToggleLabelRight>
    </ToggleContainer>
  );
};

export default Toggle;
