import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../theme';

import Recipient from '../../../types/Recipient';
import CrossBlue from '../../../assets/vectors/CrossBlue.svg';

const StyledRecipientTag = styled.div`
  display: inline-block;
  flex-grow: 1;

  height: 2.5rem;
  min-width: 10rem;
  max-width: 24rem;
  margin: 0.5rem;
  padding: 0.4rem 2.6rem;
  border-radius: 100px;

  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  color: ${colors.black};
  background-color: ${colors.blue};
  background-image: url(${CrossBlue});
  background-repeat: no-repeat;
  background-position: 97%;

  cursor: pointer;
`;

const StyledSearchRecipientDisplay = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  max-height: 4rem;
  margin: 1rem;
  overflow: scroll;

  border-radius: 8px;
  border: 1px solid ${colors.gray_light};
  background-color: ${colors.gray_light_l1};
`;

const StyledRecipientCount = styled.div`
  position: sticky;
  right: -1rem;

  padding: 1rem 2rem;

  color: ${colors.gray_dark};
  background: ${colors.gray_light_l1};
  border-left: 1px solid ${colors.gray_light};
  border-right: 3px solid ${colors.gray_light};
`;

const RecipientDisplay: React.FC<{ recipients?: Recipient[] }> = ({ recipients = [] }) => {
  return (
    <StyledSearchRecipientDisplay>
      {recipients.map((recipient) => (
        <StyledRecipientTag key={recipient.id}>{recipient.fullname}</StyledRecipientTag>
      ))}
      {recipients.length > 0 && <StyledRecipientCount>{recipients.length}</StyledRecipientCount>}
    </StyledSearchRecipientDisplay>
  );
};

export default RecipientDisplay;
