import React from 'react';
import styled from 'styled-components';

const StyledGPALabel = styled.span<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.4rem' : '1.2rem')};
  font-weight: 700;
  line-height: 1.5rem;
`;

const StyledGPAValue = styled.span<{ big: boolean }>`
  margin-left: 0.6rem;

  font-size: ${({ big }) => (big ? '1.8rem' : '1.4rem')};
  line-height: 1.4rem;
`;

const StyledGPAOutOf = styled.span<{ big: boolean }>`
  margin-right: 0.4rem;

  font-size: ${({ big }) => (big ? '1.8rem' : '1.4rem')};
  line-height: 1.4rem;
`;

interface CandidateGPADisplayProps {
  gpa: number | null;
  gpaOutOf: number | null;
  gpaNotApplicable: boolean;
  big?: boolean;
}

const CandidateGPADisplay: React.FC<CandidateGPADisplayProps> = ({ gpa, gpaOutOf, big = false, gpaNotApplicable }) => {
  return (
    <>
      {gpaNotApplicable ? (
        <StyledGPALabel big={big}> GPA Not applicable</StyledGPALabel>
      ) : (
        <>
          <StyledGPALabel big={big}>GPA</StyledGPALabel>
          <StyledGPAValue big={big}>{gpa}</StyledGPAValue>
          <StyledGPAOutOf big={big}>{`/ ${gpaOutOf}`}</StyledGPAOutOf>
        </>
      )}
    </>
  );
};

export default CandidateGPADisplay;
