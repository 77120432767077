import { GetEventDetails } from 'elitegrad-common/src/generated/GetEventDetails';
import { EventDetailProps } from './EventDetail';
import { EVENT_VIRTUAL } from 'utils/constants';
import { timestampToLocalDate } from 'utils/dates';

export const transformObjectToEventDetail = (data?: GetEventDetails): EventDetailProps | null => {
  if (!data?.recruit_event_by_pk) return null;

  const {
    address,
    capacity,
    description,
    end_datetime,
    event_id,
    show_event,
    event_type,
    image,
    start_datetime,
    title,
    event_url,
    timezone,
    account: { company },
    event_registrations,
  } = data.recruit_event_by_pk;
  return {
    address: address || '',
    capacity,
    company: company?.name || '',
    description: description || '',
    endDate: timestampToLocalDate(end_datetime, timezone),
    id: event_id,
    image: image || '',
    isHidden: !show_event,
    isVirtual: event_type === EVENT_VIRTUAL,
    link: event_url || '',
    startDate: timestampToLocalDate(start_datetime, timezone),
    title,
    event_registrations: event_registrations.map((e) => e.user_id),
  };
};
