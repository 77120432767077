import React from 'react';
import styled from 'styled-components';

import { NoWorkExperiences } from '../../assets/images';
import { colors } from '../../theme';

const StyledWorkExperience = styled.div`
  display: grid;
  grid-template-columns: 4rem auto;
  grid-template-rows: 6.5rem;
  grid-template-areas: 'img-area text-area';

  padding: 0 1.8rem;

  border-radius: 4px;

  color: ${colors.gray_l1};
`;

const StyledImage = styled.img`
  grid-area: img-area;

  width: 3rem;
  height: 3rem;
  margin: auto;
`;

const StyledText = styled.div`
  grid-area: text-area;

  margin: auto;
  margin-left: 0;

  font-size: 1rem;
  line-height: 1.2rem;
`;

const CandidateNoWorkExperiences: React.FC = () => {
  return (
    <StyledWorkExperience>
      <StyledImage src={NoWorkExperiences} />
      <StyledText>This student has no Work Experience / Prior Internships</StyledText>
    </StyledWorkExperience>
  );
};

export default CandidateNoWorkExperiences;
