import React from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';

import FreeTextFilter from '../FreeTextFilter';

export interface LastNameFilterProps {
  name: string;
}

export const generateQuery = (value: string): WhereQueryInterface => {
  const queryLastNameFilter: WhereQueryInterface = {
    enabled: !!value.length,
    operator: '_and',
    whereQuery: { lastname: { _ilike: `%${value}%` } },
  };

  return queryLastNameFilter;
};

const LastNameFilter: React.FC<LastNameFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);

  const setFilterValue = (value: string) => storeFilterStatus(generateQuery(value), value);

  return (
    <FreeTextFilter
      filterTitle={'Last Name'}
      defaultValue={(filterValue as string) || ''}
      setFilterValue={setFilterValue}
    />
  );
};

export default LastNameFilter;
