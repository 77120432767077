import { useQuery } from '@apollo/client';
import {
  GetCandidatesValidGPAFromIds,
  GetCandidatesValidGPAFromIdsVariables,
} from 'elitegrad-common/src/generated/GetCandidatesValidGPAFromIds';
import GET_CANDIDATES from 'elitegrad-common/src/queries/getCandidatesValidGPA.gql';

export const useCandidatesQuery = (Ids: string[]) => {
  return useQuery<GetCandidatesValidGPAFromIds, GetCandidatesValidGPAFromIdsVariables>(GET_CANDIDATES, {
    variables: {
      Ids,
    },
  });
};
