import { QueryResult, useLazyQuery, useQuery } from '@apollo/client';
import {
  GetCVsCountByUsersId,
  GetCVsCountByUsersIdVariables,
} from 'elitegrad-common/src/generated/GetCVsCountByUsersId';
import {
  GetProfileImagesByUserIds,
  GetProfileImagesByUserIdsVariables,
} from 'elitegrad-common/src/generated/GetProfileImagesByUserIds';
import { GetCVsByUsersId, GetCVsByUsersIdVariables } from 'elitegrad-common/src/generated/GetCVsByUsersId';
import GET_CVS_BY_USERS_ID from 'elitegrad-common/src/queries/getCVsByUsersId.gql';
import CandidateType from 'elitegrad-common/src/types/Candidate';
import GET_CVS_COUNT_BY_USERS_ID from 'elitegrad-common/src/queries/getCVsCountByUsersId.gql';
import GET_PROFILE_IMAGES_BY_USER_IDS from 'elitegrad-common/src/queries/getProfileImagesByUserIds.gql';
import { GetCandidates, GetCandidatesVariables } from 'elitegrad-common/src/generated/GetCandidates';
import GET_CANDIDATES from 'elitegrad-common/src/queries/getCandidates.gql';

import { base64ToBlob, downloadAllBlobFilesAsZip, FileToZip } from '../../../utils/FileManagement';

export const useDownloadCandidateByUsersId = (
  selectedCandidates: CandidateType[],
  setDownloadingCVs: (state: boolean) => void,
) => {
  const [fetchCVsByUsersId] = useLazyQuery<GetCVsByUsersId, GetCVsByUsersIdVariables>(GET_CVS_BY_USERS_ID, {
    onCompleted: (data) => {
      const resumes: FileToZip[] = data?.grad_upload_doc.map((cv) => {
        const candidate = selectedCandidates.find((candidate) => candidate.userId === cv.user_id);
        const blob = base64ToBlob(cv.hex_content.replace('data:application/pdf;base64,', ''), 'application/pdf');
        const fileName = `${candidate ? `${candidate.fullname} - ` : ''} ${cv.filename}`;
        return {
          blob,
          fileName,
        };
      });
      downloadAllBlobFilesAsZip('candidates', resumes);
      setDownloadingCVs(false);
    },
  });
  return fetchCVsByUsersId;
};

export const useCountCVByCandidates = () => {
  return useLazyQuery<GetCVsCountByUsersId, GetCVsCountByUsersIdVariables>(GET_CVS_COUNT_BY_USERS_ID);
};

export const useFetchCandidates = (
  getCandidatesVariables: GetCandidatesVariables,
  showPhoto: boolean,
): [QueryResult<GetCandidates, GetCandidatesVariables>, GetProfileImagesByUserIds | undefined] => {
  const [fetchImages, { data }] = useLazyQuery<GetProfileImagesByUserIds, GetProfileImagesByUserIdsVariables>(
    GET_PROFILE_IMAGES_BY_USER_IDS,
  );

  const query = useQuery<GetCandidates, GetCandidatesVariables>(GET_CANDIDATES, {
    variables: getCandidatesVariables,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (showPhoto && data.grad_profile.length) {
        const candidatesUserIds = data.grad_profile.map((c) => c.user_id);
        fetchImages({
          variables: {
            userIds: candidatesUserIds,
          },
        });
      }
    },
  });

  return [query, data];
};
