import React from 'react';
import styled from 'styled-components';

import Modal from '../../../components/Modal';

import RecipientDisplay from '../recipient/RecipientDisplay';
import Recipient from '../../../types/Recipient';
import MessageSenderController from './MessageSenderController';
import SpinnerIsoType from '../../SpinnerIsoType';

export interface MessageNewModalProps {
  loading: boolean;
  recipients: Recipient[];
  setModalOpen: (status: boolean) => void;
  onSend?: () => void;
}

const StyledTitle = styled.div`
  margin: 2rem 1rem;
  font-size: 2rem;
`;

const MessageNewModal: React.FC<MessageNewModalProps> = ({ loading, setModalOpen, onSend, recipients }) => {
  const handleOnSend = () => {
    setModalOpen(false);
    if (onSend !== undefined) onSend();
  };
  return (
    <Modal heightAuto={true} setModalOpen={setModalOpen}>
      <StyledTitle>Send Message</StyledTitle>
      {loading ? (
        <SpinnerIsoType text={'Loading recipients'} />
      ) : (
        <>
          <RecipientDisplay recipients={recipients} />
          <MessageSenderController onSend={handleOnSend} candidatesIds={recipients.map(({ id }) => id) || []} />
        </>
      )}
    </Modal>
  );
};

export default MessageNewModal;
