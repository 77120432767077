import React from 'react';
import styled from 'styled-components';

import Test from '../../types/Test';
import { colors } from '../../theme';

const StyledTestDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1rem;

  margin-top: 0.2rem;
  padding: 0 1rem 0.5rem;
  min-height: 2rem;

  text-align: center;

  & > *:nth-child(3n + 1),
  *:nth-child(3n + 2) {
    border-right: 1px ${colors.gray_light} solid;
  }
`;

const StyledScoreDetail = styled.span<{ big: boolean }>`
  padding: 0.5rem 0;

  font-size: ${({ big }) => (big ? '1.4rem' : '1rem')};
  line-height: 1.1rem;

  text-transform: capitalize;
`;

interface CandidateTestScoreOthersProps {
  tests: Test[];
  big?: boolean;
}

const CandidateTestScoreOthers: React.FC<CandidateTestScoreOthersProps> = ({ tests, big = false }) => {
  return (
    <StyledTestDetails>
      {tests.map((d) => (
        <StyledScoreDetail big={big} key={d.id}>
          {d.type}: {d.score}
        </StyledScoreDetail>
      ))}
    </StyledTestDetails>
  );
};

export default CandidateTestScoreOthers;
