import React, { useCallback, useEffect, useReducer } from 'react';
import { NoCandidateMessage, ToggleControl, PaginationWrapper, Container } from './RelatedCandidates.styled';
import Toggle from '../common/Toggle';
import reducer, { initialState } from './RelatedCandidates.reducer';
import Pagination from 'elitegrad-common/src/components/pagination';
import CandidateType from 'elitegrad-common/src/types/Candidate';
import CVsList, { CVCardControllerProps } from './CVsList';
import Candidates from './Candidates';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import { PAGE_SIZE } from 'elitegrad-common/src/components/pagination/PaginationState';
import { base64ToBlob, downloadAllBlobFilesAsZip, FileToZip } from '../../utils/FileManagement';
import { asCandidatesWithProfileImages } from 'elitegrad-common/src/transformers/candidates';
import { usePhoto } from 'elitegrad-common/src/utils/usePhoto';
import { generateWhereQuery, WhereQueryInterface } from 'elitegrad-common/src/queries/queryUtils';
import { grad_profile_bool_exp } from 'elitegrad-common/src/generated/globalTypes';
import { useCandidatesLazyQuery, useCvsLazyQuery, useProfileImageLazyQuery } from './RelatedCandidates.hooks';

interface JobCandidatesDashboardProps {
  candidatesUserIds: string[];
  optionalQuery?: grad_profile_bool_exp | null;
  toggleLabelLeft: string;
}

const RelatedCandidatesController: React.FC<JobCandidatesDashboardProps> = ({
  candidatesUserIds,
  optionalQuery = null,
  toggleLabelLeft,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { paginationOffset, resultsCount, showCandidates, showCVs, selectedCandidates } = state;
  const pagination = {
    count: resultsCount,
    offset: paginationOffset,
    limit: PAGE_SIZE,
  };

  const [fetchImages, { data: imagesData }] = useProfileImageLazyQuery();
  const [fetchCVs, { data: cvsData, loading: loadingCVs }] = useCvsLazyQuery();

  const onCompletedFetchCandidates = (data) => {
    dispatch({
      type: 'setResultsCount',
      payload: data?.grad_profile_aggregate.aggregate?.count || 0,
    });
    if (data.grad_profile.length) {
      const userIds = data.grad_profile.map((c) => c.user_id);

      if (showPhoto) fetchImages({ variables: { userIds } });
    }
  };

  const [
    fetchCandidates,
    {
      data: candidatesData,
      loading: loadingCandidates,
      fetchMore: fetchMoreCandidates,
      called: hasBeenFetchedCandidates,
    },
  ] = useCandidatesLazyQuery(onCompletedFetchCandidates);

  const candidatesCount = candidatesData?.grad_profile_aggregate.aggregate?.count || 0;

  const { showPhoto } = usePhoto();

  let candidates = asCandidatesWithProfileImages(
    candidatesData?.grad_profile || [],
    showPhoto ? imagesData?.grad_profile || [] : [],
  );

  candidates = candidates.map((can) => {
    const resumeFound = cvsData?.grad_upload_doc?.find((res) => res.user_id === can.userId);
    if (!resumeFound) return can;
    return {
      ...can,
      resume: {
        uploadDocId: resumeFound.upload_doc_id,
        filename: resumeFound.filename,
        hexContent: resumeFound.hex_content,
      },
    };
  });

  let resumeCandidates = candidates;
  if (Boolean(selectedCandidates.length)) {
    resumeCandidates = candidates.filter((c) => {
      const selectedCandidatesIds = selectedCandidates.map((cand) => cand.userId);
      return selectedCandidatesIds.includes(c.userId);
    });
  }

  const resumes = resumeCandidates.reduce((resumes: CVCardControllerProps[], { resume, fullname, userId }) => {
    if (resume) {
      const { hexContent, filename, uploadDocId } = resume;
      return [
        ...resumes,
        {
          fullName: fullname,
          candidateId: userId,
          hexContent,
          filename,
          uploadDocId,
        },
      ];
    }
    return resumes;
  }, []);

  const getDinamicQuery = useCallback(() => {
    const userIdQuery: WhereQueryInterface = {
      enabled: true,
      operator: '_and',
      whereQuery: { user_id: { _in: candidatesUserIds } },
    };
    return generateWhereQuery([userIdQuery]) as grad_profile_bool_exp;
  }, [candidatesUserIds]);

  if (!hasBeenFetchedCandidates) {
    fetchCandidates({
      variables: {
        dynamicQuery: optionalQuery ? optionalQuery : getDinamicQuery(),
        limit: PAGE_SIZE,
        offset: paginationOffset,
        admin: false,
      },
    });
  }

  const fetchData = useCallback(() => {
    const fetchMore = (query: grad_profile_bool_exp, offset: number, mustShowCVs: boolean) => {
      if (fetchMoreCandidates) {
        fetchMoreCandidates({
          variables: { dynamicQuery: query, offset },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult?.grad_profile.length) {
              const userIds = fetchMoreResult.grad_profile.map((c) => c.user_id);

              if (showPhoto) fetchImages({ variables: { userIds } });

              if (mustShowCVs) fetchCVs({ variables: { userIds } });
            }
            dispatch({
              type: 'setResultsCount',
              payload: fetchMoreResult?.grad_profile_aggregate.aggregate?.count || 0,
            });
            return { ...prev, ...fetchMoreResult };
          },
        });
      }
    };
    const query = optionalQuery ? optionalQuery : getDinamicQuery();
    return fetchMore(query, paginationOffset, showCVs);
  }, [
    showCVs,
    paginationOffset,
    fetchImages,
    fetchCVs,
    fetchMoreCandidates,
    showPhoto,
    getDinamicQuery,
    optionalQuery,
  ]);

  useEffect(() => {
    fetchData();
  }, [optionalQuery, showCVs, fetchData]);

  const onDownloadAllCVs = () => {
    const filesTwo: FileToZip[] = resumeCandidates.reduce((files: FileToZip[], candidate) => {
      if (candidate.resume) {
        const blob = base64ToBlob(
          candidate.resume.hexContent.replace('data:application/pdf;base64,', ''),
          'application/pdf',
        );
        const fileName = `${candidate.fullname} - ${candidate.resume.filename}`;
        return [...files, { fileName, blob }];
      }
      return files;
    }, []);

    downloadAllBlobFilesAsZip('resumes', filesTwo);
  };

  const onShowCandidates = (showCandidateSelected: boolean) =>
    dispatch({ type: showCandidateSelected ? 'showToggleCandidates' : 'showToggleCVs' });

  const onChangePaginationOffset = (offset: number) => dispatch({ type: 'setPaginationOffset', payload: offset });

  const editSelection = (candidate: CandidateType, add: boolean) => {
    if (add) {
      dispatch({ type: 'addCandidateToSelected', payload: candidate });
    } else {
      dispatch({ type: 'removeCandidateFromSelected', payload: candidate });
    }
  };

  if (loadingCVs || loadingCandidates) return <SpinnerIsoType text="Loading" />;
  const hasCandidates = Boolean(candidatesCount);

  if (!loadingCandidates && !hasCandidates) return <NoCandidateMessage>No candidates yet</NoCandidateMessage>;

  return (
    <>
      {hasCandidates && (
        <ToggleControl>
          <Toggle
            onChange={(status) => onShowCandidates(status)}
            onLeft={showCandidates}
            labelLeft={toggleLabelLeft}
            labelRight="Resumes"
          />
        </ToggleControl>
      )}
      <Container>
        {showCandidates ? (
          <Candidates
            selectedCandidates={selectedCandidates}
            editSelection={editSelection}
            candidates={candidates}
            candidatesCount={candidatesCount}
            onChangePaginationOffset={onChangePaginationOffset}
            refetch={fetchData}
          />
        ) : (
          <CVsList downloadAllCVs={onDownloadAllCVs} resumes={resumes} />
        )}
        <PaginationWrapper>
          <Pagination pagination={pagination} onPageChange={onChangePaginationOffset} />
        </PaginationWrapper>
      </Container>
    </>
  );
};

export default RelatedCandidatesController;
