import { WorkExperienceParts } from '../generated/WorkExperienceParts';
import WorkExperience from '../types/WorkExperience';
import { parseBackendStartDate, parseBackendEndDate } from '../utils/dateUtils';
import { asJobType } from './jobType';

const asWorkExperience = (parts: WorkExperienceParts): WorkExperience => {
  const startDate = parseBackendStartDate(parts);
  const endDate = parseBackendEndDate(parts);

  const jobType = parts.job_type ? asJobType(parts.job_type) : null;

  return {
    id: parts.work_experience_id,
    city: parts.city || null,
    company: parts.company,
    startDate,
    endDate,
    jobType,
    position: parts.position,
    state: parts.state || null,
    userId: parts.user_id,
  };
};

export { asWorkExperience };
