import React from 'react';
import colors from '../../theme/colors';

import Company from '../../types/Company';
import styled from 'styled-components';
import HorizontalRuler from '../HorizontalRuler';

const StyledCompany = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 5rem;
`;

const StyledCompanyDetailInfo = styled.div`
  display: grid;

  grid-template-columns: 50rem auto;
  grid-template-rows: 5rem 5rem 5rem 5rem 20rem;
`;

const StyledCompanyBasicInfo = styled.div`
  display: grid;

  margin-bottom: 5rem;
  grid-template-columns: auto 1fr;
  grid-template-rows: 10rem 10rem;

  column-gap: 2rem;
`;

const StyledLogo = styled.img`
  height: 20rem;

  grid-column: 1 / 1;
  grid-row: 1 / 3;

  padding: 2rem;

  border: 1px solid ${colors.gray_l1};
  border-radius: 4px;
  background-color: transparent;
`;

const StyledCompanyName = styled.div`
  font-weight: 600;
  font-size: 4rem;
`;

const StyledCompanyDescription = styled.div`
  max-width: 50rem;
  min-width: 50rem;

  grid-row: 2 / 6;
  overflow: scroll;

  font-size: 1.5rem;

  color: ${colors.gray_dark};
`;
const StyledTitleH1 = styled.div`
  font-size: 2rem;
`;

const StyledTitleH2 = styled.div`
  font-size: 1.5rem;

  color: ${colors.gray_dark};
`;
const StyledLinkH2 = styled.div`
  font-size: 1.5rem;
`;

const StyledRuler = styled(HorizontalRuler)`
  margin: 3rem 0;
`;

const CompanyDisplay: React.FC<{ company: Company }> = ({ company }) => {
  return (
    <StyledCompany>
      <StyledCompanyBasicInfo>
        {company.logo && <StyledLogo src={company.logo} />}
        <StyledCompanyName> {company.name}</StyledCompanyName>
        <StyledTitleH1>
          {company.industry}
          <StyledTitleH2>{company.subIndustries.join(' - ')}</StyledTitleH2>
        </StyledTitleH1>
      </StyledCompanyBasicInfo>

      <StyledCompanyDetailInfo>
        <StyledTitleH1>About the company:</StyledTitleH1>
        <StyledCompanyDescription> {company.description}</StyledCompanyDescription>
        <StyledRuler />
        <StyledLinkH2>{company.website}</StyledLinkH2>
        <StyledTitleH2>Company phone:{company.phone}</StyledTitleH2>
        <StyledTitleH2>Address: {company.address}</StyledTitleH2>
        <StyledTitleH2>Employees: {company.employeesNumber}</StyledTitleH2>
      </StyledCompanyDetailInfo>
    </StyledCompany>
  );
};

export default CompanyDisplay;
