import React from 'react';
import styled from 'styled-components';

import VolunteerActivity from '../../types/Achievement';
import colors from '../../theme/colors';

const StyledTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 1.6rem;

  width: 100%;
`;

const StyledVolunteerActivityLists = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-top: 1.5rem;
  }

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

const StyledVolunteerActivityListTag = styled.div`
  display: inline-block;
  min-width: 3.5rem;

  padding: 0.4rem 0.4rem 0.4rem;
  border-radius: 3px;

  background-color: ${colors.black};

  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: left;
  color: ${colors.white};

  white-space: brake-spaces;
`;

const VolunteerActivities: React.FC<{ volunteerActivities?: VolunteerActivity[] }> = ({ volunteerActivities = [] }) => {
  return (
    <div>
      <StyledTitle>Volunteer/Clubs/Extracurricular Activities:</StyledTitle>
      <StyledVolunteerActivityLists>
        {volunteerActivities.length
          ? volunteerActivities.map((achievement) => (
              <StyledVolunteerActivityListTag key={achievement.id}>
                {achievement.description}
              </StyledVolunteerActivityListTag>
            ))
          : '-'}
      </StyledVolunteerActivityLists>
    </div>
  );
};

export default VolunteerActivities;
