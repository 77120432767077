import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useSubscription } from '@apollo/client';

import Chat from './Chat';
import { asMessage } from '../../../transformers/message';

import UPDATE_CONVERSATION_TIME from '../../../queries/updateConversationSeenTime.gql';
import {
  UpdateConversationSeenTime,
  UpdateConversationSeenTimeVariables,
} from '../../../generated/UpdateConversationSeenTime';
import GET_MESSAGES from '../../../queries/getMessages.gql';
import { GetMessages, GetMessagesVariables } from '../../../generated/GetMessages';
import { User, useAuth } from '../../../utils/react-auth';
import SpinnerIsoType from '../../SpinnerIsoType';

const ChatController: React.FC<{ candidateId?: string; generateCandidatePath: (candidateId: string) => string }> = ({
  candidateId,
  generateCandidatePath,
}) => {
  const { user } = useAuth() as { user: User };
  const { id } = useParams<{ id: string }>();

  const [updateSeenTime] = useMutation<UpdateConversationSeenTime, UpdateConversationSeenTimeVariables>(
    UPDATE_CONVERSATION_TIME,
    {
      variables: {
        recruiter_user_id: user.sub,
        correspondent_user_id: candidateId || id || '',
        last_read_timestamp: new Date().toISOString(),
      },
    },
  );

  useEffect(() => {
    const interval = setInterval(updateSeenTime, 1000);
    return () => clearInterval(interval);
  }, [updateSeenTime]);

  const { data, loading } = useSubscription<GetMessages, GetMessagesVariables>(GET_MESSAGES, {
    skip: !(candidateId || id),
    fetchPolicy: 'network-only',
    variables: {
      candidateId: candidateId || id,
    },
  });

  if (loading) return <SpinnerIsoType text={'Loading conversations'} />;

  const messages = data?.recruit_sms_message.map((sms) => asMessage(sms));

  return <Chat messages={messages} candidateId={candidateId || id} generateCandidatePath={generateCandidatePath} />;
};

export default ChatController;
