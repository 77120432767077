import React from 'react';

import CandidateType from 'elitegrad-common/src/types/Candidate';
import { CandidatesGrid } from '../../../views/candidates';
import { CandidateCount, StyledCount } from './Candidates.styled';
import Candidate from 'elitegrad-common/src/types/Candidate';

export interface JobProps {
  editSelection: (candidate: Candidate, add: boolean) => void;
  selectedCandidates: Candidate[];
  candidates: CandidateType[];
  candidatesCount: number;
  onChangePaginationOffset: (offset: number) => void;
  refetch: () => void;
}

const Candidates: React.FC<JobProps> = ({
  candidates,
  candidatesCount,
  refetch,
  editSelection,
  selectedCandidates,
}) => {
  return (
    <CandidateCount>
      <StyledCount>
        {`Showing ${candidates.length} candidate${candidates.length !== 1 ? 's' : ''} of ${candidatesCount}`}
      </StyledCount>
      <CandidatesGrid
        refetch={refetch}
        candidates={candidates}
        editSelection={editSelection}
        selectedCandidates={selectedCandidates}
      />
    </CandidateCount>
  );
};

export default Candidates;
