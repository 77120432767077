import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';

import VerticalDivider from '../../../components/VerticalDivider';
import HorizontalDivider from '../../../components/HorizontalRuler';

import { Trash as TrashIcon, Save as SaveIcon } from '../../../assets/vectors';
import { colors } from '../../../theme';

import Template from '../../../types/Template';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';

const StyledTemplateItem = styled.div<{ disabled: boolean }>`
  display: grid;
  grid-template-columns: auto 3rem 1rem 3rem;
  grid-template-rows: 2rem 24rem;
  grid-row-gap: 0.5rem;

  margin: 1rem;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const StyledTrash = styled(TrashIcon)`
  width: 2.4rem;
  height: 2.4rem;
  margin: auto;
  & * {
    stroke: ${colors.red};
  }
`;

const StyledEdit = styled(SaveIcon)`
  width: 2.4rem;
  height: 2.4rem;
  padding: 0.2rem;
  margin: auto;
  & * {
    stroke: ${colors.black};
  }
`;

const StyledTitle = styled.div`
  grid-column: 1 / 5;

  font-size: 1.8rem;
  font-weight: 700;
`;

const StyledTitleInput = styled(TextInput)`
  font-size: 1.4rem;
  font-weight: 700;
`;

const StyledContentInput = styled(TextAreaInput)`
  margin: 1rem 0;

  overflow: scroll;
  resize: none;
`;

const StyledHorizontalDivider = styled(HorizontalDivider)`
  grid-column: 1 / 5;
  padding: 0 0.1rem;
`;

interface TemplateListItemEditProps {
  template?: Template;
  disable: boolean;
  componentTitle: string;
  onClickTrash: () => void;
  onClickSave: (content: string, title: string) => void;
  disableSaveMode: () => void;
}

const TemplateListItemEdit: React.FC<TemplateListItemEditProps> = ({
  template,
  disable,
  componentTitle,
  onClickTrash,
  onClickSave,
  disableSaveMode,
}) => {
  const ref = useRef(null);

  const [title, setTitle] = useState(template?.title || '');
  const [content, setContent] = useState(template?.content || '');

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(ref.current as any).contains(e.target)) {
        disableSaveMode();
      }
    },
    [disableSaveMode],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handleClickOutside]);

  return (
    <StyledTemplateItem ref={ref} disabled={disable}>
      <StyledTitle>{componentTitle}</StyledTitle>
      <div>
        <StyledTitleInput placeholder={'Title'} value={title} onChange={setTitle} />
        <StyledContentInput placeholder={'Message Template'} value={content} onChange={setContent} />
      </div>
      <StyledEdit onClick={() => onClickSave(content, title)} />
      <VerticalDivider />
      <StyledTrash onClick={!disable ? onClickTrash : undefined} />
      <StyledHorizontalDivider />
    </StyledTemplateItem>
  );
};

export default TemplateListItemEdit;
