import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../theme/colors';

const StyledBackLink = styled.a`
  padding: 0.5rem 1rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.black};

  :focus {
    outline: none;
  }
`;

const BackLink: React.FC = () => {
  const history = useHistory();

  return <StyledBackLink onClick={history.goBack}>← Back</StyledBackLink>;
};

export default BackLink;
