import React from 'react';
import { useQuery } from '@apollo/client';

import { MessageNewModal } from 'elitegrad-common/src/components/messaging';
import { GetRecipientsById, GetRecipientsByIdVariables } from 'elitegrad-common/src/generated/GetRecipientsById';
import GET_RECIPIENTS_BY_IDS from 'elitegrad-common/src/queries/getRecipientsById.gql';
import { asRecipient } from 'elitegrad-common/src/transformers/recipient';

export interface JobDismissMessageControllerProps {
  candidatesIds: string[];
  setModalOpen: (status: boolean) => void;
}

const JobDismissMessageController: React.FC<JobDismissMessageControllerProps> = ({ setModalOpen, candidatesIds }) => {
  const { data, loading } = useQuery<GetRecipientsById, GetRecipientsByIdVariables>(GET_RECIPIENTS_BY_IDS, {
    variables: { ids: candidatesIds },
  });

  const recipients = data?.grad_profile.map(asRecipient) || [];

  return <MessageNewModal loading={loading} setModalOpen={setModalOpen} recipients={recipients} />;
};

export default JobDismissMessageController;
