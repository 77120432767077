import React from 'react';
import MessageSender from './MessageSender';
import { useAuth, User } from '../../../utils/react-auth';
import { InsertSMSMessage, InsertSMSMessageVariables } from '../../../generated/InsertSMSMessage';
import INSERT_SMS_MESSAGE from '../../../queries/InsertSMSMessage.gql';
import { useMutation } from '@apollo/client';

interface MessageSenderControllerProps {
  candidatesIds: string[];
  onSend?: () => void;
}

const MessageSenderController: React.FC<MessageSenderControllerProps> = ({ candidatesIds, onSend }) => {
  const { user } = useAuth() as { user: User };

  const [sendSMS] = useMutation<InsertSMSMessage, InsertSMSMessageVariables>(INSERT_SMS_MESSAGE);

  const disableSend = candidatesIds.length === 0;
  const onClickSend = (text: string) => {
    if (disableSend) return;
    sendSMS({
      variables: {
        content: text,
        recruiter_user_id: user.sub,
        correspondents: candidatesIds.map((correspondentId) => ({ correspondent_user_id: correspondentId })),
      },
    });
    if (onSend !== undefined) onSend();
  };

  return <MessageSender disabled={disableSend} onClickSend={onClickSend} />;
};

export default MessageSenderController;
