import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme';

import Message from '../../../types/Message';
import moment from 'moment';
import MessageListItem from '../message/MessageListItem';
import MessageSenderController from '../message/MessageSenderController';
import ChatRecipientIdentityController from './ChatRecipientIdentityController';

const StyledMessageList = styled.div`
  display: grid;
  grid-template-rows: 12rem 1fr 10rem;

  height: 100%;
`;

const StyledMessages = styled.ol`
  padding: 2rem;
  overflow-y: scroll;

  list-style-type: none;

  background: ${colors.gray_l2};
  border-top: 1px solid ${colors.gray_light};
`;

const DateBlob = styled.div`
  position: relative;

  width: 14rem;
  height: 3rem;
  margin: auto;

  text-align: center;
  font-size: 1.2rem;
  line-height: 3rem;

  border-radius: 8px;
  background-color: ${colors.blue};
  box-shadow: 0px 2px 6px rgba(10, 22, 70, 0.1);
`;

const blobRequired = (first: Message | undefined, second: Message): boolean =>
  first === undefined ? true : !moment(first.dateSent).isSame(second.dateSent, 'days');

const getBlobText = (first: Message) =>
  moment(first.dateSent).calendar(0, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    sameElse: 'DD/MM/YYYY',
  });

interface ChatProps {
  messages?: Message[];
  candidateId?: string;
  generateCandidatePath: (candidateId: string) => string;
  onSend?: () => void;
}

const Chat: React.FC<ChatProps> = ({ messages = [], candidateId, onSend, generateCandidatePath }) => {
  return (
    <StyledMessageList>
      <ChatRecipientIdentityController candidateId={candidateId} generateCandidatePath={generateCandidatePath} />

      <StyledMessages>
        {messages.map((message, index, array) => (
          <li key={message.id + 'date'}>
            {blobRequired(array[index - 1], message) && index !== 1 && <DateBlob>{getBlobText(message)}</DateBlob>}
            <MessageListItem key={message.id} message={message} />
          </li>
        ))}
      </StyledMessages>

      <MessageSenderController onSend={onSend} candidatesIds={candidateId ? [candidateId] : []} />
    </StyledMessageList>
  );
};

export default Chat;
