import React from 'react';
import { useQuery } from '@apollo/client';

import GET_CANDIDATE_DESIGNATIONS from '../../queries/getProfessionalDesignations.gql';
import {
  GetProfessionalDesignations,
  GetProfessionalDesignationsVariables,
} from '../../generated/GetProfessionalDesignations';

import ProfessionalDesignations from './ProfessionalDesignations';
import { asAward } from '../../transformers/awards';

const ProfessionalDesignationsController: React.FC<{ candidateId: string }> = ({ candidateId }) => {
  const { data } = useQuery<GetProfessionalDesignations, GetProfessionalDesignationsVariables>(
    GET_CANDIDATE_DESIGNATIONS,
    {
      skip: !candidateId,
      variables: { candidateId },
    },
  );

  const awards = data?.grad_award.map((a) => asAward(a)) || [];

  return <ProfessionalDesignations awards={awards} />;
};

export default ProfessionalDesignationsController;
