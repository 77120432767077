import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';
import { usePopper } from '../../utils/usePopper';
import Switch from '../Switch';

import FilterTitle from './FilterTitle';

const StyledFilterItem = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  margin: 0.8rem 2rem 2rem.5rem;
`;

const StyledFilterApply = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledClearApply = styled.div`
  grid-area: save;

  color: ${colors.red};

  text-decoration: underline;

  cursor: pointer;
`;

interface BooleanFilterClearProps {
  filterTitle: string;
  defaultValue: boolean | null;
  setFilterValue: (status: boolean) => void;
  clearFilter: () => void;
}

const BooleanFilterClear: React.FC<BooleanFilterClearProps> = ({
  filterTitle,
  defaultValue = false,
  setFilterValue,
  clearFilter,
}) => {
  const { togglePopper } = usePopper(filterTitle);

  const [valueBool, setValueBool] = useState<boolean>(defaultValue || false);

  const handleClear = () => {
    clearFilter();
    togglePopper();
  };

  const handleApplyFilter = () => {
    setFilterValue(!!valueBool);
    togglePopper();
  };

  return (
    <div>
      <StyledFilterItem>
        <FilterTitle title={filterTitle} />
        <Switch on={valueBool} onChange={setValueBool} />
      </StyledFilterItem>
      <StyledFilterApply>
        <StyledClearApply onClick={handleClear}>Clear</StyledClearApply>
        <StyledClearApply onClick={handleApplyFilter}>Apply</StyledClearApply>
      </StyledFilterApply>
    </div>
  );
};

export default BooleanFilterClear;
