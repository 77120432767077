import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import debounce from 'lodash.debounce';

import ConversationList from './ConversationList';
import { GetConversations, GetConversationsVariables } from '../../../generated/GetConversations';
import GET_CONVERSATIONS from '../../../queries/getConversations.gql';
import { asConversation } from '../../../transformers/conversation';
import Conversation from '../../../types/Conversation';

const ConversationListController: React.FC<{
  pathConversationNew: string;
  generateMessagingPath: (id: string) => string;
}> = ({ pathConversationNew, generateMessagingPath }) => {
  const [localLoading, setLocalLoading] = useState(true);

  const [name, setName] = useState('% %');

  const { data, loading } = useSubscription<GetConversations, GetConversationsVariables>(GET_CONVERSATIONS, {
    onSubscriptionData: () => setLocalLoading(false),
    variables: { name, limit: 20 },
  });

  const { id: candidateId } = useParams<{ id: string }>();

  const conversations = data?.recruit_sms_correspondent.map((sms) => asConversation(sms));

  const debouncedGetConversations = useCallback(
    debounce((value: string) => {
      setName(`%${value}%`);
    }, 600),
    [setName],
  );

  const handleSearchChange = (value: string) => {
    setLocalLoading(true);
    debouncedGetConversations(value);
  };

  return (
    <ConversationList
      candidateId={candidateId}
      conversations={conversations as Conversation[]}
      loading={localLoading || loading}
      onSearchChange={handleSearchChange}
      pathConversationNew={pathConversationNew}
      generateMessagingPath={generateMessagingPath}
    />
  );
};

export default ConversationListController;
