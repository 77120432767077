import React from 'react';
import styled from 'styled-components';

import Sidebar from 'components/SideBar';
import { PopperContextProvider } from 'elitegrad-common/src/utils/usePopper';

const StyledPage = styled.div`
  display: flex;
  flex-direction: row;
`;

const ScrollContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;

  overflow: hidden;

  & > * {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledContent = styled.div`
  position: relative;
  flex-grow: 1;
`;

const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  max-height: 100vh;
`;

export interface PageProps {
  extraSidebar?: React.ReactNode;
  headerComponent?: React.ReactNode;
  footer?: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ extraSidebar, headerComponent, children, footer }) => {
  return (
    <PopperContextProvider>
      <StyledPage>
        <Sidebar />
        <StyledFlexColumn>
          <ScrollContent>
            {extraSidebar}
            <StyledContent>
              <StyledHeader>{headerComponent}</StyledHeader>
              {children}
            </StyledContent>
          </ScrollContent>
          {footer}
        </StyledFlexColumn>
      </StyledPage>
    </PopperContextProvider>
  );
};

export default Page;
