import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';

import { EditCheck as EditCheckIcon } from 'elitegrad-common/src/assets/vectors';
import { editJobPath } from 'routes';
import Job from 'elitegrad-common/src/types/Job';

const StyledShowStatusLink = styled(Link)`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 2.4rem;
  grid-template-areas: 'icon legend';

  cursor: pointer;

  margin: auto;
`;

const StyledIcon = styled(EditCheckIcon)`
  grid-area: icon;

  width: 2.4rem;
  height: 2.4rem;

  & * {
    stroke: ${colors.black};
  }
`;

const StyledText = styled.div`
  grid-area: legend;

  margin-left: 1rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
`;

interface JobStatusEditProps {
  job: Job;
}

const JobStatusEdit: React.FC<JobStatusEditProps> = ({ job }) => {
  return (
    <StyledShowStatusLink to={editJobPath(job.id)}>
      <StyledIcon />
      <StyledText>Edit</StyledText>
    </StyledShowStatusLink>
  );
};

export default JobStatusEdit;
