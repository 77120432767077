import University from '../types/University';

import { UniversityParts } from '../generated/UniversityParts';
import { FillInUniversityParts } from '../generated/FillInUniversityParts';

const asUniversity = (parts: UniversityParts): University => {
  return {
    id: parts.university_selection_id,
    name: parts.location_name,
    logoUrl: parts.logo_url,
  };
};

const asFillUniversity = (parts: FillInUniversityParts): University => {
  return {
    id: parts.degree_id,
    name: parts.institution_name,
    logoUrl: null,
  };
};

export { asUniversity, asFillUniversity };
