import styled from 'styled-components';
export const ToggleControl = styled.div`
  margin-left: 1.6rem;
`;

export const NoCandidateMessage = styled.div`
  margin-left: 1.6rem;
`;

export const PaginationWrapper = styled.div`
  text-align: center;
`;

export const Container = styled.div`
  padding: 2rem;
`;
