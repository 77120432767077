import React from 'react';
import styled from 'styled-components';
import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import { Link } from 'react-router-dom';

import CompanyType from 'elitegrad-common/src/types/Company';
import Page from '../Page';
import Header from 'elitegrad-common/src/components/Header';

import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import { Paths } from 'routes';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import CompanyDisplay from 'elitegrad-common/src/components/company/CompanyDisplay';
import { colors } from 'elitegrad-common/src/theme';

const StyledCompany = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5rem;
`;

const StyledHeaderButton = styled(PrimaryButton)`
  margin-left: 3rem;
`;

const StyledNotSetYet = styled.div`
  position: relative;

  color: ${colors.gray_dark};

  font-size: 1.4rem;
  margin: 20rem auto;

  max-width: 50rem;

  text-align: center;
`;

interface CompanyProps {
  company: CompanyType | null;
  loadingCompany: boolean;
}

const Company: React.FC<CompanyProps> = ({ company, loadingCompany }) => {
  const { user } = useAuth();
  if (loadingCompany || !user)
    return (
      <Page>
        <SpinnerIsoType text="Loading" />
      </Page>
    );
  if (!company)
    return (
      <Page>
        <StyledNotSetYet>
          Your company details for this page hasn&apos;t been set yet, please contact EG administrators
        </StyledNotSetYet>
      </Page>
    );

  return (
    <Page>
      <StyledCompany>
        {user?.isManager && (
          <Header title={company?.name}>
            <Link to={Paths.editCompany}>
              <StyledHeaderButton big text="Edit" />
            </Link>
          </Header>
        )}
        <CompanyDisplay company={company} />
      </StyledCompany>
    </Page>
  );
};

export default Company;
