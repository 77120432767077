import React from 'react';
import styled from 'styled-components';

import { DefaultUniversity } from '../../assets/images';
import colors from '../../theme/colors';
import Degree, { getAwardName, getMajorName } from '../../types/Degree';
import AcademicHonorTag from '../../components/AcademicHonorTag';
import { CandidateGPADisplay } from '.';
import { formatMonthYear } from '../../utils/dateUtils';

const StyledCandidateCurrentDegree = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'logo degree'
    'logo university'
    'logo gpa';
  grid-column-gap: 0.2rem;

  margin: 1rem 0;
  min-height: 7.2rem;
`;

const StyledLogo = styled.img`
  grid-area: logo;

  width: 4.5rem;
  height: 4.5rem;
`;

const StyledDegree = styled.div`
  grid-area: degree;

  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4rem;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledDegreeYear = styled.span`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;

  color: ${colors.gray_dark};
`;

const StyledGPAHonor = styled.div`
  grid-area: gpa;
`;

const StyledUniversity = styled.div`
  grid-area: university;
`;

const StyledUniversityName = styled.span`
  margin-right: 0.4rem;

  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4rem;

  color: ${colors.gray_dark};
`;

const getUniversityLogoUrl = (degree: Degree) => {
  return degree.university?.logoUrl || DefaultUniversity;
};

export interface CandidateCurrentDegreeProps {
  degree: Degree;
  showDate?: boolean;
}

const CandidateCurrentDegree: React.FC<CandidateCurrentDegreeProps> = ({ degree, showDate = false }) => {
  const awardName = getAwardName(degree);
  const universityLogoUrl = getUniversityLogoUrl(degree);

  return (
    <StyledCandidateCurrentDegree>
      <StyledLogo src={universityLogoUrl} />
      <StyledDegree>
        {degree.degreeName}
        {' in '}
        {getMajorName(degree)}
      </StyledDegree>
      <StyledUniversity>
        <StyledUniversityName>{degree.university?.name}</StyledUniversityName>
        {showDate && <StyledDegreeYear>{degree.completeDate && formatMonthYear(degree.completeDate)}</StyledDegreeYear>}
      </StyledUniversity>
      <StyledGPAHonor>
        <CandidateGPADisplay gpa={degree.gpa} gpaOutOf={degree.gpaOutOf} gpaNotApplicable={degree.gpaNotApplicable} />
        {awardName && <AcademicHonorTag awardName={awardName} />}
      </StyledGPAHonor>
    </StyledCandidateCurrentDegree>
  );
};

export default CandidateCurrentDegree;
