import React from 'react';
import styled from 'styled-components';

import colors from '../theme/colors';

const StyledFulltimeTag = styled.span`
  display: inline-block;
  width: 8rem;

  padding: 0.2rem 0.2rem 0;
  border-radius: 3px;

  background-color: ${colors.black};

  font-size: 1rem;
  font-weight: 900;
  line-height: 1.6rem;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
`;

const FullTimeInterestTag: React.FC = (props) => {
  return <StyledFulltimeTag {...props}>Full Time</StyledFulltimeTag>;
};

export default FullTimeInterestTag;
