import React from 'react';

import Candidate from '../../types/Candidate';
import Modal from '../../components/Modal';
import NewChat from './chat/NewChat';
import { asRecipientCandidate } from '../../transformers/recipient';

export interface ChatModalNewProps {
  candidates: Candidate[];
  setModalOpen: (status: boolean) => void;
}

const ChatModalNew: React.FC<ChatModalNewProps> = ({ setModalOpen, candidates }) => {
  return (
    <Modal setModalOpen={setModalOpen}>
      <NewChat recipients={candidates.map(asRecipientCandidate)} />
    </Modal>
  );
};

export default ChatModalNew;
