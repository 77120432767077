import React from 'react';
import styled from 'styled-components';

import colors from '../theme/colors';
import Job from '../types/Job';

const isInternship = (job: Job) => {
  return job.jobType && job.jobType.match(/internship/i);
};

const StyledInternshipTag = styled.span`
  display: inline-block;
  margin-top: 1rem;
  padding: 0.4rem;

  background-color: ${colors.black};

  font-size: 1.2rem;
  color: ${colors.white};
  line-height: 1.4rem;
  text-transform: uppercase;
`;

export interface InternshipTagProps {
  job: Job;
}

const InternshipTag: React.FC<InternshipTagProps> = ({ job, ...props }) => {
  const opacity = isInternship(job) ? 1 : 0;

  return (
    <StyledInternshipTag style={{ opacity }} {...props}>
      Internship
    </StyledInternshipTag>
  );
};

export default InternshipTag;
