import gql from 'graphql-tag';

import { AchievementParts } from './fragments.gql';

export default gql`
  query GetPersonalAchievements($candidateId: String!) {
    grad_achievement(where: { user_id: { _eq: $candidateId } }) {
      ...AchievementParts
    }
  }
  ${AchievementParts}
`;
