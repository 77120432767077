import React from 'react';
import styled from 'styled-components';

import colors from '../../theme/colors';
import { DefaultCandidatePhoto } from '../../assets/images';

import CandidateType from '../../types/Candidate';
import { getDegreesSortedByCompleteDate } from '../../types/Degree';
import { CandidateCurrentDegree } from '../../components/candidate';
import CandidateTag from './CandidateTag';
import ProfessionalDesignationsController from './ProfessionalDesignationsController';

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;

  margin-top: 3.1 rem;
  margin-left: 3.1rem;

  max-height: 20rem;
`;

const StyledLegalTags = styled.div`
  grid-area: tags;
  text-align: left;
  margin-bottom: 1rem;

  & > * {
    margin-right: 0.4rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

const StyledCandidateDetail = styled.div`
  display: flex;
  flex-direction: column;

  margin: auto 4rem auto 0;
`;

const StyledCandidateImg = styled.img`
  display: block;
  width: 15.2rem;
  height: 15.2rem;

  border: 4px solid ${colors.red};
  border-radius: 50%;
`;

const StyledNameTitle = styled.div`
  margin-top: 1rem;

  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8rem;

  overflow: hidden;

  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledProfessionalDesignationsController = styled(ProfessionalDesignationsController)`
  &&& {
    grid-column: 1/3;
  }
`;

const StyledCurrentDegree = styled.div`
  margin: auto 0;
`;

export interface CandidateIdentityProps {
  candidate: CandidateType;
}

const CandidateIdentity: React.FC<CandidateIdentityProps> = ({ candidate }) => {
  const [currentDegree] = getDegreesSortedByCompleteDate(candidate.degrees);

  return (
    <StyledHeader>
      <StyledCandidateDetail>
        <StyledCandidateImg src={candidate.photo || DefaultCandidatePhoto} />
        <StyledNameTitle>
          {candidate.fullname}
          {candidate.title ? `, ${candidate.title}` : ''}
        </StyledNameTitle>
        <StyledLegalTags>
          {candidate.needSponsor && <CandidateTag text="Needs sponsorship" />}
          {!candidate.authorizedToWork && <CandidateTag text="Not authorized to work" />}
        </StyledLegalTags>
      </StyledCandidateDetail>
      <StyledCurrentDegree>
        <CandidateCurrentDegree showDate degree={currentDegree} />
      </StyledCurrentDegree>
      <StyledProfessionalDesignationsController candidateId={candidate.userId} />
    </StyledHeader>
  );
};

export default CandidateIdentity;
