import React from 'react';
import styled from 'styled-components';

import Page from '../Page';
import ConversationListController from 'elitegrad-common/src/components/messaging/conversations/ConversationListController';
import ChatController from 'elitegrad-common/src/components/messaging/chat/ChatController';
import TemplateAdministration from 'elitegrad-common/src/components/messaging/template/TemplateAdministration';
import { colors } from 'elitegrad-common/src/theme';
import { messagingPath, Paths } from 'routes';

const StyledMessaging = styled.div`
  display: grid;

  grid-template-columns: 30rem 1fr;
  grid-template-rows: 1fr;

  & > * {
    height: 100vh;
  }
`;

const StyledLeft = styled.div`
  display: grid;
  grid-template-rows: auto 5rem;

  height: 100%;

  border: 1px solid ${colors.gray_light};
`;

const Messaging: React.FC = () => {
  return (
    <Page>
      <StyledMessaging>
        <StyledLeft>
          <ConversationListController
            generateMessagingPath={messagingPath}
            pathConversationNew={Paths.conversationNew}
          />
          <TemplateAdministration />
        </StyledLeft>
        <ChatController generateCandidatePath={messagingPath} />
      </StyledMessaging>
    </Page>
  );
};

export default Messaging;
