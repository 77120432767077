import gql from 'graphql-tag';

export default gql`
  query GetEvents($userID: String!, $limit: Int = 30, $offset: Int = 0) {
    recruit_event(where: { creator_id: { _eq: $userID } }, limit: $limit, offset: $offset) {
      event_id
      title
      image
      capacity
      timezone
      start_datetime
      end_datetime
      event_type
      show_event
    }

    recruit_event_aggregate {
      aggregate {
        count
      }
    }
  }
`;
