import moment from 'moment';
import React, { useState } from 'react';

import Header from 'elitegrad-common/src/components/Header';
import NotFoundView from 'elitegrad-common/src/components/NotFoundView';
import JobType from 'elitegrad-common/src/types/Job';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import Page from 'views/Page';
import JobHeader from '../JobHeader';
import JobDismissMessageController from '../JobDismissMessageController';

import JobCandidatesDashboardController from '../JobCandidatesDashboard';

import {
  Content,
  Deadline,
  Description,
  InfoSection,
  StyledInternshipTag,
  Label,
  LeftSection,
  Location,
  RightSection,
  Title,
} from './JobDetail.styled';

export interface JobProps {
  allCandidatesIds: string[];
  job: JobType | null;
  loadingJob: boolean;
}

const JobDetail: React.FC<JobProps> = ({ job, loadingJob, allCandidatesIds }) => {
  const [modalOpen, setModalOpen] = useState(false);
  if (loadingJob) {
    return (
      <Page>
        <Header backLink={true} />
        <SpinnerIsoType text="Loading" />
      </Page>
    );
  }

  if (!job) return <NotFoundView />;

  const header = <JobHeader setModalOpen={setModalOpen} job={job} />;

  return (
    <Page headerComponent={header}>
      <Content>
        <LeftSection>
          <Title>{job.title}</Title>
          <StyledInternshipTag job={job} />
          <Description>{job.description}</Description>
        </LeftSection>
        <RightSection>
          {job.deadline && (
            <InfoSection>
              <Label>Deadline:</Label>
              <Deadline>{moment(job.deadline).format('LL')}</Deadline>
            </InfoSection>
          )}
          {job.region && (
            <InfoSection>
              <Label>Location:</Label>
              <Location>{job.region}</Location>
            </InfoSection>
          )}
        </RightSection>
      </Content>

      <JobCandidatesDashboardController candidatesUserIds={allCandidatesIds} jobRequirements={job.requirements} />
      {modalOpen && <JobDismissMessageController candidatesIds={allCandidatesIds} setModalOpen={setModalOpen} />}
    </Page>
  );
};

export default JobDetail;
