import React from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';

import FreeTextFilter from '../FreeTextFilter';

export interface NameFilterProps {
  name: string;
}

export const generateQuery = (value: string): WhereQueryInterface => {
  const queryNameFilter: WhereQueryInterface = {
    enabled: !!value.length,
    operator: '_and',
    whereQuery: { firstname: { _ilike: `%${value}%` } },
  };

  return queryNameFilter;
};

const NameFilter: React.FC<NameFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);

  const setFilterValue = (value: string) => storeFilterStatus(generateQuery(value), value);

  return (
    <FreeTextFilter
      filterTitle={'First Name'}
      defaultValue={(filterValue as string) || ''}
      setFilterValue={setFilterValue}
    />
  );
};

export default NameFilter;
