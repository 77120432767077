import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import styled from 'styled-components';

export const CVsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const DownloadButtonContainer = styled.div`
  width: 250px;
  padding: 1rem 2rem;
  position: absolute;
  right: 0;
  margin-top: -50px;
`;

export const StyledHeaderButton = styled(PrimaryButton)`
  margin-left: 2rem;
`;
