import React from 'react';
import CV from 'elitegrad-common/src/types/CV';
import {
  DownloadIcon,
  PdfDocumentIcon,
  CVContainer,
  CandidateName,
  Icon,
  BusyDownload,
  BusyPreview,
  ComposedIcon,
  IconGroup,
} from './CVCard.styled';

export interface CVCardProps extends CV {
  fullName: string;
  candidateId: string;
  onClickDownload: () => void;
  onClickPreview: () => void;
  isOpeningCV: boolean;
  isDownloadingCV: boolean;
}

const CVCard: React.FC<CVCardProps> = ({ isOpeningCV, isDownloadingCV, onClickDownload, onClickPreview, fullName }) => {
  return (
    <CVContainer>
      <IconGroup>
        <Icon>{isDownloadingCV ? <BusyDownload /> : <DownloadIcon onClick={onClickDownload} />}</Icon>
      </IconGroup>
      {isOpeningCV ? (
        <ComposedIcon linkWorking={isOpeningCV}>
          <BusyPreview />
          <CandidateName>{fullName}</CandidateName>
        </ComposedIcon>
      ) : (
        <ComposedIcon onClick={onClickPreview} linkWorking={!isOpeningCV}>
          <PdfDocumentIcon />
          <CandidateName>{fullName}</CandidateName>
        </ComposedIcon>
      )}
    </CVContainer>
  );
};

export default CVCard;
