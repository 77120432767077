import React from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';

import BooleanFilterItem from '../BooleanFilterItem';

export interface InterestJobTypesToggleFilterProps {
  name: string;
}

export const generateQuery = ({
  internship,
  fulltime,
}: {
  internship: boolean;
  fulltime: boolean;
}): WhereQueryInterface => {
  const queryInterestJobTypesToggleFilter: WhereQueryInterface = {
    enabled: internship || fulltime,
    operator: '_and',
    whereQuery: {
      interest_job_types: {
        job_type: { _in: [...(internship ? ['internship'] : []), ...(fulltime ? ['full time'] : [])] },
      },
    },
  };

  return queryInterestJobTypesToggleFilter;
};

const InterestJobTypesToggleFilter: React.FC<InterestJobTypesToggleFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);
  const { internship, fulltime } = (filterValue as {
    [property: string]: boolean;
  } | null) || { internship: false, fulltime: false };

  const setFilterValueFullTime = (enabledFulltime: boolean) =>
    storeFilterStatus(generateQuery({ internship, fulltime: enabledFulltime }), {
      internship,
      fulltime: enabledFulltime,
    });

  const setFilterValueIntership = (enabledInternship: boolean) =>
    storeFilterStatus(generateQuery({ internship: enabledInternship, fulltime }), {
      internship: enabledInternship,
      fulltime,
    });

  return (
    <div>
      <BooleanFilterItem on={fulltime} filterTitle={'Full Time'} setFilterValue={setFilterValueFullTime} />
      <BooleanFilterItem on={internship} filterTitle={'Internship'} setFilterValue={setFilterValueIntership} />
    </div>
  );
};

export default InterestJobTypesToggleFilter;
