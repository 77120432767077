import styled from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';

export const StyledHeader = styled.div`
  padding: 0 0 1.2rem;
  min-height: 15.5rem;
`;

export const StyledCandidateDetails = styled.div`
  margin: 1rem 0 0.5rem;

  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4rem;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    opacity: 0.4;
  }
`;

export const StyledCandidateImg = styled.img<{ selected: boolean }>`
  display: block;
  width: 7rem;
  height: 7rem;
  margin: 1rem auto 0;

  border: 2px solid;
  border-color: ${({ selected }) => (selected ? colors.green : colors.red)};

  border-radius: 50%;

  &:hover {
    opacity: 0.4;
  }
`;

export const StyledDegreeYear = styled.div`
  text-align: center;

  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1rem;

  color: ${colors.gray_dark};
`;

export const StyledTags = styled.div`
  grid-area: tags;

  & > * {
    margin-right: 0.4rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export const StyledTopBar = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr 2.4rem;
  grid-template-areas: 'radio tags menu';
  justify-items: center;
  align-items: center;
`;
