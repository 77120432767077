import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';

const StyledFilterTitle = styled.div`
  margin: 0.5rem 0;

  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  text-align: left;

  color: ${colors.gray_dark};
`;

const FilterTitle: React.FC<{ title: string }> = ({ title }) => {
  return <StyledFilterTitle>{title}</StyledFilterTitle>;
};

export default FilterTitle;
