import moment from 'moment-timezone';

export const timestampToLocalDate = (date: string, timezone: string): Date => {
  return moment.tz(date, timezone).toDate();
};

export const dateToTimestamp = (date: Date): string => {
  return moment(date).format('YYYY-MM-DD hh:mm:ss a');
};

export const dateToDateString = (date: Date): string => {
  return moment(date).format('MM/DD/YYYY');
};

export const dateToTimeString = (date: Date): string => {
  return moment(date).format('hh:mm a');
};
