import React from 'react';
import { useQuery } from '@apollo/client';

import { useAuth } from 'elitegrad-common/src/utils/react-auth';

import Company from './Company';
import { GetCompany, GetCompanyVariables } from 'elitegrad-common/src/generated/GetCompany';
import GET_COMPANY from 'elitegrad-common/src/queries/getCompany.gql';
import { asCompany } from 'elitegrad-common/src/transformers/companies';

const CompanyController: React.FC = () => {
  const { user } = useAuth();

  const { data, loading } = useQuery<GetCompany, GetCompanyVariables>(GET_COMPANY, {
    skip: !user?.sub,
    variables: {
      id: user?.sub || '',
    },
  });

  const company = data?.recruit_company.map((c) => asCompany(c))[0] || null;

  return <Company loadingCompany={loading} company={company} />;
};

export default CompanyController;
