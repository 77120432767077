import gql from 'graphql-tag';

import { CandidateParts, CandidatePartsAdmin } from './fragments.gql';

export default gql`
  query GetCandidates($admin: Boolean!, $dynamicQuery: grad_profile_bool_exp, $offset: Int!, $limit: Int!) {
    grad_profile_aggregate(where: $dynamicQuery) {
      aggregate {
        count
      }
    }
    grad_profile(
      order_by: { creation_timestamp: desc_nulls_last }
      where: $dynamicQuery
      offset: $offset
      limit: $limit
    ) {
      ... on grad_profile @include(if: $admin) {
        ...CandidatePartsAdmin
      }

      ... on grad_profile @skip(if: $admin) {
        ...CandidateParts
      }
    }
  }
  ${CandidateParts}
  ${CandidatePartsAdmin}
`;
