import React from 'react';
import { useQuery } from '@apollo/client';

import GET_PERSONAL_VOLUNTEER_ACTIVITIES from '../../queries/getVolunteerActivities.gql';

import VolunteerActivities from './VolunteerActivities';
import { asVolunteerActivity } from '../../transformers/volunteerActivities';
import { GetVolunteerActivities, GetVolunteerActivitiesVariables } from '../../generated/GetVolunteerActivities';

const VolunteerActivitiesController: React.FC<{ candidateId: string }> = ({ candidateId }) => {
  const { data } = useQuery<GetVolunteerActivities, GetVolunteerActivitiesVariables>(
    GET_PERSONAL_VOLUNTEER_ACTIVITIES,
    {
      skip: !candidateId,
      variables: { candidateId },
    },
  );

  const volunteerActivities = data?.grad_volunteer.map((a) => asVolunteerActivity(a)) || [];

  return <VolunteerActivities volunteerActivities={volunteerActivities} />;
};

export default VolunteerActivitiesController;
