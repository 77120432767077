import React, { useState } from 'react';
import styled from 'styled-components';
import AvatarEditor from 'react-avatar-editor';
import loadImage from 'blueimp-load-image';
import colors from '../theme/colors';

const StyledCustomUpload = styled.label`
  margin-top: 1.5rem;
  height: 3.2rem;
  width: 12.8rem;
  padding: 0.2rem;

  border-radius: 10px;
  border: 1px solid ${colors.red};
  background: ${colors.white};

  font-weight: 600;
  font-size: 1.4rem;
  line-height: 3.2rem;
  color: ${colors.red};

  text-align: center;
  text-transform: capitalize;

  cursor: pointer;
`;
const StyledUploadFile = styled.input.attrs({
  type: 'file',
  accept: '.png, .jpg, .jpeg',
})`
  display: none;
`;

const StyledPhotoEditor = styled.div`
  margin: auto;
  max-width: 30rem;
`;
const StyledScaleInput = styled.input.attrs({
  type: 'range',
  step: '0.01',
  min: '1',
  max: '2',
  name: 'scale',
})`
  margin-top: 1rem;
  width: 100%;
`;

const StyledPhotoUploader = styled.div`
  padding: 2rem;

  border: 1px solid ${colors.gray_l1};
  border-radius: 4px;
  background-color: transparent;
`;

const PhotoUploader: React.FC<{ onChange: (val: string) => void; logo: string }> = ({ logo, onChange }) => {
  const [image, setImage] = useState<string>(logo);
  const [imageLoaded, setImageLoaded] = useState(!!logo);
  const [editor, setEditor] = useState<AvatarEditor>();
  const [zoom, setZoom] = useState('1');

  const editorRef = (editor: AvatarEditor) => setEditor(editor);

  const onZoom = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZoom(e.target.value);
  };

  const imageUploaded = (img: Event | HTMLCanvasElement | HTMLImageElement, file: File) => {
    setImage((img as HTMLCanvasElement).toDataURL(file.type));
    setImageLoaded(true);
  };

  const onImageChange = () => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const dataUrl = canvasScaled.toDataURL();
      onChange(dataUrl);
    }
  };

  const onFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageLoaded(false);

    if (e && e.target && e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      loadImage(
        e.target.files[0],
        (img) => {
          imageUploaded(img, file);
        },
        { orientation: true, canvas: true },
      );
    }
  };

  return (
    <StyledPhotoUploader>
      <StyledPhotoEditor>
        <AvatarEditor
          ref={editorRef}
          borderRadius={1000}
          color={[0, 0, 0, 0.1]} // RGBA
          scale={parseFloat(zoom)}
          image={image || ''}
          style={{ width: '100%', height: 'auto' }}
          onLoadSuccess={() => onImageChange()}
          onImageChange={onImageChange}
        />
      </StyledPhotoEditor>

      <StyledScaleInput onChange={onZoom} value={zoom} disabled={!imageLoaded}></StyledScaleInput>
      <StyledCustomUpload>
        <StyledUploadFile onChange={onFile} />
        upload photo
      </StyledCustomUpload>
    </StyledPhotoUploader>
  );
};

export default PhotoUploader;
