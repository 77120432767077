import gql from 'graphql-tag';

import { RecipientParts } from './fragments.gql';

export default gql`
  query GetRecipientsById($ids: [String!]!) {
    grad_profile(where: { user_id: { _in: $ids } }) {
      ...RecipientParts
    }
  }
  ${RecipientParts}
`;
