import React, { useState } from 'react';

import { useEventsQuery } from './EventList.hook';
import EventList from './EventList';

import Spinner from 'elitegrad-common/src/components/SpinnerIsoType';
import { useAuth } from 'elitegrad-common/src/utils/react-auth';

const LIMIT_EVENT_CARDS = 30;

const pagination = (count: number, offset: number) => {
  return {
    count: count || 0,
    limit: LIMIT_EVENT_CARDS,
    offset,
  };
};

const EventListController = () => {
  const [offset, setOffset] = useState<number>(0);
  const { user } = useAuth();
  const { data, loading } = useEventsQuery({ userID: user?.sub || '', limit: LIMIT_EVENT_CARDS, offset });

  const count = data?.recruit_event_aggregate?.aggregate?.count || 0;

  return loading ? (
    <Spinner fullPage text="loading..." />
  ) : (
    <EventList eventsList={data} pagination={pagination(count, offset)} onPageChange={setOffset} />
  );
};

export default EventListController;
