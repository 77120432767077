import React from 'react';
import styled from 'styled-components';

import Award from '../../types/Award';
import ProfessionalAwardTag from '../../components/ProfessionalAwardTag';

const StyledProfessionalDesignations = styled.div`
  display: flex;
  flex-direction: row;

  margin: 1rem 1rem 1rem 0;
`;
const StyledAwardList = styled.ol`
  list-style: none;
`;
const StyledAward = styled.li`
  float: left;

  padding: 0 1rem;
`;

const ProfessionalDesignations: React.FC<{ awards?: Award[] }> = ({ awards = [] }) => {
  return (
    <StyledProfessionalDesignations>
      {awards.length > 0 && (
        <>
          {'Professional designations: '}
          <StyledAwardList>
            {awards.map((award) => (
              <StyledAward key={award.id}>
                <ProfessionalAwardTag awardName={award.name} awardYear={award.yearReceived} />
              </StyledAward>
            ))}
          </StyledAwardList>
        </>
      )}
    </StyledProfessionalDesignations>
  );
};

export default ProfessionalDesignations;
