import { useQuery } from '@apollo/client';
import { GetEvents, GetEventsVariables } from 'elitegrad-common/src/generated/GetEvents';
import GET_EVENT_LIST from 'elitegrad-common/src/queries/getEvents.gql';

interface EventQueryProps {
  userID: string;
  limit?: number;
  offset?: number;
}

export const useEventsQuery = ({ userID, limit, offset }: EventQueryProps) => {
  const eventsList = useQuery<GetEvents, GetEventsVariables>(GET_EVENT_LIST, {
    variables: { userID, limit, offset },
    fetchPolicy: 'cache-and-network',
  });

  return eventsList;
};
