import React from 'react';
import styled from 'styled-components';

import Template from '../../../types/Template';
import SpinnerIsoType from '../../SpinnerIsoType';
import TemplateListItemController from './TemplateListItemController';

interface TemplateListProps {
  templates: Template[];
  loading: boolean;
}

const StyledTemplateList = styled.ul`
  list-style: none;
  overflow-y: scroll;
`;

const TemplateList: React.FC<TemplateListProps> = ({ templates, loading }) => {
  return (
    <StyledTemplateList>
      {loading ? (
        <SpinnerIsoType text={'Loading templates'} />
      ) : (
        templates.map((t) => (
          <li key={t.id}>
            <TemplateListItemController template={t} />
          </li>
        ))
      )}
    </StyledTemplateList>
  );
};

export default TemplateList;
