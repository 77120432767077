import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import GET_MAJOR_SELECTION from '../../../queries/GetMajorSelection.gql';
import { GetMajorSelection, GetMajorSelectionVariables } from '../../../generated/GetMajorSelection';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { asMajor } from '../../../transformers/majors';
import { FilterOption } from '../../../types/FilterValueType';
import Major from '../../../types/Major';
import { useFilter } from '../../../utils/useFilter';
import MultiCheckFilterAsync from '../MultiCheckFilterAsync';

export interface MultiCheckQueryFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const optionsNames = options.map(({ filterValue }) => filterValue);
  const queryMultiCheckQueryFilter: WhereQueryInterface = {
    enabled: !!optionsNames.length,
    operator: '_and',
    whereQuery: { degrees: { concentrations: { val: { _in: optionsNames } } } },
  };

  return queryMultiCheckQueryFilter;
};

const MultiCheckQueryFilter: React.FC<MultiCheckQueryFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const [textFilterValue, setTextFilterValue] = useState('');
  const [localLoading, setLocalLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>((filterValue as FilterOption[]) || []);

  const [getMajors, { data: majorSelection }] = useLazyQuery<GetMajorSelection, GetMajorSelectionVariables>(
    GET_MAJOR_SELECTION,
    {
      onCompleted: () => {
        setLocalLoading(false);
      },
      onError: () => {
        setLocalLoading(false);
      },
    },
  );

  useEffect(() => {
    getMajors({ variables: { major_name: `% %` } });
  }, [getMajors]);

  const handleTextFilter = (value: string) => {
    setLocalLoading(true);
    setTextFilterValue(value);

    setTimeout(() => {
      setLocalLoading(true);
      getMajors({ variables: { major_name: `%${value}%` } });
    }, 1000);
  };

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const majors: Major[] = majorSelection?.grad_major_selection.map((gp) => asMajor(gp)) || [];
  const optionsMajors: FilterOption[] = majors.map(({ id, name }) => ({
    filterValue: name,
    displayValue: name,
    id: id,
  }));

  const optionsAndSelections = [
    ...optionsMajors.filter(({ id }) => !selectedOptions.some(({ id: selectedId }) => selectedId === id)),
  ];

  return (
    <MultiCheckFilterAsync
      loading={localLoading}
      setTextFilter={handleTextFilter}
      textFilter={textFilterValue}
      filterTitle={'Major'}
      options={optionsAndSelections}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    />
  );
};

export default MultiCheckQueryFilter;
