import styled from 'styled-components';

import { Download, MenuMoreAddGroupWhite, MessagingWhite } from 'elitegrad-common/src/assets/vectors';
import { colors } from 'elitegrad-common/src/theme';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';

const SIDEBAR_HEIGHT = '7.4rem';

export const StyledFooter = styled.div`
  position: relative;
  min-height: ${SIDEBAR_HEIGHT};
  background: ${colors.white};
  box-shadow: 0px -6px 16px rgba(10, 22, 70, 0.1);
  display: flex;
  align-items: center;
`;

export const StyledMenuMoreAddGroupWhite = styled(MenuMoreAddGroupWhite)`
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 1rem;

  vertical-align: bottom;
`;

export const StyledMenuMoreDownloadCVWhite = styled(Download)`
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  color: white;

  vertical-align: bottom;
`;

export const StyledMenuMoreSendMessageWhite = styled(MessagingWhite)`
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;

  vertical-align: bottom;
`;

export const ButtonGroupContainer = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonContainer = styled.div`
  flex: 0 0 auto;
  flex-wrap: wrap;
  margin: 0.5rem 1rem;
  line-height: 2.2rem;
`;

export const StyledSecondaryButton = styled(PrimaryButton)`
  background-color: ${colors.black};
  min-width: 20.7rem;
  min-height: 4.4rem;
  border-radius: 8px;
`;

export const StyledClearSelection = styled.div`
  color: ${colors.red};
  text-decoration: underline;
  cursor: pointer;
  margin-left: 1.8rem;

  &:after {
    content: 'Clear Selection';
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;
