import gql from 'graphql-tag';

import { ComputerLanguageSelectorParts } from './fragments.gql';

export default gql`
  query GetComputerLanguageSelection($language: String) {
    grad_computer_language_selection(where: { computer_language: { _ilike: $language } }, limit: 100) {
      ...ComputerLanguageSelectorParts
    }
  }
  ${ComputerLanguageSelectorParts}
`;
