import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from 'elitegrad-common/src/theme';
import { EditCheck as EditCheckIcon } from 'elitegrad-common/src/assets/vectors';

export const ShowStatusLink = styled(Link)`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 2.4rem;
  grid-template-areas: 'icon legend';

  cursor: pointer;

  margin: auto;
`;

export const Icon = styled(EditCheckIcon)`
  grid-area: icon;

  width: 2.4rem;
  height: 2.4rem;

  & * {
    stroke: ${colors.black};
  }
`;

export const Text = styled.div`
  grid-area: legend;

  margin-left: 1rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
`;
