import React, { SyntheticEvent, useState, useCallback, useMemo } from 'react';
import WithSeparator from 'react-with-separator';
import styled, { css } from 'styled-components';

import { ChevronDown } from '../../assets/vectors';
import HorizontalRuler from '../../components/HorizontalRuler';

export interface CollapsibleCardSectionProps {
  title: string;
  defaultCollapsed?: boolean;
}

const StyledTitle = styled.div`
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.4rem;

  text-transform: capitalize;
`;

const StyledArrowIcon = styled(ChevronDown)<{ props: { up?: boolean } }>`
  width: 1.8rem;
  height: 1.8rem;

  ${({ props }) =>
    !!props.up &&
    css`
      transform: rotate(180deg);
    `}
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 1.8rem;
`;

const StyledHorizontalRuler = styled(HorizontalRuler)`
  margin: 0 0 1rem;
`;

const CollapsibleCardSection: React.FC<CollapsibleCardSectionProps> = ({
  title,
  defaultCollapsed = true,
  children,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const [firstChild, ...restChildren] = React.Children.toArray(children);

  const handleOnClick = useCallback((e: SyntheticEvent) => {
    setCollapsed((c) => !c);
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const separator = useMemo<React.ReactElement>(() => <StyledHorizontalRuler />, []);

  return (
    <div>
      <StyledHeader>
        <StyledTitle>{title}</StyledTitle>
        {restChildren.length > 0 && <StyledArrowIcon props={{ up: !collapsed }} onClick={handleOnClick} />}
      </StyledHeader>
      {firstChild}
      {restChildren.length > 0 && !collapsed && (
        <>
          {separator}
          <WithSeparator separator={separator}>{restChildren}</WithSeparator>
        </>
      )}
    </div>
  );
};

export default CollapsibleCardSection;
