import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';
import WithSeparator from 'react-with-separator';

import Header from 'elitegrad-common/src/components/Header';
import VerticalDivider from 'elitegrad-common/src/components/VerticalDivider';
import JobStatusActiveController from './JobStatusActiveController';
import JobStatusHiddenController from './JobStatusHiddenController';
import JobStatusDeleteController from './JobStatusDeleteController';
import JobStatusEdit from './JobStatusEdit';

import JobType from 'elitegrad-common/src/types/Job';

const StyledOptions = styled.div`
  display: flex;

  min-width: 45rem;
  min-height: 3.5rem;
`;

const StyledVerticalDivider = styled(VerticalDivider)`
  background: ${colors.gray_light_l2};
`;

const JobHeader: React.FC<{ job: JobType; setModalOpen: (s: boolean) => void }> = ({ job, setModalOpen }) => {
  const separator = useMemo<React.ReactElement>(() => <StyledVerticalDivider />, []);

  return (
    <Header backLink={true}>
      <StyledOptions>
        <WithSeparator separator={separator}>
          <JobStatusEdit job={job} />
          <JobStatusHiddenController job={job} />
          <JobStatusActiveController setModalOpen={setModalOpen} job={job} />
          {!job.active && <JobStatusDeleteController job={job} />}
        </WithSeparator>
      </StyledOptions>
    </Header>
  );
};

export default JobHeader;
