import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import { useQuery, useMutation } from '@apollo/client';

import EditCompanyForm from './EditCompanyForm';

import { GetCompany, GetCompanyVariables } from 'elitegrad-common/src/generated/GetCompany';
import { UpdateCompany, UpdateCompanyVariables } from 'elitegrad-common/src/generated/UpdateCompany';
import {
  CleanInsertCompanySubIndustry,
  CleanInsertCompanySubIndustryVariables,
} from 'elitegrad-common/src/generated/CleanInsertCompanySubIndustry';
import UPDATE_COMPANY from 'elitegrad-common/src/queries/updateCompany.gql';
import GET_COMPANY from 'elitegrad-common/src/queries/getCompany.gql';
import CLEAN_INSERT_SUB_COMPANY from 'elitegrad-common/src/queries/cleanInsertCompanySubIndustry.gql';

import { asCompany } from 'elitegrad-common/src/transformers/companies';
import NotFoundView from 'elitegrad-common/src/components/NotFoundView';
import Company from 'elitegrad-common/src/types/Company';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';

const EditCompanyController: React.FC = () => {
  const history = useHistory();
  const [localLoading, setLocalLoading] = useState(false);

  const { user } = useAuth();

  const { data, loading } = useQuery<GetCompany, GetCompanyVariables>(GET_COMPANY, {
    // fetchPolicy: 'no-cache',
    skip: !user?.sub,
    variables: {
      id: user?.sub || '',
    },
  });

  const [update] = useMutation<UpdateCompany, UpdateCompanyVariables>(UPDATE_COMPANY);

  const [insertCompany] = useMutation<CleanInsertCompanySubIndustry, CleanInsertCompanySubIndustryVariables>(
    CLEAN_INSERT_SUB_COMPANY,
    {
      update: (cache, { data: newSubCompany }) => {
        if (!user?.sub) return;
        const companyCache = cache.readQuery<GetCompany, GetCompanyVariables>({
          query: GET_COMPANY,
          variables: { id: user.sub },
        });

        const [oldCompany] = companyCache?.recruit_company || [];
        if (!oldCompany) return;
        const newSubIndustries = newSubCompany?.insert_recruit_company_subindustry?.returning;
        if (!newSubIndustries) return;

        cache.writeQuery({
          query: GET_COMPANY,
          variables: { id: user.sub },
          data: {
            recruit_company: [{ ...oldCompany, subindustries: newSubIndustries }],
          },
        });
      },
    },
  );

  const handleOnSave = useCallback(
    async (company: Company) => {
      const subIndustries = company.subIndustries.map((value) => ({
        company_id: company.id,
        value,
      }));
      setLocalLoading(true);
      Promise.all([
        update({
          variables: {
            companyId: company.id,
            address: company.address,
            description: company.description,
            industry: company.industry,
            name: company.name,
            num_employees: company.employeesNumber,
            phone: company.phone,
            website: company.website,
            logo: company.logo,
          },
        }),
        insertCompany({ variables: { companyId: company.id, subIndustries } }),
      ]).then(history.goBack);
    },
    [update, insertCompany, history.goBack],
  );

  const company = data?.recruit_company.map((c) => asCompany(c))[0] || null;
  if (loading || localLoading) return <SpinnerIsoType text="Loading" />;

  if (!company) return <NotFoundView />;

  return <EditCompanyForm onSave={handleOnSave} onCancel={history.goBack} company={company} />;
};

export default EditCompanyController;
