import { CompanyParts } from '../generated/CompanyParts';
import Company from '../types/Company';
import { AccountParts } from '../generated/AccountParts';
import { SubIndustryParts } from '../generated/SubIndustryParts';

const asAccount = (account: AccountParts) => account.account_id;
const asIndustries = (subindustries: SubIndustryParts[]) => subindustries.map(({ value }) => value);

const asCompany = (companyParts: CompanyParts): Company => {
  return {
    id: companyParts.company_id,
    accountId: companyParts.account_id,
    name: companyParts.name,

    address: companyParts.address,
    description: companyParts.description,
    logo: companyParts.logo,
    employeesNumber: companyParts.num_employees,

    phone: companyParts.phone,
    website: companyParts.website,

    account: asAccount(companyParts.account), // CompanyParts_account;

    industry: companyParts.industry,
    subIndustries: asIndustries(companyParts.subindustries), //CompanyParts_subindustries
  };
};

export { asCompany };
