import React from 'react';
import styled, { css } from 'styled-components';

import VerticalDivider from '../../../components/VerticalDivider';
import HorizontalDivider from '../../../components/HorizontalRuler';

import { EditCheck as EditCheckIcon, Trash as TrashIcon } from '../../../assets/vectors';
import { colors } from '../../../theme';

import Template from '../../../types/Template';

const StyledTemplateItem = styled.div<{ disabled: boolean }>`
  display: grid;
  grid-template-columns: auto 3rem 1rem 3rem;
  grid-template-rows: 3.5rem;
  grid-row-gap: 0.5rem;

  margin: 1rem;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const StyledTrash = styled(TrashIcon)`
  width: 2.4rem;
  height: 2.4rem;
  margin: auto;
  & * {
    stroke: ${colors.red};
  }
`;

const StyledEdit = styled(EditCheckIcon)`
  width: 2.4rem;
  height: 2.4rem;
  margin: auto;
  & * {
    stroke: ${colors.black};
  }
`;

const StyledTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
`;

const StyledContent = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: scroll;
`;

const StyledHorizontalDivider = styled(HorizontalDivider)`
  grid-column: 1 / 5;
  padding: 0 0.1rem;
`;

interface TemplateListItemProps {
  template: Template;
  disable: boolean;
  onClickTrash: () => void;
  enableSaveMode: () => void;
}

const TemplateListItem: React.FC<TemplateListItemProps> = ({ template, disable, onClickTrash, enableSaveMode }) => {
  return (
    <StyledTemplateItem disabled={disable}>
      <div>
        <StyledTitle>{template.title}</StyledTitle>
        <StyledContent>{template.content}</StyledContent>
      </div>
      <StyledEdit onClick={enableSaveMode} />
      <VerticalDivider />
      <StyledTrash onClick={!disable ? onClickTrash : undefined} />
      <StyledHorizontalDivider />
    </StyledTemplateItem>
  );
};

export default TemplateListItem;
