import React from 'react';

import PaginationButton from './PaginationButton';
import PaginationState, { getPageNumber, getPagesCount, getPageNumbersToDisplay, getOffset } from './PaginationState';

interface Props {
  pagination: PaginationState;
  onPageChange: (pageNumber: number) => void;
}

const Pagination: React.FC<Props> = ({ pagination, onPageChange }) => {
  const pageNumber = getPageNumber(pagination);
  const pageCount = getPagesCount(pagination);
  const pageNumbersToDisplay = getPageNumbersToDisplay(pagination);

  if (pageCount === 0) return null;

  return (
    <div>
      <PaginationButton
        disabled={pageNumber <= 1}
        previous
        text="Previous"
        onClick={() => onPageChange(getOffset(pageNumber - 1, pagination.limit))}
      />
      {pageNumbersToDisplay[0] > 1 && <PaginationButton text="..." disabled />}
      {pageNumbersToDisplay.map((n) => (
        <PaginationButton
          key={n}
          active={n === pageNumber}
          previousActive={n === pageNumber + 1}
          text={String(n)}
          onClick={() => onPageChange(getOffset(n, pagination.limit))}
        />
      ))}
      {pageNumbersToDisplay[pageNumbersToDisplay.length - 1] < pageCount && <PaginationButton text={'...'} disabled />}
      <PaginationButton
        disabled={pageNumber === pageCount}
        next
        previousActive={pageNumber === pageCount}
        text="Next"
        onClick={() => onPageChange(getOffset(pageNumber + 1, pagination.limit))}
      />
    </div>
  );
};

export default Pagination;
