import React from 'react';
import styled from 'styled-components';

import { colors } from '../theme';

const StyledProfessionalAwardTag = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0.3rem 0.4rem 0.2rem;

  border: 1px solid ${colors.gray_light};
  border-radius: 6px;
  background-color: ${colors.gray_extraLight};

  font-size: 1rem;
  line-height: 1.1rem;
  color: ${colors.gray};
`;

const StyledYear = styled.div`
  font-size: 0.7rem;
  line-height: 1.1rem;

  vertical-align: middle;
`;

const ProfessionalAwardTag: React.FC<{ awardName: string; awardYear: number | null }> = ({
  awardName,
  awardYear,
  ...props
}) => {
  return (
    <StyledProfessionalAwardTag {...props}>
      {awardYear && <StyledYear>{awardYear}</StyledYear>}
      <span>{awardName}</span>
    </StyledProfessionalAwardTag>
  );
};

export default ProfessionalAwardTag;
