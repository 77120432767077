import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import ChatRecipientIdentity from './ChatRecipientIdentity';
import GET_RECIPIENT_BY_ID from '../../../queries/getRecipientById.gql';

import { GetRecipientById, GetRecipientByIdVariables } from '../../../generated/GetRecipientById';
import { asRecipient } from '../../../transformers/recipient';
import SpinnerIsoType from '../../SpinnerIsoType';

const ChatRecipientIdentityController: React.FC<{
  candidateId?: string;
  generateCandidatePath: (candidateId: string) => string;
}> = ({ candidateId, generateCandidatePath }) => {
  const { id } = useParams<{ id: string }>();

  const { data, loading } = useQuery<GetRecipientById, GetRecipientByIdVariables>(GET_RECIPIENT_BY_ID, {
    skip: !(candidateId || id),
    variables: { userId: candidateId || id },
  });

  if (loading) return <SpinnerIsoType text={'Loading messages'} />;
  if (data?.grad_profile.length === 0) return <SpinnerIsoType text={'Sorry, something went wrong'} />;

  const recipient = data?.grad_profile.map((c) => asRecipient(c))[0];

  if (!recipient) return <SpinnerIsoType text={'Sorry, something went wrong'} />;

  return <ChatRecipientIdentity recipient={recipient} candidatePath={generateCandidatePath(recipient.id)} />;
};

export default ChatRecipientIdentityController;
