import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Paths } from 'routes';
import Job from 'elitegrad-common/src/types/Job';
import JobStatusDelete from './JobStatusDelete';

import DELETE_JOB from 'elitegrad-common/src/queries/deleteJob.gql';
import GET_JOBS from 'elitegrad-common/src/queries/getJobs.gql';
import { DeleteJob, DeleteJobVariables } from 'elitegrad-common/src/generated/DeleteJob';
import { GetJobs } from 'elitegrad-common/src/generated/GetJobs';

interface JobStatusDeleteControllerProps {
  job: Job;
}

const JobStatusDeleteController: React.FC<JobStatusDeleteControllerProps> = ({ job }) => {
  const history = useHistory();

  const [deleteJob, { called: deleteCalled, loading: deleteLoading }] = useMutation<DeleteJob, DeleteJobVariables>(
    DELETE_JOB,
    {
      update: (cache, { data: deleteResultData }) => {
        const cachedJobsResult = cache.readQuery<GetJobs>({
          query: GET_JOBS,
        });

        if (!cachedJobsResult) {
          return;
        }

        if (!deleteResultData || !deleteResultData.delete_recruit_job_posting) {
          return;
        }

        const newJobs = cachedJobsResult.recruit_job_posting.filter((j) => j.job_posting_id !== job.id);

        cache.writeQuery({
          query: GET_JOBS,
          data: {
            recruit_job_posting: newJobs,
          },
        });
      },
    },
  );

  const handleOnDelete = useCallback(() => {
    const variables = {
      jobId: job.id,
    };

    deleteJob({ variables });
  }, [deleteJob, job]);

  useEffect(() => {
    if (deleteCalled && !deleteLoading) {
      history.push(Paths.jobs);
    }
  }, [history, deleteCalled, deleteLoading]);

  return <JobStatusDelete loading={deleteLoading} onDelete={handleOnDelete} />;
};

export default JobStatusDeleteController;
