import styled from 'styled-components';
import { PlusSign } from '../assets/vectors';

const ExitCross = styled(PlusSign)`
  position: absolute;
  right: 1rem;
  top: 2rem;

  width: 2.4rem;

  cursor: pointer;
  transform: rotate(45deg);
`;

export default ExitCross;
