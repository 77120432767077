import React from 'react';
import { useMutation } from '@apollo/client';

import {
  UpdateJobsStatusActive,
  UpdateJobsStatusActiveVariables,
} from 'elitegrad-common/src/generated/UpdateJobsStatusActive';
import UPDATE_JOB_STATUS_ACTIVE from 'elitegrad-common/src/queries/updateJobStatusActive.gql';

import JobStatusActivate from './JobStatusActive';
import JobType from 'elitegrad-common/src/types/Job';

interface JobStatusActiveController {
  job: JobType;
  setModalOpen: (status: boolean) => void;
}
const JobStatusActiveController: React.FC<JobStatusActiveController> = ({ job, setModalOpen }) => {
  const [updateJobStatusActivate, { loading }] = useMutation<UpdateJobsStatusActive, UpdateJobsStatusActiveVariables>(
    UPDATE_JOB_STATUS_ACTIVE,
  );

  const setActiveCallback = (active: boolean) => {
    const variables = {
      active: active,
      jobId: job.id,
    };
    updateJobStatusActivate({ variables });
  };
  return (
    <JobStatusActivate
      hasCandidates={job.candidatesUserIds.length > 0}
      setModalOpen={setModalOpen}
      loading={loading}
      jobActiveDefaultValue={job.active}
      setActiveCallback={setActiveCallback}
    />
  );
};

export default JobStatusActiveController;
