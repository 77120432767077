import React, { SyntheticEvent, useState, useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { ChevronDown } from '../../assets/vectors';

const StyledTitle = styled.div`
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.4rem;

  text-transform: capitalize;
`;

const ResizeableSection = styled.div<{ collapsed: boolean }>`
  display: flex;
  flex-wrap: wrap;

  overflow: hidden;
  min-height: 2.5rem;
  height: ${({ collapsed }) => (collapsed ? '2.5rem ' : 'auto')};
`;

const StyledArrowIcon = styled(ChevronDown)<{ props: { up?: boolean } }>`
  width: 1.8rem;
  height: 1.8rem;

  ${({ props }) =>
    !!props.up &&
    css`
      transform: rotate(180deg);
    `}
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 1.8rem;
`;

export interface ResizeableCardSectionProps {
  title: string;
  defaultCollapsed?: boolean;
}

const ResizeableCardSection: React.FC<ResizeableCardSectionProps> = ({ title, defaultCollapsed = true, children }) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const resizeableSectionRef = useRef<HTMLDivElement>(null);
  const [overflows, setOverflows] = useState(false);
  useEffect(() => {
    if (resizeableSectionRef.current) {
      setOverflows(resizeableSectionRef.current.offsetHeight < resizeableSectionRef.current.scrollHeight);
    }
  }, [resizeableSectionRef.current]);

  const handleOnClick = useCallback((e: SyntheticEvent) => {
    setCollapsed((c) => !c);
    e.stopPropagation();
    e.preventDefault();
  }, []);

  return (
    <div>
      <StyledHeader>
        <StyledTitle>{title}</StyledTitle>
        {overflows && children && <StyledArrowIcon props={{ up: !collapsed }} onClick={handleOnClick} />}
      </StyledHeader>
      <ResizeableSection ref={resizeableSectionRef} collapsed={collapsed}>
        {children}
      </ResizeableSection>
    </div>
  );
};

export default ResizeableCardSection;
