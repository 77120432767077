import React, { useState } from 'react';
import styled from 'styled-components';
import TemplateListModal from './TemplateListModal';
import MessageTemplate from '../../../assets/vectors/MessageTemplate.svg';

const StyledTitle = styled.div`
  padding: 1.5rem 1rem 1rem 0;
  min-height: 3rem;

  font-size: 1.6rem;
  text-align: right;
  background-image: url(${MessageTemplate});
  background-repeat: no-repeat;
  background-position: 1rem 1rem;

  cursor: pointer;
`;

const TemplateAdministration: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <StyledTitle onClick={() => setModalOpen(true)}>Message Template Administration</StyledTitle>
      {modalOpen && <TemplateListModal setModalOpen={setModalOpen} />}
    </div>
  );
};

export default TemplateAdministration;
