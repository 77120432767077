import React from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';

import Page from 'views/Page';

import FormInput from 'elitegrad-common/src/components/FormInput';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import TextAreaInput from 'elitegrad-common/src/components/TextAreaInput';
import TextInput from 'elitegrad-common/src/components/TextInput';
import SecondaryButton from 'elitegrad-common/src/components/SecondaryButton';
import Company from 'elitegrad-common/src/types/Company';
import Header from 'elitegrad-common/src/components/Header';
import ViewTitle from 'elitegrad-common/src/components/ViewTitle';
import PhotoUploader from 'elitegrad-common/src/components/PhotoUploader';

import SubIndustrySelector from 'elitegrad-common/src/components/SubIndustrySelector';
import IndustrySelector from 'elitegrad-common/src/components/IndustrySelector';
import EmployeeCountSelector from 'elitegrad-common/src/components/EmployeeCountSelector';
import InputError from 'elitegrad-common/src/components/InputError';

const StyledForm = styled.form`
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 1rem;

  width: 50rem;
  margin: 0 auto;
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 1rem;
`;

export interface CompanyProps {
  company: Company;
  newCompany?: boolean;
  onCancel?: () => void;
  onSave?: (company: Company) => void;
}

const EditCompanyForm: React.FC<CompanyProps> = ({
  newCompany,
  company,
  onCancel = () => undefined,
  onSave = () => undefined,
}) => {
  const formik = useFormik({
    initialValues: {
      name: company.name || '',
      description: company.description || '',
      address: company.address || '',
      phone: company.phone || '',
      website: company.website || '',
      subIndustries: company.subIndustries || [],
      industry: company.industry || '',
      employeesNumber: company.employeesNumber || '',
      logo: company.logo || '',
    },
    validate: (values) => {
      return {
        ...(!values.name && { name: 'Required' }),
        ...(!values.description && { description: 'Required' }),
        ...(!values.address && { address: 'Required' }),
        ...(!values.phone && { phone: 'Required' }),
        ...(!values.website && { website: 'Required' }),
        ...(!(values.subIndustries.length > 0) && { subIndustries: 'Required' }),
        ...(!values.industry && { industry: 'Required' }),
        ...(!values.employeesNumber && { employeesNumber: 'Required' }),
        ...(!values.logo && { logo: 'Required' }),
      };
    },
    onSubmit: (values) => {
      onSave({
        ...company,
        description: values.description,
        name: values.name,
        address: values.address,
        phone: values.phone,
        website: values.website,
        subIndustries: values.subIndustries,
        industry: values.industry,
        employeesNumber: values.employeesNumber,
        logo: values.logo,
      });
    },
  });

  return (
    <Page headerComponent={<Header backLink={true} />}>
      <StyledForm onSubmit={formik.handleSubmit}>
        <ViewTitle>{newCompany ? 'New Company' : 'Edit Company'}</ViewTitle>

        <FormInput label="Logo">
          <PhotoUploader logo={formik.values.logo} onChange={(v) => formik.setFieldValue('logo', v)} />
          <InputError error={formik.errors.logo} />
        </FormInput>

        <FormInput label="Company Name">
          <TextInput value={formik.values.name} onChange={(v) => formik.setFieldValue('name', v)} />
          <InputError error={formik.errors.name} />
        </FormInput>
        <FormInput label="Description">
          <TextAreaInput value={formik.values.description} onChange={(v) => formik.setFieldValue('description', v)} />
          <InputError error={formik.errors.description} />
        </FormInput>
        <FormInput label="Address">
          <TextInput value={formik.values.address} onChange={(v) => formik.setFieldValue('address', v)} />
          <InputError error={formik.errors.address} />
        </FormInput>
        <FormInput label="Phone">
          <TextInput value={formik.values.phone} onChange={(v) => formik.setFieldValue('phone', v)} />
          <InputError error={formik.errors.phone} />
        </FormInput>
        <FormInput label="Website">
          <TextInput type={'url'} value={formik.values.website} onChange={(v) => formik.setFieldValue('website', v)} />
          <InputError error={formik.errors.website} />
        </FormInput>
        <FormInput label="Industry">
          <IndustrySelector value={formik.values.industry} onChange={(v) => formik.setFieldValue('industry', v)} />
          <InputError error={formik.errors.industry} />
        </FormInput>
        <FormInput label="Sub Industry">
          <SubIndustrySelector
            values={formik.values.subIndustries}
            onChange={(v) => formik.setFieldValue('subIndustries', v)}
          />
          <InputError error={formik.errors.subIndustries as string} />
        </FormInput>
        <FormInput label="Employees">
          <EmployeeCountSelector
            value={formik.values.employeesNumber}
            onChange={(v) => formik.setFieldValue('employeesNumber', v)}
          />
          <InputError error={formik.errors.employeesNumber} />
        </FormInput>
        <StyledButtons>
          <SecondaryButton big text="Cancel" onClick={onCancel} />
          <StyledPrimaryButton big submit text="Save" />
        </StyledButtons>
      </StyledForm>
    </Page>
  );
};

export default EditCompanyForm;
