import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import styled from 'styled-components';

export const HeaderButton = styled(PrimaryButton)`
  margin-left: 2rem;
`;

export const PaginationWrapper = styled.div`
  text-align: center;
`;

export const EventListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  max-width: 100%;
  margin: 0 auto;
`;
