import VolunteerActivity from '../types/VolunteerActivity';
import { VolunteerActivitiesParts } from '../generated/VolunteerActivitiesParts';

const asVolunteerActivity = (parts: VolunteerActivitiesParts): VolunteerActivity => {
  return {
    id: parts.volunteer_id,
    description: parts.description,
  };
};

export { asVolunteerActivity };
