import React from 'react';
import { useQuery } from '@apollo/client';

import GET_DEGREE_SELECTION from '../../../queries/getDegreeSelection.gql';
import { GetDegreeSelection } from '../../../generated/GetDegreeSelection';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { asDegreeSelection } from '../../../transformers/degreeSelection';
import DegreeSelection from '../../../types/DegreeSelection';
import { FilterOption } from '../../../types/FilterValueType';
import { useFilter } from '../../../utils/useFilter';
import Spinner from '../../Spinner';
import MultiCheckFilter from '../MultiCheckFilter';

export interface DegreeFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const optionsValues = options.map(({ filterValue }) => filterValue);
  const queryDegreeFilter: WhereQueryInterface = {
    enabled: !!optionsValues.length,
    operator: '_and',
    whereQuery: { degrees: { degree: { _in: optionsValues } } },
  };
  return queryDegreeFilter;
};

const DegreeFilter: React.FC<DegreeFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const { data, loading } = useQuery<GetDegreeSelection>(GET_DEGREE_SELECTION);

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const degreeSelections: DegreeSelection[] = data?.grad_degree_selection.map((ds) => asDegreeSelection(ds)) || [];
  const options: FilterOption[] = degreeSelections.map(({ id, degreeName, description }) => ({
    filterValue: degreeName,
    displayValue: `${degreeName}: ${description}`,
    id: id,
  }));

  return loading ? (
    <Spinner />
  ) : (
    <MultiCheckFilter
      clearFilter={clearFilter}
      filterTitle={'Degree filter'}
      options={options}
      setFilterValue={setFilterValue}
      defaultOptions={(filterValue as FilterOption[]) || []}
    />
  );
};

export default DegreeFilter;
