import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { asJob } from 'elitegrad-common/src/transformers/jobs';

import JobDetail from './JobDetail';

import GET_JOB from 'elitegrad-common/src/queries/getJob.gql';

import { GetJob, GetJobVariables } from 'elitegrad-common/src/generated/GetJob';

const JobDetailController: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: jobData, loading: loadingJob } = useQuery<GetJob, GetJobVariables>(GET_JOB, {
    fetchPolicy: 'network-only',
    skip: !id,
    variables: {
      id: id || '',
    },
  });

  const job = jobData?.recruit_job_posting.map((rcp) => asJob(rcp))[0] || null;

  const candidatesUserIds = job?.candidatesUserIds || [];

  return <JobDetail allCandidatesIds={candidatesUserIds} job={job} loadingJob={loadingJob} />;
};

export default JobDetailController;
