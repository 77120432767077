import React from 'react';
import styled from 'styled-components';

import TestDetail from '../../types/TestDetail';
import { colors } from '../../theme';

const StyledTestDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  margin-top: 0.2rem;
  padding: 0 0.5rem;

  text-align: center;

  & > *:nth-child(1),
  *:nth-child(2) {
    border-right: 1px ${colors.gray_light} solid;
  }
`;

const StyledScoreDetail = styled.span<{ big: boolean }>`
  padding: 0.5rem 0;

  font-size: ${({ big }) => (big ? '1.4rem' : '1rem')};
  line-height: 1.1rem;

  text-transform: capitalize;
`;

interface CandidateTestDetailProps {
  details: TestDetail[];
  big?: boolean;
}

const CandidateTestScoreDetails: React.FC<CandidateTestDetailProps> = ({ details, big = false }) => {
  return (
    <StyledTestDetails>
      {details.map((d) => (
        <StyledScoreDetail big={big} key={d.id}>
          {d.type}: {d.score}
        </StyledScoreDetail>
      ))}
    </StyledTestDetails>
  );
};

export default CandidateTestScoreDetails;
