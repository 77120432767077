import styled, { css } from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';

export const ButtonContainer = styled.div`
  position: relative;
  display: inline-block;

  width: 3.8rem;
  height: 1.8rem;

  cursor: pointer;
`;

export const ButtonBackground = styled.div`
  position: relative;
  top: 0.1rem;
  left: 0.1rem;

  width: 3.6rem;
  height: 1.6rem;

  border: none;
  border-radius: 1.6rem;

  background-color: ${colors.red};
`;

export const ButtonCircle = styled.div<{ left: boolean }>`
  position: absolute;
  top: 0;
  left: 0;

  width: 1.8rem;
  height: 100%;

  border: solid 1px ${colors.gray_light};
  border-radius: 50%;

  background-color: ${colors.white};

  ${({ left }) =>
    !left &&
    css`
      left: unset;
      right: 0;
    `}
`;

export const ToggleContainer = styled.div`
  display: flex;
  padding-left: 1rem;
  align-items: center;
`;

export const ToggleLabelLeft = styled.div<{ left: boolean }>`
  margin-right: 1rem;

  font-size: 1.2rem;
  line-height: 1.2rem;
  ${({ left }) =>
    left &&
    css`
      font-size: 1.5rem;
      color: ${colors.red};
    `}
`;

export const ToggleLabelRight = styled.div<{ left: boolean }>`
  margin-left: 1rem;

  font-size: 1.2rem;
  line-height: 1.2rem;

  ${({ left }) =>
    !left &&
    css`
      font-size: 1.5rem;
      color: ${colors.red};
    `}
`;
