import gql from 'graphql-tag';

export default gql`
  query GetEventDetails($eventID: String!) {
    recruit_event_by_pk(event_id: $eventID) {
      event_id
      title
      image
      description
      capacity
      timezone
      start_datetime
      end_datetime
      event_type
      address
      show_event
      event_url
      account {
        company {
          name
        }
      }
      event_registrations {
        user_id
      }
    }
  }
`;
