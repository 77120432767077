import React from 'react';
import styled from 'styled-components';

import { NoDegrees } from '../../assets/images';
import { colors } from '../../theme';

const StyledNoTests = styled.div`
  display: grid;
  grid-template-columns: 4rem auto;
  grid-template-rows: 4.9rem;
  grid-template-areas: 'img-area text-area';

  padding: 0 1.8rem;

  border-radius: 4px;

  color: ${colors.gray_l1};
`;

const StyledImage = styled.img`
  grid-area: img-area;

  width: 3rem;
  height: 3rem;
  margin: auto;
`;
const StyledText = styled.div`
  grid-area: text-area;

  margin: auto;
  margin-left: 0;

  font-size: 1rem;
  line-height: 1.2rem;
`;

const CandidateNoTests: React.FC = () => {
  return (
    <StyledNoTests>
      <StyledImage src={NoDegrees} />
      <StyledText>This student has no previous tests</StyledText>
    </StyledNoTests>
  );
};

export default CandidateNoTests;
