import React, { useState } from 'react';
import styled from 'styled-components';
import SearchInputText from '../../../components/SearchInputText';

import { colors } from '../../../theme';

import Recipient from '../../../types/Recipient';
import CrossBlue from '../../../assets/vectors/CrossBlue.svg';
import RecipientSelectorList from './RecipientSelectorList';

const StyledRecipientSelector = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;

  overflow-y: scroll;
`;

const StyledTitle = styled.div`
  font-size: 2rem;
`;

const StyledRecipientSearchInput = styled.div`
  min-height: 10rem;

  margin: 2rem;
  margin-bottom: 0rem;
`;

const StyledSearchInputText = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 1.3rem;

  border-radius: 8px;
  border: 1px solid ${colors.gray_light};
  background-color: ${colors.gray_light_l1};
`;

const StyledRecipientTag = styled.div`
  display: inline-block;
  flex-grow: 1;

  height: 2.5rem;
  min-width: 10rem;
  max-width: 24rem;
  margin: 0.5rem;
  padding: 0.4rem 2.6rem;
  border-radius: 100px;

  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  color: ${colors.black};
  background-color: ${colors.blue};
  background-image: url(${CrossBlue});
  background-repeat: no-repeat;
  background-position: 97%;

  cursor: pointer;
`;

const StyledSearchInput = styled(SearchInputText)`
  flex-grow: 1;
  width: auto;
`;

interface RecipientSelectorProps {
  onChangeSearchText: (text: string) => void;
  removeRecipient: (recipient: Recipient) => void;
  addRecipient: (recipient: Recipient) => void;
  recipients?: Recipient[];
  selectedRecipients: Recipient[];
  loadingRecipients: boolean;
}

const RecipientSelector: React.FC<RecipientSelectorProps> = ({
  onChangeSearchText,
  removeRecipient,
  addRecipient,
  selectedRecipients = [],
  recipients,
  loadingRecipients,
}) => {
  const [searchInput, setSearchInput] = useState('');

  const handleSearchTextChange = (text: string) => {
    setSearchInput(text);
    onChangeSearchText(text);
  };

  const handleOnClickRecipient = (recipient: Recipient) => {
    addRecipient(recipient);
    setSearchInput('');
  };

  return (
    <StyledRecipientSelector>
      <StyledRecipientSearchInput>
        <StyledTitle>Search candidates</StyledTitle>
        <StyledSearchInputText>
          {selectedRecipients.map((recipient) => (
            <StyledRecipientTag onClick={() => removeRecipient(recipient)} key={recipient.id}>
              {recipient.fullname}
            </StyledRecipientTag>
          ))}
          <StyledSearchInput value={searchInput} onChange={handleSearchTextChange} />
        </StyledSearchInputText>
      </StyledRecipientSearchInput>
      <RecipientSelectorList recipients={recipients} loading={loadingRecipients} onClickItem={handleOnClickRecipient} />
    </StyledRecipientSelector>
  );
};

export default RecipientSelector;
