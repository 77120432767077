import React from 'react';
import Page from 'views/Page';
import { Container } from './EventDetail.styled';
import EventDetailMainInfo from './EventDetailMainInfo';
import EventDetailDescription from './EventDetailDescription';
import Header from './header';
import RelatedCandidatesController from 'components/RelatedCandidates';

export interface EventDetailProps {
  id: string;
  capacity: number;
  company: string;
  description: string;
  endDate: Date;
  isHidden: boolean;
  isVirtual: boolean;
  image: string;
  startDate: Date;
  title: string;
  address: string;
  link: string;
  event_registrations: string[];
}

const EventDetail: React.FC<EventDetailProps> = ({
  id,
  capacity,
  company,
  description,
  endDate,
  isHidden,
  isVirtual,
  image,
  startDate,
  title,
  address,
  link,
  event_registrations,
}) => {
  return (
    <Page headerComponent={<Header eventID={id} isHidden={isHidden} />}>
      <Container>
        <EventDetailMainInfo
          title={title}
          capacity={capacity}
          image={image}
          company={company}
          startDate={startDate}
          endDate={endDate}
          isVirtual={isVirtual}
        />
        <EventDetailDescription description={description} address={address} link={link} />
        <RelatedCandidatesController toggleLabelLeft={'Subscribed'} candidatesUserIds={event_registrations} />
      </Container>
    </Page>
  );
};

export default EventDetail;
