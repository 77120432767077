import React from 'react';
import styled from 'styled-components';

import colors from '../../theme/colors';
import ResizeableCardSection from '../candidates/ResizeableCardSection';

const StyledInterestTag = styled.div`
  display: inline-block;

  min-width: 3.5rem;
  max-width: 25rem;
  height: 2rem;

  overflow: hidden;

  margin: 0.2rem;
  padding: 0.2rem;
  border-radius: 3px;

  font-size: 1rem;
  line-height: 1.6rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${colors.black};
  background-color: ${colors.gray_light_l2};
`;

interface CandidateInterestProps {
  interests: string[];
}

const InterestTag: React.FC<{ interest: string }> = ({ interest }) => {
  return <StyledInterestTag>{interest}</StyledInterestTag>;
};

const CandidateCardInterests: React.FC<CandidateInterestProps> = ({ interests }) => {
  return (
    <ResizeableCardSection title="Firms of Interest">
      {interests
        .sort((a, b) => a.length - b.length)
        .map((interest, i) => (
          <InterestTag key={i} interest={interest} />
        ))}
    </ResizeableCardSection>
  );
};

export default CandidateCardInterests;
