import React, { useState } from 'react';
import styled from 'styled-components';

import Recipient from '../../../types/Recipient';
import RecipientSelectorController from '../recipient/RecipientSelectorController';
import MessageSenderController from '../message/MessageSenderController';

const StyledNewChat = styled.div`
  display: grid;
  grid-template-rows: auto 10rem;
  height: 100%;

  overflow-y: scroll;

  border: 1px solid transparent;
`;

const NewChat: React.FC<{ recipients?: Recipient[] }> = ({ recipients = [] }) => {
  const [selectedRecipients, setSelectedRecipients] = useState(recipients);

  return (
    <StyledNewChat>
      <RecipientSelectorController
        setSelectedRecipients={setSelectedRecipients}
        selectedRecipients={selectedRecipients}
      />
      <MessageSenderController candidatesIds={selectedRecipients.map(({ id }) => id) || []} />
    </StyledNewChat>
  );
};

export default NewChat;
