import React, { Ref } from 'react';
import styled from 'styled-components';

import { colors } from '../theme';

const StyledTextArea = styled.textarea`
  display: block;

  width: 100%;
  padding: 0.8rem 1rem;

  border: 1px solid ${colors.gray_l1};
  border-radius: 4px;

  background-color: transparent;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6rem;
  color: ${colors.black};

  resize: none;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${colors.gray_l1};
  }
`;

export interface TextAreaProps {
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const TextAreaInput: React.FC<TextAreaProps> = ({
  placeholder = '',
  value = '',
  onChange = () => undefined,
  ...props
}) => {
  return (
    <StyledTextArea
      {...props}
      value={value}
      rows={10}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

interface HookTextAreaInput {
  name?: string;
  register?: Ref<HTMLTextAreaElement>;
}

export const HookTextAreaInput: React.FC<HookTextAreaInput> = ({ name, register }) => (
  <StyledTextArea name={name} ref={register} rows={10}/>
);

export default TextAreaInput;
