import React, { useState } from 'react';

import CandidateType from '../../types/Candidate';
import CandidateHeaderButton from './CandidateHeaderButton';
import GroupModalController from '../groups/GroupModalController';
import { PlusSign } from '../../assets/vectors';

interface CandidateGroupButtonProps {
  candidate: CandidateType;
}

const CandidateGroupButton: React.FC<CandidateGroupButtonProps> = ({ candidate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleOnClickAddGroup = () => setModalOpen((m) => !m);

  return (
    <>
      <CandidateHeaderButton onClick={handleOnClickAddGroup} text={'Add to group'} icon={PlusSign}>
        {modalOpen && <GroupModalController setModalOpen={setModalOpen} candidates={[candidate]} />}
      </CandidateHeaderButton>
    </>
  );
};

export default CandidateGroupButton;
