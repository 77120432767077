import React from 'react';
import styled from 'styled-components';

const StyledItems = styled.div`
  margin-top: 2rem;

  & > *:not(:last-child) {
    margin-bottom: 1.6rem;
  }
`;

const StyledTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 1.6rem;
`;

const StyledItemName = styled.span`
  font-weight: 900;
  font-size: 1.3rem;
  line-height: 1.6rem;
`;

const StyledItemValue = styled.a`
  margin-left: 0.6rem;

  font-size: 1.3rem;
  line-height: 1.6rem;
`;

interface CandidateInformationItemProps {
  name: string;
  values: string[] | string | null;
}

const CandidateInformationItem: React.FC<CandidateInformationItemProps> = ({ name, values }) => {
  const valueFormated = values ? (Array.isArray(values) ? values.join(', ') : values) : '-';
  return (
    <div>
      <StyledItemName>{name}:</StyledItemName>
      <StyledItemValue href={'mailto:' + valueFormated}>{valueFormated}</StyledItemValue>
    </div>
  );
};

const CandidateInformation: React.FC = ({ children, ...props }) => {
  return (
    <div {...props}>
      <StyledTitle>Candidate Information</StyledTitle>
      <StyledItems>{children}</StyledItems>
    </div>
  );
};

export { CandidateInformationItem };

export default CandidateInformation;
