import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { INITIAL_OFFSET, PAGE_SIZE } from 'elitegrad-common/src/components/pagination/PaginationState';
import { asJob } from 'elitegrad-common/src/transformers/jobs';
import Jobs from './Jobs';
import { useCandidatesQuery } from '../job/JobDetail/JobDetail.hooks';

import GET_JOBS from 'elitegrad-common/src/queries/getJobs.gql';
import { GetJobs, GetJobsVariables } from 'elitegrad-common/src/generated/GetJobs';

const JobsController: React.FC = () => {
  const [paginationOffset, setPaginationOffset] = useState(INITIAL_OFFSET);
  const { data, loading } = useQuery<GetJobs, GetJobsVariables>(GET_JOBS, {
    variables: {
      limit: PAGE_SIZE,
      offset: paginationOffset,
    },
  });

  const jobs = data?.recruit_job_posting.map((rcp) => asJob(rcp)) || [];
  const allIds = jobs
    .filter((c) => Boolean(c.candidatesUserIds.length))
    .map((c) => c.candidatesUserIds)
    .reduce((ss: string[], c) => [...ss, ...c], []);

  const { data: dataValidIds } = useCandidatesQuery(allIds);
  const validGPACandidates = dataValidIds?.grad_profile?.map((candidate) => candidate.user_id) || [];

  const jobsTwo = jobs.map((j) => ({
    ...j,
    candidatesUserIds: [...j.candidatesUserIds.filter((userId) => validGPACandidates.includes(userId))],
  }));

  return (
    <Jobs
      pagination={{
        count: data?.recruit_job_posting_aggregate.aggregate?.count || 0,
        limit: PAGE_SIZE,
        offset: paginationOffset,
      }}
      jobs={jobsTwo}
      loadingJobs={loading}
      onChangePaginationOffset={setPaginationOffset}
    />
  );
};

export default JobsController;
