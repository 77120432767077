import React from 'react';
import { useParams } from 'react-router-dom';
import { useEventDetailQuery } from './EventDetail.hook';
import EventDetail from './EventDetail';
import Spinner from 'elitegrad-common/src/components/SpinnerIsoType';
import { transformObjectToEventDetail } from './EventDetail.utils';

interface RouteParamsProps {
  id: string;
}

const EventDetailController = () => {
  const params = useParams<RouteParamsProps>();
  const { data, loading } = useEventDetailQuery(params.id);

  const event = transformObjectToEventDetail(data);

  if (loading || !event) {
    return <Spinner fullPage text="loading..." />;
  }

  return <EventDetail {...event} />;
};

export default EventDetailController;
