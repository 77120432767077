import styled, { css } from 'styled-components';

import colors from '../theme/colors';


interface HorizontalRulerProps {
  highlighted?: boolean;
}
const HorizontalRuler = styled.hr<HorizontalRulerProps>`
  border: solid 0 ${colors.gray_light};
  border-bottom-width: 1px;

  ${({ highlighted = false }) =>
    highlighted &&
    css`
      border-color: ${colors.red};
    `}
`;

export default HorizontalRuler;
