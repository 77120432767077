import React from 'react';
import styled from 'styled-components';
import Switch from '../Switch';

import FilterTitle from './FilterTitle';

const StyledFilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-right: 2rem;
`;

interface BooleanFilterItemProps {
  filterTitle: string;
  on: boolean;
  setFilterValue: (status: boolean) => void;
}

const BooleanFilterItem: React.FC<BooleanFilterItemProps> = ({ filterTitle, on, setFilterValue }) => {
  return (
    <StyledFilterItem>
      <FilterTitle title={filterTitle} />
      <Switch on={on} onChange={setFilterValue} />
    </StyledFilterItem>
  );
};

export default BooleanFilterItem;
