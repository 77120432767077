import styled from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';
import { ChevronDown } from 'elitegrad-common/src/assets/vectors';

export const StyledFilterSideBar = styled.div`
  height: 100vh;
  width: 17rem;
  min-width: 17rem;
  padding: 1.8rem 1.4rem;

  background-color: ${colors.gray_light_l1};

  overflow-y: scroll;
  overflow-x: hidden;

  & > * {
    margin: 2rem 0;
  }

  & > *:first-child {
    margin: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const ChevronDownRed = styled(ChevronDown)`
  max-height: 2.4rem;
  max-width: 2.4rem;

  min-height: 2.4rem;
  min-width: 2.4rem;

  & * {
    stroke: ${colors.red};
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  justify-content: space-around;
`;
