import React from 'react';
import CVCardController, { CVCardControllerProps } from '../CVCard';
import { CVsContainer, StyledHeaderButton, DownloadButtonContainer } from './CVsList.styled';

export interface CVsListProps {
  resumes: CVCardControllerProps[];
  downloadAllCVs?: () => void;
}

const CVsList: React.FC<CVsListProps> = ({ resumes, downloadAllCVs }) => {
  return (
    <>
      {downloadAllCVs && resumes.length > 0 && (
        <DownloadButtonContainer>
          <StyledHeaderButton big text="Download All Resumes" onClick={downloadAllCVs} />
        </DownloadButtonContainer>
      )}
      <CVsContainer>
        {resumes.map((resume) => (
          <CVCardController
            fullName={resume.fullName}
            candidateId={resume.candidateId}
            key={resume.uploadDocId}
            filename={resume.filename}
            hexContent={resume.hexContent}
            uploadDocId={resume.uploadDocId}
          />
        ))}
      </CVsContainer>
    </>
  );
};

export default CVsList;
