import gql from 'graphql-tag';

import { InterestRoleSelectorParts } from './fragments.gql';

export default gql`
  query GetInterestRolesSelection($role: String) {
    grad_interest_role_selection(where: { value: { _ilike: $role } }, limit: 100) {
      ...InterestRoleSelectorParts
    }
  }
  ${InterestRoleSelectorParts}
`;
