import React from 'react';
import styled from 'styled-components';

import Recipient from '../../../types/Recipient';
import SpinnerIsoType from '../../SpinnerIsoType';
import RecipientSelectorListItem from './RecipientSelectorListItem';

const StyledRecipientSelectorList = styled.div`
  overflow-y: scroll;
`;

interface RecipientSelectorListProps {
  onClickItem: (recipient: Recipient) => void;
  recipients?: Recipient[];
  loading: boolean;
}

const RecipientSelectorList: React.FC<RecipientSelectorListProps> = ({ onClickItem, recipients = [], loading }) => {
  return (
    <StyledRecipientSelectorList>
      {loading ? (
        <SpinnerIsoType text={'Searching candidates'} />
      ) : (
        recipients.map((recipient) => (
          <div onClick={() => onClickItem(recipient)} key={recipient.id}>
            <RecipientSelectorListItem recipient={recipient} />
          </div>
        ))
      )}
    </StyledRecipientSelectorList>
  );
};

export default RecipientSelectorList;
