import React, { useState, SyntheticEvent, useCallback } from 'react';

import CandidateType from 'elitegrad-common/src/types/Candidate';
import GroupModalController from 'elitegrad-common/src/components/groups/GroupModalController';
import { ChatModalNew } from 'elitegrad-common/src/components/messaging';
import {
  StyledClearSelection,
  StyledFooter,
  StyledMenuMoreAddGroupWhite,
  StyledMenuMoreSendMessageWhite,
  StyledMenuMoreDownloadCVWhite,
  StyledSecondaryButton,
  ButtonContainer,
  ButtonGroupContainer,
  ButtonContent,
} from './CandidateGridFooter.styled';

interface CandidateGridFooterProps {
  selectedCandidates: CandidateType[];
  clearSelection: () => void;
  onClickDownloadCvs: () => void;
  refetch: () => void;
  cvsCount: number;
  downloadingCVs: boolean;
}

const CandidateGridFooter: React.FC<CandidateGridFooterProps> = ({
  selectedCandidates,
  onClickDownloadCvs,
  clearSelection,
  downloadingCVs,
  refetch,
  cvsCount,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenMessaging, setModalOpenMessaging] = useState(false);

  const selectedCount = selectedCandidates.length;
  const displayFooter = !!selectedCount;

  const handleMenuClick = () => setModalOpen((m) => !m);

  const handleMenuMessagingClick = () => setModalOpenMessaging((m) => !m);

  const handleMenuDownloadCvs = () => onClickDownloadCvs();

  const handlePropagation = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const downloadCVsButtonMessage =
    cvsCount < 1
      ? 'None selected candidates has a CV'
      : `Download ${cvsCount} CV${cvsCount > 1 ? 's' : ''} from selected candidate${cvsCount > 1 ? 's' : ''}`;

  if (!displayFooter) return null;

  return (
    <StyledFooter onClick={handlePropagation}>
      <StyledClearSelection onClick={clearSelection} />
      <ButtonGroupContainer>
        <ButtonContainer>
          <StyledSecondaryButton onClick={handleMenuClick}>
            <ButtonContent>
              <StyledMenuMoreAddGroupWhite />
              {`Add ${selectedCount} student${selectedCount > 1 ? 's' : ''} to group`}
            </ButtonContent>
          </StyledSecondaryButton>
        </ButtonContainer>
        <ButtonContainer>
          <StyledSecondaryButton onClick={handleMenuMessagingClick}>
            <ButtonContent>
              <StyledMenuMoreSendMessageWhite />
              {`Send ${selectedCount} message${selectedCount > 1 ? 's' : ''} to candidate${
                selectedCount > 1 ? 's' : ''
              } `}
            </ButtonContent>
          </StyledSecondaryButton>
        </ButtonContainer>
        <ButtonContainer>
          <StyledSecondaryButton onClick={handleMenuDownloadCvs} disabled={cvsCount < 1 || downloadingCVs}>
            <ButtonContent>
              <StyledMenuMoreDownloadCVWhite />
              {downloadCVsButtonMessage}
            </ButtonContent>
          </StyledSecondaryButton>
        </ButtonContainer>
      </ButtonGroupContainer>

      {modalOpenMessaging && <ChatModalNew setModalOpen={setModalOpenMessaging} candidates={selectedCandidates} />}
      {modalOpen && (
        <GroupModalController
          refetch={() => {
            setModalOpen(false);
            refetch();
            clearSelection();
          }}
          setModalOpen={setModalOpen}
          candidates={selectedCandidates}
        />
      )}
    </StyledFooter>
  );
};

export default CandidateGridFooter;
