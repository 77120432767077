import styled from 'styled-components';

import colors from 'elitegrad-common/src/theme/colors';

export const StyledCandidateCardBorder = styled.div<{ selected?: boolean }>`
  width: 28.5rem;
  padding: 0.5rem 0.5rem 2.2rem;

  border: none;
  border-radius: 8px;

  background-color: ${({ selected }) => (selected ? colors.green : colors.red)};
`;

export const StyledCandidateCard = styled.div`
  position: relative;

  height: 100%;
  padding: 1rem;

  border: none;
  border-radius: 8px;

  background-color: ${colors.white};
`;
