import React, { useState, useContext, useCallback, useEffect } from 'react';

import queryGeneratorMap from '../components/filters/queryFilters/mappedQueries';
import { INITIAL_OFFSET } from '../components/pagination/PaginationState';
import { WhereQueryInterface } from '../queries/queryUtils';
import { FilterValueType } from '../types/FilterValueType';
import JobRequirement from '../types/JobRequirement';

export const useFilter = (
  filterId: string,
): {
  storeFilterValue: (filterValue: FilterValueType) => void;
  storeFilterStatus: (query: WhereQueryInterface, filterValue: FilterValueType) => void;
  filterValue: FilterValueType | null;
  clearFilter: () => void;
  filterActive: () => void;
  filterActiveWithId: (queryId: string) => boolean;
} => {
  const { addQueryWithId, getFilterValue, filterActiveWithId, removeQueryWithId, handleOffSet } = useContext(
    UseFilterContext,
  );

  const storeFilterStatus = useCallback(
    (query: WhereQueryInterface, filterValue: FilterValueType) => {
      addQueryWithId({ filterId, query, filterValue });
    },
    [addQueryWithId, filterId],
  );

  const storeFilterValue = useCallback(
    (filterValue: FilterValueType) => {
      addQueryWithId({ filterId, filterValue });
      handleOffSet(INITIAL_OFFSET);
    },
    [addQueryWithId, filterId, handleOffSet],
  );

  const filterValue = getFilterValue(filterId);

  const clearFilter = useCallback(() => {
    removeQueryWithId(filterId);
  }, [removeQueryWithId, filterId]);

  const filterActive = useCallback(() => {
    filterActiveWithId(filterId);
  }, [filterId, filterActiveWithId]);

  return {
    storeFilterStatus,
    filterValue,
    clearFilter,
    filterActive,
    filterActiveWithId,
    storeFilterValue,
  };
};

export const useFilterQueries = (): { getFilterQueries: () => WhereQueryInterface[]; getOffset: () => number } => {
  const { getFilterQueries, getOffset } = useContext(UseFilterContext);
  return { getFilterQueries, getOffset };
};

export const usePagination = (): { setOffSet: (offset: number) => void; getOffset: () => number } => {
  const { handleOffSet, getOffset } = useContext(UseFilterContext);
  return { setOffSet: handleOffSet, getOffset };
};

export const useFilterClear = (): { clearFilters: () => void } => {
  const { clearFilters } = useContext(UseFilterContext);

  return { clearFilters };
};

export const useFilterGetter = (): { filterActiveWithId: (queryId: string) => boolean } => {
  const { filterActiveWithId } = useContext(UseFilterContext);

  return { filterActiveWithId };
};

interface FilterContextProviderProps {
  persistState?: boolean;
  initialValue?: JobRequirement[];
  onChange?: ({
    values,
    queries,
  }: {
    values: { [x: string]: FilterValueType };
    queries: WhereQueryInterface[];
  }) => void;
}

interface FilterState {
  [key: string]: FilterValueType;
}

interface QueryState {
  [key: string]: WhereQueryInterface;
}

export const initialFilterContext = {
  addQueryWithId: () => undefined,
  getFilterValue: () => null,
  removeQueryWithId: () => undefined,
  filterActiveWithId: () => false,
  clearFilters: () => undefined,
  getFilterQueries: () => [],
  getFilterValuesAsJobRequirements: () => [],
  handleOffSet: () => undefined,
  getOffset: () => INITIAL_OFFSET,
};

export const UseFilterContext = React.createContext<{
  addQueryWithId: ({
    filterId,
    query,
    filterValue,
  }: {
    filterId: string;
    query?: WhereQueryInterface;
    filterValue: FilterValueType;
  }) => void;
  getFilterValue: (queryId: string) => FilterValueType | null;
  removeQueryWithId: (queryId: string) => void;
  filterActiveWithId: (queryId: string) => boolean;
  clearFilters: () => void;
  getFilterQueries: () => WhereQueryInterface[];
  handleOffSet: (value: number) => void;
  getOffset: () => number;
}>(initialFilterContext);

export const FilterContextProvider: React.FC<FilterContextProviderProps> = ({
  children,
  initialValue = [],
  persistState = true,
  onChange,
}) => {
  const localStorageValue = localStorage.getItem('filterValues');
  const defaultFilterValues: FilterState = persistState
    ? localStorageValue
      ? JSON.parse(localStorageValue || '')
      : {}
    : initialValue.reduce((map, parts) => {
        const generator = queryGeneratorMap[parts.name];
        if (!generator) {
          console.warn(`No Generator for : ${parts.name} removing filter`); // eslint-disable-line no-console
          return map;
        } else {
          try {
            generator(parts.value);
            return { ...map, [parts.name]: parts.value };
          } catch (error) {
            console.warn(`Error parsing queryFilter: ${error}`); // eslint-disable-line no-console
            return map;
          }
        }
      }, {});

  const defaultFilterQueries: QueryState = (Object.entries(defaultFilterValues) || []).reduce((prev, [key, value]) => {
    try {
      const generator = queryGeneratorMap[key];
      if (!generator) {
        console.warn(`No Generator for : ${key}`); // eslint-disable-line no-console
        return prev;
      } else return { ...prev, [key]: generator(value) };
    } catch (error) {
      console.warn(`Error parsing queryFilter: ${error}`); // eslint-disable-line no-console
      return prev;
    }
  }, {});
  const localStorageValuePagination: number = Number.parseInt(JSON.parse(localStorage.getItem('pagination') || '0'));

  const [offset, setOffset] = useState(localStorageValuePagination);
  const [filterValues, setFilterValues] = useState<FilterState>(defaultFilterValues);
  const [filterQueries, setFilterQueries] = useState<QueryState>(defaultFilterQueries);

  useEffect(() => {
    if (persistState) localStorage.setItem('filterValues', JSON.stringify(filterValues));
  }, [filterValues, persistState]);

  useEffect(() => {
    if (persistState) localStorage.setItem('filterQueries', JSON.stringify(filterQueries));
  }, [filterQueries, persistState]);

  const setFilterValuesQueries = (
    newFilterValues: { [key: string]: FilterValueType },
    newFilterQueries: { [key: string]: WhereQueryInterface },
  ) => {
    setFilterValues(newFilterValues);
    setFilterQueries(newFilterQueries);
    if (onChange) onChange({ values: newFilterValues, queries: Object.values(newFilterQueries) });
  };

  const getFilterValue = (queryId: string) => (filterValues[queryId] === undefined ? null : filterValues[queryId]);
  const filterActiveWithId = (filterId: string) => getFilterValue(filterId) !== null;

  const getOffset = () => offset || INITIAL_OFFSET;
  const handleOffSet = (value: number) => {
    setOffset(value);
    localStorage.setItem('pagination', JSON.stringify(value));
  };

  const addQueryWithId = ({
    filterId,
    query,
    filterValue,
  }: {
    filterId: string;
    query?: WhereQueryInterface;
    filterValue: FilterValueType;
  }) => {
    const newFilterValues = { ...filterValues, [filterId]: filterValue };
    const newFilterQueries = query ? { ...filterQueries, [filterId]: query } : filterQueries;
    setFilterValuesQueries(newFilterValues, newFilterQueries);
    handleOffSet(INITIAL_OFFSET);
  };

  const removeQueryWithId = (id: string) => {
    const { [id]: removeValue, ...newFilterValues } = filterValues; // eslint-disable-line @typescript-eslint/no-unused-vars
    const { [id]: removeQuery, ...newFilterQueries } = filterQueries; // eslint-disable-line @typescript-eslint/no-unused-vars
    setFilterValuesQueries(newFilterValues, newFilterQueries);
  };

  const getFilterQueries = () => Object.values(filterQueries);
  const clearFilters = () => setFilterValuesQueries({}, {});

  return (
    <UseFilterContext.Provider
      value={{
        clearFilters,
        addQueryWithId,
        getFilterValue,
        filterActiveWithId,
        removeQueryWithId,
        getFilterQueries,
        handleOffSet,
        getOffset,
      }}
    >
      {children}
    </UseFilterContext.Provider>
  );
};
