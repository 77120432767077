import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../theme/colors';

interface StyledButtonProps {
  big: boolean;
}

const StyledButton = styled.button.attrs({
  type: 'button',
})<StyledButtonProps>`
  margin: auto 0;
  border: none;
  border-radius: 4px;
  border-color: ${colors.white};
  padding: 0.5rem 1rem;

  background-color: ${colors.white};
  cursor: pointer;

  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${colors.black};

  :focus {
    outline: none;
  }

  ${({ big }) =>
    big &&
    css`
      min-height: 4rem;
      min-width: 11rem;
      height: 3.4rem;

      font-weight: 700;
    `}
`;

export interface SecondaryButtonProps {
  text?: string;
  big?: boolean;
  onClick?: () => void;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({ text, children, big = false, ...props }) => {
  return (
    <StyledButton big={big} {...props}>
      {text}
      {children}
    </StyledButton>
  );
};

export default SecondaryButton;
