import { useQuery } from '@apollo/client';
import { GetEventDetails, GetEventDetailsVariables } from 'elitegrad-common/src/generated/GetEventDetails';
import GET_EVENT_DETAILS from 'elitegrad-common/src/queries/getEventDetails.gql';

export const useEventDetailQuery = (eventID: string) => {
  const getEvent = useQuery<GetEventDetails, GetEventDetailsVariables>(GET_EVENT_DETAILS, {
    variables: { eventID },
    fetchPolicy: 'network-only',
  });

  return getEvent;
};
