import React from 'react';
import styled from 'styled-components';

import { Calendar, ChevronDown } from 'elitegrad-common/src/assets/vectors';
import ViewTitle from 'elitegrad-common/src/components/ViewTitle';
import { colors } from 'elitegrad-common/src/theme';

import FiltersGroup from 'elitegrad-common/src/components/filters/FiltersGroup';
import FilterItem from 'elitegrad-common/src/components/filters/FilterItem';
import {
  ACTFilter,
  SATFilter,
  GPAFilter,
  GMATFilter,
  UniversityFilter,
  MajorFilter,
  InterestRegionsFilter,
  InterestFirmsFilter,
  InterestJobTypesFilter,
  ProfessionalDesignationFilter,
  UndergradDegreeDateFilter,
  GraduateDegreeDateFilter,
  AthleteFilter,
  LanguageFilter,
  InterestRolesFilter,
  ComputerLanguageFilter,
  GREFilter,
  RelevantYearsOfWorkFilter,
  GroupedDegreeFilter,
} from 'elitegrad-common/src/components/filters/queryFilters';
import { useFilterGetter } from 'elitegrad-common/src/utils/useFilter';

const StyledRequirementsSideBar = styled.div`
  height: 100vh;
  width: 22rem;
  min-width: 22rem;
  padding: 1.8rem 1.4rem;

  background-color: ${colors.gray_light_l1};

  overflow-y: auto;
  overflow-x: hidden;

  & > * {
    margin: 2rem 0;
  }

  & > *:first-child {
    margin: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

const ChevronDownRed = styled(ChevronDown)`
  max-height: 2.4rem;
  max-width: 2.4rem;

  min-height: 2.4rem;
  min-width: 2.4rem;

  & * {
    stroke: ${colors.red};
  }
`;

const RequirementsSideBar: React.FC = () => {
  const { filterActiveWithId } = useFilterGetter();

  return (
    <StyledRequirementsSideBar>
      <ViewTitle>Job Requirements</ViewTitle>

      <FiltersGroup name="Academic">
        <FilterItem text="Athlete" icon={ChevronDownRed} filterActive={filterActiveWithId('AthleteFilter')}>
          <AthleteFilter name={'AthleteFilter'} />
        </FilterItem>
        <FilterItem text="Schools" icon={ChevronDownRed} filterActive={filterActiveWithId('UniversityFilter')}>
          <UniversityFilter name="UniversityFilter" />
        </FilterItem>
        <FilterItem text="Degree" icon={ChevronDownRed} filterActive={filterActiveWithId('DegreeFilter')}>
          <GroupedDegreeFilter name={'DegreeFilter'} />
        </FilterItem>
        <FilterItem
          text="Undergrad Degree"
          icon={Calendar}
          filterActive={filterActiveWithId('UndergradDegreeDateFilter')}
        >
          <UndergradDegreeDateFilter name="UndergradDegreeDateFilter" />
        </FilterItem>
        <FilterItem
          text="Graduate Degree"
          icon={Calendar}
          filterActive={filterActiveWithId('GraduateDegreeDateFilter')}
        >
          <GraduateDegreeDateFilter name="GraduateDegreeDateFilter" />
        </FilterItem>
        <FilterItem text="GPA" icon={ChevronDownRed} filterActive={filterActiveWithId('GPAFilter')}>
          <GPAFilter name={'GPAFilter'} nameNonApplicable={'GPAFilterNotApplicable'} />
        </FilterItem>
        <FilterItem text="Major" icon={ChevronDownRed} filterActive={filterActiveWithId('MajorFilter')}>
          <MajorFilter name="MajorFilter" />
        </FilterItem>

        <FilterItem
          text="Work Experience / Prior Internships"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('RelevantYearsOfWorkFilter')}
        >
          <RelevantYearsOfWorkFilter name={'RelevantYearsOfWorkFilter'} />
        </FilterItem>

        <FilterItem
          text="Professional Designation"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('ProfessionalDesignationFilter')}
        >
          <ProfessionalDesignationFilter name="ProfessionalDesignationFilter" />
        </FilterItem>
        <FilterItem text="Language" icon={ChevronDownRed} filterActive={filterActiveWithId('LanguageFilter')}>
          <LanguageFilter name={'LanguageFilter'} />
        </FilterItem>
        <FilterItem
          text="Technical Language"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('ComputerLanguageFilter')}
        >
          <ComputerLanguageFilter name={'ComputerLanguageFilter'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Test">
        <FilterItem text="SAT" icon={ChevronDownRed} filterActive={filterActiveWithId('SATFilter')}>
          <SATFilter name={'SATFilter'} nameNonApplicable={'SATFilterNotApplicable'} />
        </FilterItem>
        <FilterItem text="ACT" icon={ChevronDownRed} filterActive={filterActiveWithId('ACTFilter')}>
          <ACTFilter name={'ACTFilter'} nameNonApplicable={'ACTFilterNotApplicable'} />
        </FilterItem>
        <FilterItem text="GMAT" icon={ChevronDownRed} filterActive={filterActiveWithId('GMATFilter')}>
          <GMATFilter name="GMATFilter" />
        </FilterItem>
        <FilterItem text="GRE" icon={ChevronDownRed} filterActive={filterActiveWithId('GREFilter')}>
          <GREFilter name={'GREFilter'} nameNonApplicable={'GREFilterNotApplicable'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Interest">
        <FilterItem text="Firm Types" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestFirmsFilter')}>
          <InterestFirmsFilter name={'InterestFirmsFilter'} />
        </FilterItem>
        <FilterItem text="Jobs" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestJobTypesFilter')}>
          <InterestJobTypesFilter name={'InterestJobTypesFilter'} />
        </FilterItem>
        <FilterItem text="Roles" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestRolesFilter')}>
          <InterestRolesFilter name={'InterestRolesFilter'} />
        </FilterItem>
        <FilterItem text="Regions" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestRegionsFilter')}>
          <InterestRegionsFilter name={'InterestRegionsFilter'} />
        </FilterItem>
      </FiltersGroup>
    </StyledRequirementsSideBar>
  );
};

export default RequirementsSideBar;
