import React, { useMemo } from 'react';
import styled from 'styled-components';
import WithSeparator from 'react-with-separator';

import { colors } from '../../theme';

import Header from '../../components/Header';
import VerticalDivider from '../../components/VerticalDivider';

const StyledOptions = styled.div`
  display: flex;

  min-width: 45rem;
  min-height: 3.5rem;

  & > :nth-child(odd) {
    margin: auto;
  }
`;

const StyledVerticalDivider = styled(VerticalDivider)`
  background: ${colors.gray_light_l2};
`;

const CandidateHeader: React.FC = ({ children }) => {
  const separator = useMemo<React.ReactElement>(() => <StyledVerticalDivider />, []);

  return (
    <Header backLink={true}>
      <StyledOptions>
        <WithSeparator separator={separator}>{children}</WithSeparator>
      </StyledOptions>
    </Header>
  );
};

export default CandidateHeader;
