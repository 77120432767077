import React from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';
import BooleanFilterClear from '../BooleanFilterClear';

export const generateQuery = (isAthlete: boolean): WhereQueryInterface => {
  const queryAthleteFilter: WhereQueryInterface = {
    enabled: true,
    operator: '_and',
    whereQuery: { student_athlete: { _eq: isAthlete } },
  };
  return queryAthleteFilter;
};

const AthleteFilter: React.FC<{ name: string }> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const setFilterValue = (isAthlete: boolean) => storeFilterStatus(generateQuery(isAthlete), isAthlete);
  return (
    <BooleanFilterClear
      defaultValue={filterValue as boolean}
      filterTitle={'Student Athlete'}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    />
  );
};

export default AthleteFilter;
