import React from 'react';
import styled from 'styled-components';

import Test from '../../types/Test';
import CandidateTestScoreDetails from './CandidateTestScoreDetails';

const StyledTestScores = styled.div<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.6rem' : '1.2rem')};

  padding: 0.5rem;
  min-height: 4.9rem;
`;

const StyledTestType = styled.span`
  line-height: 1.5rem;
  font-weight: 700;
`;

const StyledScore = styled.span`
  margin-left: 0.8rem;

  line-height: 1.5rem;
  font-weight: 700;
`;

const StyledOutOf = styled.span`
  margin-right: 0.8rem;

  line-height: 1.5rem;
  font-weight: 500;
`;

interface CandidateTestScoreSatProps {
  satTest: Test;
  big?: boolean;
}

const CandidateTestScoreSat: React.FC<CandidateTestScoreSatProps> = ({ satTest, big = false }) => {
  return (
    <StyledTestScores big={big} key={satTest.id}>
      <StyledTestType>{satTest.type}:</StyledTestType>
      <StyledScore>{satTest.score}</StyledScore>
      <StyledOutOf>/{satTest.outof}</StyledOutOf>
      <CandidateTestScoreDetails big={big} details={satTest.details} />
    </StyledTestScores>
  );
};

export default CandidateTestScoreSat;
