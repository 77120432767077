import Award from './Award';
import AwardCategory from './AwardCategory';
import Concentration from './Concentration';
import ConcentrationType from './ConcentrationType';
import DegreeLevel from './DegreeLevel';
import University from './University';

export const MIN_GPA_OUT_OF = 3;
export const MAX_GPA_OUT_OF = 5;
export const DEFAULT_GPA_OUT_OF = 4;
export const gpaName = 'GPA';

interface Degree {
  id: string;
  startDate: Date | null;
  completeDate: Date | null;
  degreeLevel: DegreeLevel;
  degreeName: string;
  gpa: number | null;
  gpaOutOf: number | null;
  awards: Award[];
  concentrations: Concentration[];
  university: University | null;
  userId: string;
  gpaNotApplicable: boolean;
}

const getAwardName = (degree: Degree): string | null => {
  return degree.awards.filter((a) => a.category === AwardCategory.ACADEMIC_HONOR)[0]?.name || null;
};

const getMajorName = (degree: Degree): string | null => {
  return degree.concentrations.filter((c) => c.type === ConcentrationType.MAJOR)[0]?.name || null;
};

const getDegreesSortedByCompleteDate = (degrees: Degree[]): Degree[] => {
  return degrees.sort((a, b) => {
    if (a.completeDate === null) {
      return -1;
    } else if (b.completeDate === null) {
      return 1;
    } else {
      return b.completeDate.getTime() - a.completeDate.getTime();
    }
  });
};

export { getAwardName, getMajorName, getDegreesSortedByCompleteDate };

export default Degree;
