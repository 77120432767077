import React from 'react';
import { editEventPath } from 'routes';
import { Icon, ShowStatusLink, Text } from './EventStatusEdit.styled';

interface EventStatusEditProps {
  eventID: string;
}

const EventStatusEdit: React.FC<EventStatusEditProps> = ({ eventID }) => {
  return (
    <ShowStatusLink to={editEventPath(eventID)}>
      <Icon />
      <Text>Edit</Text>
    </ShowStatusLink>
  );
};

export default EventStatusEdit;
