import React from 'react';

import { WhereQueryInterface } from '../../../queries/queryUtils';
import { gpaName, MIN_GPA_OUT_OF, MAX_GPA_OUT_OF } from '../../../types/Degree';
import { RangeType } from '../../../types/FilterValueType';
import { useFilter } from '../../../utils/useFilter';
import BooleanFilterItem from '../BooleanFilterItem';
import RangeFilter from '../RangeFilter';

export interface GPAFilterProps {
  name: string;
  nameNonApplicable: string;
}

export const generateQuery = ([minValue, maxValue]: RangeType, nonApplicable: boolean): WhereQueryInterface => {
  const queryGPAFilter: WhereQueryInterface = {
    enabled: true,
    operator: '_and',
    whereQuery: {
      degrees: {
        _or: [
          { _and: [{ gpa: { _gte: minValue } }, { gpa: { _lte: maxValue } }] },
          ...(!nonApplicable ? [{ gpa_not_applicable: { _eq: true } }] : []),
        ],
      },
    },
  };

  return queryGPAFilter;
};

const GPAFilter: React.FC<GPAFilterProps> = ({ name, nameNonApplicable }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);
  const { filterValue: filterValueNonApplicable, storeFilterValue } = useFilter(nameNonApplicable);

  const setFilterValue = (range: [number, number]) => {
    storeFilterStatus(generateQuery(range, filterValueNonApplicable as boolean), range);
  };

  return (
    <RangeFilter
      precision={2}
      filterTitle={gpaName}
      range={[MIN_GPA_OUT_OF, MAX_GPA_OUT_OF]}
      value={filterValue as RangeType}
      step={0.01}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    >
      <BooleanFilterItem
        on={filterValueNonApplicable as boolean}
        filterTitle={'Hide non applicable'}
        setFilterValue={storeFilterValue}
      />
    </RangeFilter>
  );
};

export default GPAFilter;
