import React, { SyntheticEvent, useCallback } from 'react';
import styled from 'styled-components';
import ExitCross from '../components/ExitCross';

import { colors } from '../theme';

const StyledModal = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`;

const StyledModalContent = styled.div<{ heightAuto?: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  width: 58rem;
  height: ${({ heightAuto }) => (heightAuto ? 'auto' : '67rem')};

  background: ${colors.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export interface ModalProps {
  setModalOpen: (status: boolean) => void;
  heightAuto?: boolean;
}

const Modal: React.FC<ModalProps> = ({ setModalOpen, heightAuto = false, children }) => {
  const handlePropagation = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const handleModalOnClick = useCallback(
    (e: SyntheticEvent) => {
      setModalOpen(false);
      handlePropagation(e);
    },
    [handlePropagation, setModalOpen],
  );

  return (
    <StyledModal onClick={handleModalOnClick} onMouseEnter={handlePropagation} onMouseLeave={handlePropagation}>
      <StyledModalContent heightAuto={heightAuto} onClick={handlePropagation}>
        <ExitCross onClick={handleModalOnClick} />

        {children}
      </StyledModalContent>
    </StyledModal>
  );
};

export default Modal;
