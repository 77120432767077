import React from 'react';
import styled from 'styled-components';
import WithSeparator from 'react-with-separator';

import Page from 'views/Page';

import Header from 'elitegrad-common/src/components/Header';
import NotFoundView from 'elitegrad-common/src/components/NotFoundView';
import VerticalDivider from 'elitegrad-common/src/components/VerticalDivider';
import HorizontalRuler from 'elitegrad-common/src/components/HorizontalRuler';
import {
  CandidatePreviousDegree,
  CandidateNoDegrees,
  CandidateNoWorkExperiences,
  CandidateWorkExperience,
  CandidateNoTests,
} from 'elitegrad-common/src/components/candidate';

import { colors } from 'elitegrad-common/src/theme';
import CandidateHeader from 'elitegrad-common/src/components/candidate/CandidateHeader';
import CandidateIdentity from 'elitegrad-common/src/components/candidate/CandidateIdentity';
import CandidateInterests from 'elitegrad-common/src/components/candidate/CandidateInterests';
import CandidateMessageButton from 'elitegrad-common/src/components/candidate/CandidateMessageButton';
import CandidateGroupButton from 'elitegrad-common/src/components/candidate/CandidateGroupButton';
import DownloadCVHeaderButton from 'elitegrad-common/src/components/candidate/DownloadCVHeaderButton';

import CandidateInformation, {
  CandidateInformationItem,
} from 'elitegrad-common/src/components/candidate/CandidateInformation';

import { getDegreesSortedByCompleteDate } from 'elitegrad-common/src/types/Degree';
import CandidateType, { isFullTime, isInternship } from 'elitegrad-common/src/types/Candidate';
import CandidateTestScoreSat from 'elitegrad-common/src/components/candidate/CandidateTestScoreSat';
import CandidateTestScoreOthers from 'elitegrad-common/src/components/candidate/CandidateTestScoreOthers';
import { getTestsSortedByPriority } from 'elitegrad-common/src/types/Test';
import AchievementsVariablesController from 'elitegrad-common/src/components/candidate/PersonalAchievementsController';
import VolunteerActivitiesController from 'elitegrad-common/src/components/candidate/VolunteerActivitiesController';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import { messagingPath } from 'routes';

const StyledCandidateInformation = styled(CandidateInformation)`
  margin-bottom: 5.5rem;
`;

const StyledInterests = styled.div`
  width: 40rem;

  & > *:not(:last-child) {
    margin-bottom: 5.5rem;
  }
`;

const StyledWorkExperiences = styled.div`
  & > * {
    padding: 1.7rem;
    margin: 1rem;
    min-height: 11rem;
    background-color: ${colors.gray_l2};
    border-radius: 4px;
  }
`;

const StyledMainLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr 1rem) 1fr;
  grid-template-rows: 1fr;

  margin: 4rem 3.1rem 0;
`;

const StyledColumnLayout = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 60rem;

  margin: 0.5rem 1rem;
`;

const StyledColumnTitle = styled.div`
  margin-bottom: 1rem;

  font-weight: 900;
  font-size: 1.4rem;
  line-height: 1.6rem;
`;

const StyledHorizontalRuler = styled(HorizontalRuler)`
  margin: 0 0 1rem;
`;

const StyledDegrees = styled.div`
  & > * {
    padding: 1.7rem;
    margin: 1rem;
    min-height: 11rem;

    background-color: ${colors.gray_l2};
    border-radius: 4px;
  }
`;

interface CandidateProps {
  candidate: CandidateType | null;
  loadingCandidate: boolean;
}

const Candidate: React.FC<CandidateProps> = ({ candidate, loadingCandidate }) => {
  if (loadingCandidate || !candidate) {
    const backComponent = <Header backLink={true} />;
    return (
      <Page headerComponent={backComponent}>
        {loadingCandidate ? <SpinnerIsoType text="Loading" /> : <NotFoundView />}
      </Page>
    );
  }
  const [, ...previousDegrees] = getDegreesSortedByCompleteDate(candidate.degrees);

  const headerComponent = (
    <CandidateHeader>
      <CandidateGroupButton candidate={candidate} />
      <CandidateMessageButton messagingPath={messagingPath(candidate.userId)} />
      <DownloadCVHeaderButton candidateId={candidate.userId} />
    </CandidateHeader>
  );

  const interestJobTypes: string[] = [
    ...(isFullTime(candidate) ? ['Full Time'] : []),
    ...(isInternship(candidate) ? ['Internship'] : []),
  ];
  const [satAct, ...otherTests] = getTestsSortedByPriority(candidate.tests);

  return (
    <Page headerComponent={headerComponent}>
      <CandidateIdentity candidate={candidate} />

      <StyledMainLayout>
        <StyledColumnLayout>
          <StyledColumnTitle>Test Score</StyledColumnTitle>
          <WithSeparator separator={<StyledHorizontalRuler />}>
            {satAct && <CandidateTestScoreSat satTest={satAct} big={false} />}
            {otherTests.length > 0 && <CandidateTestScoreOthers tests={otherTests} big={false} />}
            {candidate.tests.length === 0 && <CandidateNoTests />}
          </WithSeparator>
        </StyledColumnLayout>
        <VerticalDivider />

        <StyledColumnLayout>
          <StyledColumnTitle>Previous Degrees</StyledColumnTitle>
          <StyledDegrees>
            {previousDegrees.map((d) => (
              <CandidatePreviousDegree big={false} degree={d} key={d.id} />
            ))}
            {previousDegrees.length === 0 && <CandidateNoDegrees />}
          </StyledDegrees>
        </StyledColumnLayout>
        <VerticalDivider />

        <StyledColumnLayout>
          <StyledColumnTitle>Work Experience / Prior Internships</StyledColumnTitle>
          <StyledWorkExperiences>
            {candidate.workExperiences.map((we) => (
              <CandidateWorkExperience big={false} workExperience={we} key={we.id} />
            ))}
            {candidate.workExperiences.length === 0 && <CandidateNoWorkExperiences />}
          </StyledWorkExperiences>
        </StyledColumnLayout>
        <VerticalDivider />

        <StyledColumnLayout>
          <StyledCandidateInformation>
            <CandidateInformationItem name="Email Address" values={candidate.email} />
            <CandidateInformationItem name="Hometown" values={candidate.hometown} />
            <CandidateInformationItem name="Phone Number" values={candidate.mobile} />
            <CandidateInformationItem name="Relevant Years of Experience" values={candidate.yearsOfRelevantWork} />
            <CandidateInformationItem name="Languages" values={candidate.spokenLanguages} />
            <CandidateInformationItem name="What I want an employer to know about me" values={candidate.funFacts} />
            <CandidateInformationItem name="Technical Languages" values={candidate.computerLanguages} />
            <CandidateInformationItem name="Is student athlete" values={candidate.studentAthlete ? 'Yes' : 'No'} />
          </StyledCandidateInformation>
          <StyledInterests>
            {!!candidate.interestFirmTypes.length && (
              <CandidateInterests title="Interested Firm Type" interests={candidate.interestFirmTypes} />
            )}
            {!!interestJobTypes.length && (
              <CandidateInterests title="Interested Job Type" interests={interestJobTypes} />
            )}
            {!!candidate.interestRegions.length && (
              <CandidateInterests title="Interested Regions" interests={candidate.interestRegions} />
            )}
            {!!candidate.interestRoles.length && (
              <CandidateInterests title="Interested Roles" interests={candidate.interestRoles} />
            )}
            {!!candidate.employerPreferences.length && (
              <CandidateInterests title="What I Look For in an Employer" interests={candidate.employerPreferences} />
            )}
            <AchievementsVariablesController candidateId={candidate.userId} />
            <VolunteerActivitiesController candidateId={candidate.userId} />
          </StyledInterests>
        </StyledColumnLayout>
      </StyledMainLayout>
    </Page>
  );
};

export default Candidate;
