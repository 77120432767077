import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import WithSeparator from 'react-with-separator';

import { colors } from '../../../theme';
import Template from '../../../types/Template';
import { usePopper } from '../../../utils/usePopper';
import TemplatePickerPopper from './TemplatePickerPopper';
import HorizontalRuler from '../../../components/HorizontalRuler';
import SearchInputText from '../../../components/SearchInputText';
import MessageTemplate from '../../../assets/vectors/MessageTemplate.svg';
import ExitCross from '../../../components/ExitCross';

const StyledTitle = styled.div`
  padding: 1rem;

  font-size: 1.8rem;

  border-bottom: 1px solid ${colors.gray_light};
`;

const StyledPicker = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;

  max-height: 26rem;
  margin: 2rem;
`;

const TemplateItem = styled.li`
  margin: 1rem;

  font-size: 1.6rem;
  line-height: 1.9rem;

  cursor: pointer;
`;

const TemplateList = styled.ul`
  list-style: none;

  overflow-y: scroll;
`;

const StyledSearchInputText = styled(SearchInputText)`
  width: 100%;
  margin: 1rem 0;

  border: 1px solid ${colors.gray_light};
  border-radius: 8px;

  background-color: ${colors.white};
`;

const StyledTemplateButton = styled.div`
  padding: 3rem 2.5rem 0;
  margin: auto;

  opacity: 0.5;
  background-image: url(${MessageTemplate});
  background-repeat: no-repeat;
  background-position: 1rem -0.2rem;

  cursor: pointer;
`;

export interface TemplatePickerProps {
  onTemplateClick: (content: string) => void;
  templates: Template[];
  loading: boolean;
}

const filterTemplates = (templates: Template[], textFilter: string): Template[] => {
  return templates.filter((t) => t.title.toLowerCase().includes(textFilter.toLowerCase()));
};

const TemplatePicker: React.FC<TemplatePickerProps> = ({ onTemplateClick, templates }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [templateFilter, setTemplateFilter] = useState('');

  const { popperOpen, togglePopper, setPopperStatus } = usePopper('TemplatePicker');
  const separator = useMemo<React.ReactElement>(() => <HorizontalRuler />, []);

  const handleTemplateClick = (content: string) => {
    onTemplateClick(content);
    setPopperStatus(false);
  };
  return (
    <div>
      <StyledTemplateButton onClick={togglePopper} ref={setReferenceElement} />
      <TemplatePickerPopper referenceElement={referenceElement} show={popperOpen}>
        <StyledPicker>
          <StyledTitle>
            Templates <ExitCross onClick={() => setPopperStatus(false)} />
          </StyledTitle>

          <StyledSearchInputText placeholder="Search" value={templateFilter} onChange={setTemplateFilter} />
          <TemplateList>
            <WithSeparator separator={separator}>
              {filterTemplates(templates, templateFilter).map((t) => (
                <TemplateItem key={t.id} onClick={() => handleTemplateClick(t.content)}>
                  {t.title}
                </TemplateItem>
              ))}
            </WithSeparator>
          </TemplateList>
        </StyledPicker>
      </TemplatePickerPopper>
    </div>
  );
};

export default TemplatePicker;
