import Achievement from '../types/Achievement';
import { AchievementParts } from '../generated/AchievementParts';

const asAchievement = (parts: AchievementParts): Achievement => {
  return {
    id: parts.achievement_id,
    description: parts.description,
  };
};

export { asAchievement };
