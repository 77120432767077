import React from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';

import FreeTextFilter from '../FreeTextFilter';

export interface CombineFilterProps {
  name: string;
}

export const generateQuery = (value: string): WhereQueryInterface => {
  const words = value.split(',').map((word) => ({ content: { _ilike: `%${word.trim()}%` } }));

  const queryCombineFilter: WhereQueryInterface = {
    enabled: !!value.length,
    operator: '_and',
    whereQuery: { combine: { _and: words } },
  };

  return queryCombineFilter;
};

const CombineFilter: React.FC<CombineFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);

  const setFilterValue = (value: string) => storeFilterStatus(generateQuery(value), value);

  return (
    <FreeTextFilter
      filterTitle={'Keyword'}
      defaultValue={(filterValue as string) || ''}
      setFilterValue={setFilterValue}
      textArea={true}
    />
  );
};

export default CombineFilter;
