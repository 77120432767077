import React from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';
import BooleanFilterItem from '../BooleanFilterItem';

export const generateQuery = (isSponsorship: boolean): WhereQueryInterface => {
  const querySponsorshipFilter: WhereQueryInterface = {
    enabled: isSponsorship,
    operator: '_and',
    whereQuery: { need_sponsor: { _eq: false } },
  };
  return querySponsorshipFilter;
};

const SponsorshipFilter: React.FC<{ name: string }> = ({ name }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);

  const setFilterValue = (isSponsorship: boolean) => {
    storeFilterStatus(generateQuery(isSponsorship), isSponsorship);
  };

  return (
    <BooleanFilterItem
      on={!!filterValue}
      filterTitle={'Does Not Require Visa Sponsorship'}
      setFilterValue={setFilterValue}
    />
  );
};

export default SponsorshipFilter;
