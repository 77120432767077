import React from 'react';
import styled from 'styled-components';

import colors from '../../theme/colors';

const StyledInterests = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-top: 1.5rem;
  }

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

const StyledTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 1.6rem;
`;

const StyledInterestTag = styled.div`
  display: inline-block;
  min-width: 3.5rem;
  max-width: 18rem;

  padding: 0.4rem 0.4rem 0.4rem;
  border-radius: 3px;

  background-color: ${colors.black};

  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  color: ${colors.white};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface CandidateInterestProps {
  interests: string[];
  title: string;
}

const InterestTag: React.FC<{ interest: string }> = ({ interest }) => {
  return <StyledInterestTag>{interest}</StyledInterestTag>;
};

const CandidateInterests: React.FC<CandidateInterestProps> = ({ interests, title }) => {
  return (
    <div>
      <StyledTitle>{title}</StyledTitle>
      <StyledInterests>
        {interests.map((interest, i) => (
          <InterestTag key={i} interest={interest} />
        ))}
      </StyledInterests>
    </div>
  );
};

export default CandidateInterests;
