import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from 'elitegrad-common/src/theme';
import { UploadCloud, CircleSlashRed } from 'elitegrad-common/src/assets/vectors';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import SecondaryButton from 'elitegrad-common/src/components/SecondaryButton';
import HeaderPopper from 'elitegrad-common/src/components/HeaderPopper';
import { usePopper } from 'elitegrad-common/src/utils/usePopper';

const StyledShowStatus = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 2.4rem;
  grid-template-areas: 'icon legend';

  cursor: pointer;

  margin: auto;
`;

const StyledText = styled.div`
  grid-area: legend;

  margin-left: 1rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
`;

const StyledIconCircleSlashRed = styled(CircleSlashRed)`
  grid-area: icon;

  width: 2.4rem;
`;

const StyledIconUploadCloud = styled(UploadCloud)`
  grid-area: icon;

  width: 2.4rem;
`;

const StyledPopperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  & > div:first-child {
    margin: auto;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 1.5rem 2rem;
    min-width: 3.5rem;
    min-height: 7rem;

    background-color: ${colors.gray_extraLight};
  }
`;

const StyledDismissAndText = styled(PrimaryButton)`
  padding: 0;
`;

interface JobStatusActiveProps {
  jobActiveDefaultValue: boolean;
  loading: boolean;
  setActiveCallback: (active: boolean) => void;
  setModalOpen: (status: boolean) => void;
  hasCandidates: boolean;
}

const JobStatusActive: React.FC<JobStatusActiveProps> = ({
  jobActiveDefaultValue,
  setActiveCallback,
  setModalOpen,
  hasCandidates,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const [active, setActive] = useState(jobActiveDefaultValue);
  const { popperOpen, togglePopper, setPopperStatus } = usePopper('JobStatusActive');

  const onSave = () => {
    setActiveCallback(!active);
    setActive((e: boolean) => !e);
    setPopperStatus(false);
  };

  const handleDismissAndText = () => {
    onSave();
    setModalOpen(true);
  };

  return (
    <>
      <StyledShowStatus onClick={togglePopper} ref={setReferenceElement}>
        {active ? <StyledIconCircleSlashRed /> : <StyledIconUploadCloud />}
        <StyledText>{active ? 'Dismiss' : 'Activate'}</StyledText>
      </StyledShowStatus>
      <HeaderPopper referenceElement={referenceElement} show={popperOpen}>
        <StyledPopperContent>
          <div>{`Are you sure you want to ${active ? 'dismiss' : 'activate'} this Job?`}</div>
          <div>
            <SecondaryButton big onClick={() => setPopperStatus(false)} text={'No, Cancel'} />
            <PrimaryButton dark big onClick={onSave} text={`Yes, ${active ? 'Dismiss' : 'Activate'}`} />

            {hasCandidates && active && (
              <StyledDismissAndText dark big onClick={handleDismissAndText} text={'Yes, dismiss and message'} />
            )}
          </div>
        </StyledPopperContent>
      </HeaderPopper>
    </>
  );
};

export default JobStatusActive;
