import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';

import { Trash as TrashIcon } from 'elitegrad-common/src/assets/vectors';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import SecondaryButton from 'elitegrad-common/src/components/SecondaryButton';
import Spinner from 'elitegrad-common/src/components/Spinner';
import HeaderPopper from 'elitegrad-common/src/components/HeaderPopper';
import { usePopper } from 'elitegrad-common/src/utils/usePopper';

const StyledShowStatus = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 2.4rem;
  grid-template-areas: 'icon legend';

  cursor: pointer;

  margin: auto;
`;

const StyledIcon = styled(TrashIcon)`
  grid-area: icon;

  width: 2.4rem;
  height: 2.4rem;

  & * {
    stroke: ${colors.red};
  }
`;

const StyledPopperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  & > div:first-child {
    margin: auto;
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 1.5rem 2rem;
    min-width: 3.5rem;
    min-height: 7rem;

    background-color: ${colors.gray_extraLight};
  }
`;

const StyledText = styled.div`
  grid-area: legend;

  margin-left: 1rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.red};
`;

interface JobStatusDeleteProps {
  loading: boolean;
  onDelete: () => void;
}

const LoadingPopperContent: React.FC = () => {
  return (
    <div>
      <Spinner />
      <div>Deleting Job ...</div>
    </div>
  );
};

const PopperContent: React.FC<{ onDelete: () => void; setShow: (show: boolean) => void }> = ({ setShow, onDelete }) => {
  return (
    <>
      <div>Are you sure you want to delete this Job?</div>
      <div>
        <SecondaryButton big onClick={() => setShow(false)} text={'No, Cancel'} />
        <PrimaryButton dark big onClick={onDelete} text={'Yes, Delete'} />
      </div>
    </>
  );
};

const JobStatusDelete: React.FC<JobStatusDeleteProps> = ({ loading, onDelete }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const { popperOpen, togglePopper, setPopperStatus } = usePopper('JobStatusDelete');

  return (
    <>
      <StyledShowStatus onClick={togglePopper} ref={setReferenceElement}>
        <StyledIcon />
        <StyledText>Delete</StyledText>
      </StyledShowStatus>
      <HeaderPopper referenceElement={referenceElement} show={popperOpen}>
        <StyledPopperContent>
          {loading ? <LoadingPopperContent /> : <PopperContent setShow={setPopperStatus} onDelete={onDelete} />}
        </StyledPopperContent>
      </HeaderPopper>
    </>
  );
};

export default JobStatusDelete;
