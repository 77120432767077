import React from 'react';
import StatusHidden from 'views/job/JobStatusHidden';
import { useEventStatusHiddenMutation } from './EventStatusHidden.hook';

interface EventStatusHiddenController {
  eventID: string;
  isHidden: boolean;
}

const EventStatusHiddenController: React.FC<EventStatusHiddenController> = ({ eventID, isHidden }) => {
  const [hideEvent, { loading }] = useEventStatusHiddenMutation();
  const onClickHide = (hidden: boolean) => {
    hideEvent({
      variables: {
        eventID,
        show_event: !hidden,
      },
    });
  };

  return <StatusHidden loading={loading} jobHidden={isHidden} onCLickHide={onClickHide} />;
};

export default EventStatusHiddenController;
