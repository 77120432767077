import React from 'react';

import Message from '../../../types/Message';
import styled, { css } from 'styled-components';
import { colors } from '../../../theme';
import { toHoursDate } from '../../../utils/dateUtils';
import MessageStatusIndicator from './MessageStatusIndicator';

const StyledHours = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;

  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};
`;

const StyledTextBlob = styled.div<{ inc: boolean }>`
  position: relative;

  margin: 1rem;
  margin-left: ${({ inc }) => (inc ? '1rem' : '28rem')};
  margin-right: ${({ inc }) => (inc ? '28rem' : '1rem')};
  text-align: ${({ inc }) => (inc ? 'left' : 'right')};

  border-radius: 8px;
  background-color: ${({ inc }) => (inc ? colors.white : colors.blue)};
  box-shadow: 0px 2px 6px rgba(10, 22, 70, 0.1);

  ::after {
    content: ' ';
    position: absolute;
    top: 0;

    width: 0;
    height: 0;
    ${({ inc }) =>
      inc
        ? css`
            left: -2rem;
          `
        : css`
            right: -2rem;
          `}

    border: 22px solid;
    border-color: transparent;
    border-top-color: ${({ inc }) => (inc ? colors.white : colors.blue)};
  }
`;

const StyledTextContent = styled.div`
  display: grid;
  grid-template-rows: 2rem 1fr 2rem;

  padding: 1rem;
  min-height: 4rem;
`;

interface MessageListItemProps {
  message: Message;
}

const MessageListItem: React.FC<MessageListItemProps> = ({ message, ...props }) => {
  return (
    <StyledTextBlob inc={message.fromCandidate} {...props}>
      <StyledTextContent>
        <div>{!message.fromCandidate && <MessageStatusIndicator message={message} />}</div>
        <div>{message.content}</div>
        <StyledHours>{toHoursDate(message.dateSent)}</StyledHours>
      </StyledTextContent>
    </StyledTextBlob>
  );
};

export default MessageListItem;
