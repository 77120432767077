import { AwardParts } from '../generated/AwardParts';
import Award from '../types/Award';
import AwardCategory from '../types/AwardCategory';

const asAwardCategory = (category: string | null): AwardCategory | null => {
  if (category !== null && (Object.values(AwardCategory) as string[]).includes(category)) {
    return category as AwardCategory;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unknown award category: ${category}`);
  return null;
};

const asAward = (parts: AwardParts): Award => {
  return {
    id: parts.award_id,
    category: asAwardCategory(parts.award_category),
    name: parts.award_name,
    yearReceived: parts.year_received,
    degreeId: parts.degree_id,
    userId: parts.user_id,
    workExperienceId: parts.work_experience_id,
  };
};

export { asAward };
