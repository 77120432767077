import React, { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';

import GET_TEMPLATES from '../../../queries/getTemplates.gql';
import INSERT_TEMPLATE from '../../../queries/insertTemplate.gql';
import { GetTemplates } from '../../../generated/GetTemplates';
import { InsertTemplateVariables, InsertTemplate } from '../../../generated/InsertTemplate';
import TemplateListItemEdit from './TemplateListItemEdit';
import TemplateListItemNew from './TemplateListItemNew';
import { useAuth } from '../../..//utils/react-auth';

const TemplateListItemNewController: React.FC = () => {
  const [saveMode, setSaveMode] = useState(false);
  const { user } = useAuth();

  const [insertTemplate, { loading: loadingInsert }] = useMutation<InsertTemplate, InsertTemplateVariables>(
    INSERT_TEMPLATE,
    {
      onCompleted: () => {
        setSaveMode(false);
      },
      update: (cache, { data: createData }) => {
        const newTemplate = createData?.insert_recruit_sms_template?.returning[0];
        if (!newTemplate) return;

        const getTemplates = cache.readQuery<GetTemplates>({ query: GET_TEMPLATES });
        if (!getTemplates) return;

        const newTemplates = [newTemplate, ...getTemplates.recruit_sms_template];
        cache.writeQuery({ query: GET_TEMPLATES, data: { recruit_sms_template: newTemplates } });
      },
    },
  );

  const handleOnClickSave = useCallback(
    (content: string, title: string) => {
      if (!user?.sub) return;
      insertTemplate({ variables: { content, title, recruiterId: user?.sub } });
    },
    [insertTemplate, user],
  );

  const handleOnClickTrash = () => setSaveMode(false);
  return saveMode ? (
    <TemplateListItemEdit
      componentTitle={'New template'}
      disable={loadingInsert}
      onClickTrash={handleOnClickTrash}
      onClickSave={handleOnClickSave}
      disableSaveMode={() => setSaveMode(false)}
    />
  ) : (
    <TemplateListItemNew onClick={() => setSaveMode(true)} />
  );
};

export default TemplateListItemNewController;
