import styled from 'styled-components';
import colors from 'elitegrad-common/src/theme/colors';

import VerticalDivider from 'elitegrad-common/src/components/VerticalDivider';

export const HeaderOptions = styled.div`
  display: flex;

  min-width: 45rem;
  min-height: 3.5rem;
`;

export const HeaderVerticalDivider = styled(VerticalDivider)`
  background: ${colors.gray_light_l2};
`;
