import React from 'react';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';

import ConversationListItem from './ConversationListItem';
import Conversation from '../../../types/Conversation';
import { PlusSign } from '../../../assets/vectors';
import colors from '../../../theme/colors';
import ConversationListSearch from './ConversationListSearch';
import SpinnerIsoType from '../../SpinnerIsoType';

const StyledConversationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  background: ${colors.gray_light_l1};
  border-bottom: 1px solid ${colors.gray_light};
`;

const StyledConversationsHeader = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;

  max-width: 30rem;
  padding: 2rem;

  border-bottom: 1px solid ${colors.gray_light};
`;
const StyledConversationsList = styled.div`
  display: grid;
  grid-template-rows: 12rem 1fr;

  height: 100%;
  max-width: 30rem;
`;

const StyledTitle = styled.div`
  margin: auto 0;

  font-size: 2rem;
`;

const StyledNewChat = styled(Link)`
  margin: auto 0 auto auto;
  display: flex;

  cursor: pointer;
`;

const StyledPlusSign = styled(PlusSign)`
  width: 2rem;
`;

interface ConversationListProps {
  candidateId?: string;
  conversations?: Conversation[];
  loading: boolean;
  onSearchChange: (name: string) => void;
  pathConversationNew: string;
  generateMessagingPath: (userId: string) => string;
}

const ConversationList: React.FC<ConversationListProps> = ({
  loading,
  conversations = [],
  onSearchChange,
  pathConversationNew,
  generateMessagingPath,
}) => {
  return (
    <StyledConversationsList>
      <StyledConversationsHeader>
        <StyledTitle>Messages</StyledTitle>
        <StyledNewChat to={pathConversationNew}>
          <StyledPlusSign />
          <div>New Chat</div>
        </StyledNewChat>
        <ConversationListSearch onChange={onSearchChange} />
      </StyledConversationsHeader>
      <StyledConversationsContainer>
        {loading ? (
          <SpinnerIsoType text={'Searching existing conversations'} />
        ) : (
          conversations.map((conversation) => (
            <NavLink
              to={generateMessagingPath(conversation.id)}
              key={conversation.id}
              activeStyle={{ backgroundColor: colors.white }}
              style={{ borderBottom: `1px solid ${colors.gray_light}` }}
            >
              <ConversationListItem conversation={conversation} />
            </NavLink>
          ))
        )}
      </StyledConversationsContainer>
    </StyledConversationsList>
  );
};

export default ConversationList;
