import { colors } from 'elitegrad-common/src/theme';
import styled from 'styled-components';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 50rem;
  margin: 0 auto;
`;

export const SaveButton = styled(PrimaryButton)`
  margin-left: 1rem;
`;

export const SubmitButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const Spacing = styled.div`
  padding: 1rem 0;
`;

export const UnlimitedCapacityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;
`;

export const UnlimitedCapacityLabel = styled.div`
  padding: 1rem 1rem 1rem 0;
  font-size: 1.5rem;

  line-height: 1.8rem;
  color: ${colors.black};
`;

export const DateTimeRangeContainer = styled.div`
  display: flex;

  justify-content: space-between;

  padding: 2rem 0;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 1.4rem;
  margin-top: 0.5rem;

  :before {
    display: inline;
    content: '⚠ ';
  }
`;

export const DatePickerContainer = styled.div`
  .react-datetime-picker__wrapper {
    width: 100%;
    height: 3.4rem;

    border: 1px solid ${colors.gray_l1};
    border-radius: 4px;
    background-color: transparent;

    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6rem;
    color: ${colors.black};

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${colors.gray_l1};
    }
  }
`;
