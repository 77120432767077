import { useMutation, useQuery } from '@apollo/client';

import GET_EVENT from 'elitegrad-common/src/queries/getEvent.gql';
import { GetEvent, GetEventVariables } from 'elitegrad-common/src/generated/GetEvent';

import UPDATE_EVENT from 'elitegrad-common/src/queries/updateEvent.gql';
import { UpdateEvent, UpdateEventVariables } from 'elitegrad-common/src/generated/UpdateEvent';

export const useEditEventQuery = (userID: string) => {
  const getEvent = useQuery<GetEvent, GetEventVariables>(GET_EVENT, { variables: { id: userID } });

  return getEvent;
};

export const useEditEventMutation = () => {
  const [uploadEvent] = useMutation<UpdateEvent, UpdateEventVariables>(UPDATE_EVENT);

  return uploadEvent;
};
