import gql from 'graphql-tag';

import { ConversationParts } from './fragments.gql';

export default gql`
  subscription GetConversations($candidateId: String, $name: String, $limit: Int) {
    recruit_sms_correspondent(
      limit: $limit
      order_by: { last_message_timestamp: desc_nulls_last }
      where: { correspondent_user_id: { _eq: $candidateId }, grad_profile: { fullname: { _ilike: $name } } }
    ) {
      ...ConversationParts
    }
  }
  ${ConversationParts}
`;
