import React, { useState } from 'react';

import CVCard from './CVCard';
import { CVCardProps } from './CVCard';
import { useDownloadCV, usePreviewCV } from './CVCard.hooks';

export type CVCardControllerProps = Omit<
  CVCardProps,
  'onClickPreview' | 'onClickDownload' | 'isOpeningCV' | 'isDownloadingCV'
>;

const CVCardController: React.FC<CVCardControllerProps> = ({
  candidateId,
  filename,
  fullName,
  hexContent,
  uploadDocId,
}) => {
  const [downloadingCV, setDownloadingCV] = useState(false);
  const [openingCV, setOpeningCV] = useState(false);

  const downloadCV = useDownloadCV(uploadDocId, hexContent, setDownloadingCV);
  const previewCV = usePreviewCV(uploadDocId, setOpeningCV);

  const handleOnClickDownload = () => {
    setDownloadingCV(true);
    downloadCV();
  };

  const handleOnClickPreview = () => {
    setOpeningCV(true);
    previewCV();
  };
  return (
    <CVCard
      isOpeningCV={openingCV}
      isDownloadingCV={downloadingCV}
      onClickDownload={handleOnClickDownload}
      onClickPreview={handleOnClickPreview}
      candidateId={candidateId}
      uploadDocId={uploadDocId}
      filename={filename}
      hexContent={hexContent}
      fullName={fullName}
    />
  );
};

export default CVCardController;
