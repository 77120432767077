import React from 'react';
import { useQuery } from '@apollo/client';

import GET_PERSONAL_ACHIEVEMENTS from '../../queries/getPersonalAchievements.gql';
import { GetPersonalAchievements, GetPersonalAchievementsVariables } from '../../generated/GetPersonalAchievements';
import { asAchievement } from '../../transformers/achievements';
import PersonalAchievements from './PersonalAchievements';

const PersonalAchievementsController: React.FC<{ candidateId: string }> = ({ candidateId }) => {
  const { data } = useQuery<GetPersonalAchievements, GetPersonalAchievementsVariables>(GET_PERSONAL_ACHIEVEMENTS, {
    skip: !candidateId,
    variables: { candidateId },
  });

  const achievements = data?.grad_achievement.map((a) => asAchievement(a)) || [];

  return <PersonalAchievements achievements={achievements} />;
};

export default PersonalAchievementsController;
