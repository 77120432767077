import React from 'react';
import WithSeparator from 'react-with-separator';
import styled from 'styled-components';
import HorizontalRuler from '../HorizontalRuler';

const StyledFiltersGroup = styled.div`
  & > *:last-child {
    margin-bottom: 0;
  }
`;

const StyledName = styled.div`
  margin-bottom: 0.6rem;

  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.6rem;
`;

interface FiltersGroupProps {
  name?: string;
}

const FiltersGroup: React.FC<FiltersGroupProps> = ({ name = null, children }) => {
  const separator = <HorizontalRuler />;

  return (
    <StyledFiltersGroup>
      {name && <StyledName>{name}</StyledName>}
      <HorizontalRuler highlighted />

      <WithSeparator separator={separator}>{children}</WithSeparator>
      {separator}
    </StyledFiltersGroup>
  );
};

export default FiltersGroup;
