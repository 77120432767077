import React from 'react';
import Header from 'elitegrad-common/src/components/Header';
import { Link } from 'react-router-dom';
import { Paths, eventPath } from 'routes';
import Page from '../../Page';
import EventCard from 'components/events/EventCard';
import { HeaderButton, PaginationWrapper, EventListContainer } from './EventList.styled';
import Pagination, { PaginationState } from 'elitegrad-common/src/components/pagination';
import { GetEvents as EventListType } from 'elitegrad-common/src/generated/GetEvents';
import { timestampToLocalDate } from 'utils/dates';

const EventsHeader = (
  <Header title="Events">
    <Link to={Paths.newEvent}>
      <HeaderButton big text="Create new" />
    </Link>
  </Header>
);

interface EventsProps {
  eventsList?: EventListType;
  pagination: PaginationState;
  onPageChange: (offset: number) => void;
}

const EventList: React.FC<EventsProps> = ({ eventsList, pagination, onPageChange }) => {
  return (
    <Page headerComponent={EventsHeader}>
      <EventListContainer>
        {eventsList?.recruit_event?.map((event) => (
          <Link key={event.event_id} to={eventPath(event.event_id)}>
            <EventCard
              title={event.title}
              capacity={event.capacity}
              picture={event.image || ''}
              isVirtual={event.event_type === 'Online'}
              startDate={timestampToLocalDate(event.start_datetime, event.timezone)}
              endDate={timestampToLocalDate(event.end_datetime, event.timezone)}
              isHidden={!event.show_event}
            />
          </Link>
        ))}
      </EventListContainer>
      <PaginationWrapper>
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </PaginationWrapper>
    </Page>
  );
};

export default EventList;
