import styled, { keyframes } from 'styled-components';
import { MenuMoreDownloadCV, FilePDF, Spinner } from 'elitegrad-common/src/assets/vectors';
import colors from 'elitegrad-common/src/theme/colors';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const CVContainer = styled.div`
  text-align: center;
  width: 150px;
  margin-top: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  padding: 1rem;

  box-shadow: 0 2px 17px ${colors.gray_light};
  border-radius: 8px;

  background-color: ${colors.white};
`;

export const CandidateName = styled.div`
  padding-top: 1rem;
  text-align: center;
`;

export const Icon = styled.div`
  font-size: 1rem;
  cursor: pointer;
  width: 2rem;
  margin-left: 0.5rem;
  :last-child {
    margin-right: 1rem;
  }
`;

export const IconGroup = styled.div`
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
`;

export const DownloadIcon = styled(MenuMoreDownloadCV)`
  font-size: 1rem;
`;
export const BusyDownload = styled(Spinner)`
  animation: ${rotate360} 1s linear infinite;
  font-size: 0.7rem;
`;
export const BusyPreview = styled(Spinner)`
  animation: ${rotate360} 1s linear infinite;
  width: 4rem;
  margin-right: 1rem;
  vertical-align: bottom;
`;

export const PdfDocumentIcon = styled(FilePDF)`
  width: 4rem;
  margin-right: 1rem;
  vertical-align: bottom;
`;

export const ComposedIcon = styled.div<{ linkWorking: boolean }>`
  cursor: ${(props) => (props.linkWorking ? 'pointer' : 'default')};
`;
