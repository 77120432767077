import gql from 'graphql-tag';

const AccountParts = gql`
  fragment AccountParts on recruit_account {
    account_id
    account_name
  }
`;

const SubIndustryParts = gql`
  fragment SubIndustryParts on recruit_company_subindustry {
    company_subindustry_id
    value
  }
`;

const CompanyParts = gql`
  fragment CompanyParts on recruit_company {
    company_id
    account {
      ...AccountParts
    }
    account_id
    address
    description
    industry
    logo
    name
    num_employees
    phone
    subindustries {
      ...SubIndustryParts
    }
    website
  }
  ${AccountParts}
  ${SubIndustryParts}
`;

const AchievementParts = gql`
  fragment AchievementParts on grad_achievement {
    achievement_id
    description
  }
`;

const LanguageSelectorParts = gql`
  fragment LanguageSelectorParts on grad_spoken_language {
    language
    spoken_language_id
  }
`;

const ComputerLanguageSelectorParts = gql`
  fragment ComputerLanguageSelectorParts on grad_computer_language_selection {
    computer_language
  }
`;

const FillInUniversityParts = gql`
  fragment FillInUniversityParts on grad_degree_fill_in_university {
    degree_id
    institution_name
    user_id
  }
`;

const VolunteerActivitiesParts = gql`
  fragment VolunteerActivitiesParts on grad_volunteer {
    volunteer_id
    description
  }
`;

const AwardParts = gql`
  fragment AwardParts on grad_award {
    award_id
    award_category
    award_name
    year_received

    degree_id
    user_id
    work_experience_id
  }
`;

const ComputerLanguageParts = gql`
  fragment ComputerLanguageParts on grad_computer_language {
    computer_language_id
    language
    user_id
  }
`;

const TemplateParts = gql`
  fragment TemplateParts on recruit_sms_template {
    title
    text
    sms_template_id
    creation_timestamp
  }
`;

const ConcentrationParts = gql`
  fragment ConcentrationParts on grad_concentration {
    concentration_id
    type
    val

    degree_id
    user_id
  }
`;

const InterestRoleSelectorParts = gql`
  fragment InterestRoleSelectorParts on grad_interest_role_selection {
    value
    interest_role_selection_id
  }
`;
const InterestRegionSelectorParts = gql`
  fragment InterestRegionSelectorParts on grad_interest_region_selection {
    value
    interest_region_selection_id
  }
`;

const InterestFirmSelectorParts = gql`
  fragment InterestFirmSelectorParts on grad_interest_firm_selection {
    value
    interest_firm_selection_id
  }
`;

const InterestJobTypeSelectorParts = gql`
  fragment InterestJobTypeSelectorParts on grad_interest_job_type_selection {
    value
    interest_job_type_selection_id
  }
`;

const MajorSelectorParts = gql`
  fragment MajorSelectorParts on grad_major_selection {
    major
    major_selection_id
  }
`;

const UniversityParts = gql`
  fragment UniversityParts on grad_university_selection {
    university_selection_id
    location_name
    logo_url
  }
`;

const DegreeSelectorParts = gql`
  fragment DegreeSelectorParts on grad_degree_selection {
    degree
    degree_level
    degree_selection_id
    description
  }
`;

const DegreeParts = gql`
  fragment DegreeParts on grad_degree {
    degree_id
    complete_month_numeric
    complete_year
    degree
    degree_level
    gpa
    gpa_outof
    start_month_numeric
    start_year
    user_id
    gpa_not_applicable

    awards {
      ...AwardParts
    }

    concentrations {
      ...ConcentrationParts
    }

    university {
      ...UniversityParts
    }

    fill_in_university {
      ...FillInUniversityParts
    }
  }
  ${AwardParts}
  ${ConcentrationParts}
  ${UniversityParts}
  ${FillInUniversityParts}
`;

const GroupParts = gql`
  fragment GroupParts on recruit_grad_group {
    group_name
    grad_group_id
  }
`;

const GroupMembershipParts = gql`
  fragment GroupMembershipParts on recruit_grad_group_member {
    recruiter_user_id
    student_user_id
    grad_group {
      ...GroupParts
    }
  }

  ${GroupParts}
`;

const FunFactParts = gql`
  fragment FunFactParts on grad_fun_fact {
    fun_fact_id
    description
    user_id
  }
`;

const InterestRoleParts = gql`
  fragment InterestRoleParts on grad_interest_role {
    role
    interest_role_id
    user_id
  }
`;

const InterestFirmParts = gql`
  fragment InterestFirmParts on grad_interest_firm {
    firm_type
    interest_firm_id
    user_id
  }
`;

const InterestRegionParts = gql`
  fragment InterestRegionParts on grad_interest_region {
    interest_region_id
    region
    user_id
  }
`;

const InterestJobTypeParts = gql`
  fragment InterestJobTypeParts on grad_interest_job_type {
    interest_job_type_id
    job_type
    user_id
  }
`;

const EmployerPreferencesParts = gql`
  fragment EmployerPreferencesParts on grad_employer_preference {
    employer_preference_id
    preference
    user_id
  }
`;

const TestScoreDetailParts = gql`
  fragment TestScoreDetailParts on grad_test_score_detail {
    detail
    outof
    score
    test
    test_score_detail_id
  }
`;
const TestScoreParts = gql`
  fragment TestScoreParts on grad_test_score {
    score
    outof
    test
    user_id
    test_score_id

    test_score_details {
      ...TestScoreDetailParts
    }
  }

  ${TestScoreDetailParts}
`;

const WorkExperienceParts = gql`
  fragment WorkExperienceParts on grad_work_experience {
    work_experience_id
    city
    company
    end_month_numeric
    end_year
    job_type
    position
    start_month_numeric
    start_year
    state
    user_id
  }
`;

const CandidateImageOnlyParts = gql`
  fragment CandidateImageOnlyParts on grad_profile {
    user_id
    profile_image
  }
`;

const CandidatePartsBase = gql`
  fragment CandidatePartsBase on grad_profile {
    user_id
    firstname
    middlename
    lastname
    fullname
    title
    email
    hometown
    years_of_relevant_work
    mobile
    student_athlete
    need_sponsor
    authorized_to_work

    test_scores {
      ...TestScoreParts
    }
    degrees {
      ...DegreeParts
    }
    fun_facts {
      ...FunFactParts
    }

    employer_preferences {
      ...EmployerPreferencesParts
    }

    interest_firms {
      ...InterestFirmParts
    }
    interest_regions {
      ...InterestRegionParts
    }
    interest_job_types {
      ...InterestJobTypeParts
    }
    interest_roles {
      ...InterestRoleParts
    }
    work_experiences {
      ...WorkExperienceParts
    }
    computer_languages {
      ...ComputerLanguageParts
    }
    spoken_languages {
      ...LanguageSelectorParts
    }
  }

  ${TestScoreParts}
  ${DegreeParts}
  ${FunFactParts}
  ${InterestFirmParts}
  ${InterestJobTypeParts}
  ${InterestRegionParts}
  ${InterestRoleParts}
  ${EmployerPreferencesParts}
  ${WorkExperienceParts}
  ${ComputerLanguageParts}
  ${LanguageSelectorParts}
`;

const CandidateParts = gql`
  fragment CandidateParts on grad_profile {
    grad_group_members {
      recruiter_user_id
      student_user_id
      grad_group {
        group_name
        grad_group_id
      }
    }
    ...CandidatePartsBase
  }
  ${CandidatePartsBase}
`;

const CandidatePartsAdmin = gql`
  fragment CandidatePartsAdmin on grad_profile {
    blocked
    blocked_reason
    ...CandidatePartsBase
  }
  ${CandidatePartsBase}
`;

const CVParts = gql`
  fragment CVParts on grad_upload_doc {
    filename
    hex_content
    upload_doc_id
    user_id
  }
`;

const MessageParts = gql`
  fragment MessageParts on recruit_sms_message {
    content
    incoming
    recruiter_user_id
    sms_message_id
    timestamp
  }
`;

const MessageCorrespondentParts = gql`
  fragment MessageCorrespondentParts on recruit_sms_message_correspondent {
    message {
      content
      incoming
      recruiter_user_id
      sms_message_id
      timestamp
    }
  }
`;

const RecipientParts = gql`
  fragment RecipientParts on grad_profile {
    user_id
    firstname
    middlename
    lastname
    fullname
    title
    profile_image
  }
`;

const ConversationParts = gql`
  fragment ConversationParts on recruit_sms_correspondent {
    recruiter_user_id
    correspondent_user_id
    sms_correspondent_id
    last_incoming_timestamp
    last_message_timestamp
    last_outgoing_timestamp
    last_read_timestamp
    archived
    blocked
    conversation_ended
    blocked_reason
    grad_profile {
      ...RecipientParts
    }
  }
  ${RecipientParts}
`;

const RaisedHandParts = gql`
  fragment RaisedHandParts on grad_raise_hand {
    raise__hand_id
    user_id
    job_posting_id
  }
`;

const JobRequirementValueParts = gql`
  fragment JobRequirementValueParts on recruit_job_requirement_value {
    label
    value
    value_id
    job_requirement_value_id
  }
`;

const JobRequirementParts = gql`
  fragment JobRequirementParts on recruit_job_requirement {
    requirement_name
    requirement_type

    boolean_value
    job_requirement_id
    max_range_value
    min_range_value
    number_value

    values {
      ...JobRequirementValueParts
    }
  }
  ${JobRequirementValueParts}
`;

const RegionParts = gql`
  fragment RegionParts on grad_interest_region_selection {
    interest_region_selection_id
    value
  }
`;

const JobParts = gql`
  fragment JobParts on recruit_job_posting {
    job_posting_id
    account_id
    active
    creation_date
    creator_id
    deadline
    description
    firm
    job_type
    modified_date
    position_title
    publishing_date
    region
    public

    job_requirements {
      ...JobRequirementParts
    }

    raise_hands {
      ...RaisedHandParts
    }
  }

  ${JobRequirementParts}
  ${RaisedHandParts}
`;

export {
  CandidateParts,
  CandidateImageOnlyParts,
  JobParts,
  RaisedHandParts,
  RegionParts,
  GroupParts,
  MajorSelectorParts,
  GroupMembershipParts,
  UniversityParts,
  InterestRegionSelectorParts,
  InterestFirmSelectorParts,
  InterestJobTypeSelectorParts,
  InterestRoleParts,
  DegreeSelectorParts,
  JobRequirementParts,
  JobRequirementValueParts,
  ConversationParts,
  MessageParts,
  MessageCorrespondentParts,
  RecipientParts,
  TemplateParts,
  AwardParts,
  CVParts,
  AchievementParts,
  VolunteerActivitiesParts,
  InterestRoleSelectorParts,
  ComputerLanguageSelectorParts,
  CompanyParts,
  SubIndustryParts,
  CandidatePartsAdmin,
};
