import React from 'react';
import JobStatusHidden from './JobStatusHidden';
import JobType from 'elitegrad-common/src/types/Job';
import { useMutation } from '@apollo/client';
import {
  UpdateJobsStatusPublic,
  UpdateJobsStatusPublicVariables,
} from 'elitegrad-common/src/generated/UpdateJobsStatusPublic';
import UPDATE_JOB_STATUS_PUBLIC from 'elitegrad-common/src/queries/updateJobStatusPublic.gql';

interface JobStatusHiddenController {
  job: JobType;
}
const JobStatusHiddenController: React.FC<JobStatusHiddenController> = ({ job }) => {
  const [updateJobStatusHidden, { loading }] = useMutation<UpdateJobsStatusPublic, UpdateJobsStatusPublicVariables>(
    UPDATE_JOB_STATUS_PUBLIC,
  );

  const onClickHide = (hidden: boolean) => {
    const variables = {
      public: !hidden,
      jobId: job.id,
    };
    updateJobStatusHidden({ variables });
  };

  return <JobStatusHidden loading={loading} jobHidden={job.hidden} onCLickHide={onClickHide} />;
};

export default JobStatusHiddenController;
