import React from 'react';
import styled from 'styled-components';

import { DefaultCandidatePhoto } from '../../../assets/images';
import colors from '../../../theme/colors';
import Recipient from '../../../types/Recipient';

const PortraitContainer = styled.div`
  position: relative;

  max-width: 5rem;
`;

const StyledPicture = styled.img`
  width: 5rem;
  height: 5rem;

  border-radius: 50%;
`;

const StyledCandidateInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  margin: 0 1rem;
`;

const StyledTextLow = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};
`;

const StyledRecipientSelectorListItem = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr 4rem;
  grid-template-rows: 6rem;
  margin: 1rem;

  cursor: pointer;
`;

interface RecipientSelectorListItemProps {
  recipient: Recipient;
}

const RecipientSelectorListItem: React.FC<RecipientSelectorListItemProps> = ({ recipient }) => {
  return (
    <StyledRecipientSelectorListItem>
      <PortraitContainer>
        <StyledPicture src={recipient.photo || DefaultCandidatePhoto} />
      </PortraitContainer>
      <StyledCandidateInformation>
        <div>{recipient.fullname}</div>
        <StyledTextLow>{recipient.title}</StyledTextLow>
      </StyledCandidateInformation>
    </StyledRecipientSelectorListItem>
  );
};

export default RecipientSelectorListItem;
