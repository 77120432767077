import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import GET_INTEREST_ROLE_SELECTION from '../../../queries/getInterestRoleSelection.gql';
import { GetInterestRolesSelection } from '../../../generated/GetInterestRolesSelection';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { selectionAsInterestRole } from '../../../transformers/interestRolesSelection';
import { FilterOption } from '../../../types/FilterValueType';
import InterestRole from '../../../types/InterestRole';
import { useFilter } from '../../../utils/useFilter';
import Spinner from '../../Spinner';
import MultiCheckFilter from '../MultiCheckFilter';

export interface InterestRolesFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const optionsValues = options.map(({ filterValue }) => filterValue);
  const queryGroupFilter: WhereQueryInterface = {
    enabled: !!optionsValues.length,
    operator: '_and',
    whereQuery: { interest_roles: { role: { _in: optionsValues } } },
  };
  return queryGroupFilter;
};

const InterestRolesFilter: React.FC<InterestRolesFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const { data, loading } = useQuery<GetInterestRolesSelection>(GET_INTEREST_ROLE_SELECTION);

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const options: FilterOption[] = useMemo(() => {
    const rolesTypes: InterestRole[] =
      data?.grad_interest_role_selection.map((gp) => selectionAsInterestRole(gp)) || [];
    return rolesTypes.map(({ id, roleType }) => ({
      filterValue: roleType,
      displayValue: roleType,
      id: id,
    }));
  }, [data]);

  return loading ? (
    <Spinner />
  ) : (
    <MultiCheckFilter
      filterTitle={'Firms of interest'}
      options={options}
      defaultOptions={(filterValue as FilterOption[]) || []}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    />
  );
};
export default InterestRolesFilter;
