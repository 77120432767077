import styled from 'styled-components';

const ViewTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: 900;

  line-height: 2.8rem;
`;

export default ViewTitle;
