import React from 'react';
import styled from 'styled-components';
import { PlusSign } from '../../../assets/vectors';

const StyledTemplateItem = styled.div`
  display: grid;
  grid-template-columns: auto 3rem;
  grid-row-gap: 0.5rem;

  margin: 1rem;
`;

const StyledTitle = styled.div`
  text-align: right;
  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 700;

  cursor: pointer;
`;

const StyledPlusIcon = styled(PlusSign)`
  margin: auto;
  width: 2.4rem;

  font-size: 1.4rem;
  line-height: 2.8rem;

  cursor: pointer;
`;

const TemplateListItemNew: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <StyledTemplateItem>
      <StyledTitle onClick={onClick}>New template</StyledTitle>
      <StyledPlusIcon onClick={onClick} />
    </StyledTemplateItem>
  );
};

export default TemplateListItemNew;
