import styled from 'styled-components';

export const StyledCandidatesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding-left: 1.5rem;

  & > * {
    margin-right: 0.5rem;
    margin-bottom: 0.7rem;
  }
`;
