import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { PlusSign, GroupCheck } from '../../assets/vectors';
import { NewGroup } from '../../types/Group';
import Spinner from '../../components/Spinner';
import TextInput from '../../components/TextInput';
import { colors } from '../../theme';

const StyledMenuOption = styled.div`
  display: grid;
  grid-template-columns: 3.4rem auto 3.4rem;
  grid-template-rows: 3.4rem;
  grid-template-areas: 'plus input save';

  margin: 2rem 0;

  font-size: 1.4rem;
  line-height: 3.4rem;
`;

const StyledTextInput = styled(TextInput)`
  grid-area: input;
`;

const StyledAddNewText = styled.div`
  grid-area: input;

  margin-right: auto;
  max-width: 10rem;

  cursor: pointer;

  &:after {
    content: 'Add new group';
  }
`;

const StyledGroupCheck = styled(GroupCheck)<{ checked: boolean }>`
  grid-area: save;

  margin: auto;
  width: 2.4rem;

  font-size: 1.4rem;
  line-height: 2.8rem;

  cursor: pointer;

  ${({ checked }) =>
    checked
      ? css`
          opacity: 0.5;
        `
      : css`
          cursor: pointer;
        `}
`;

const StyledPlus = styled(PlusSign)<{ checked: boolean }>`
  grid-area: plus;

  transition: transform 0.2s ease-in-out;

  cursor: pointer;

  ${({ checked }) =>
    checked &&
    css`
      transform: rotate(45deg);
    `}
`;

const StyledNone = styled.div`
  grid-area: save;

  color: ${colors.red};

  text-decoration: underline;

  cursor: pointer;

  &:after {
    content: 'None';
  }
`;

const StyledSpinner = styled(Spinner)`
  grid-area: input;

  text-align: center;
`;

export interface GroupModalNewGroupProps {
  onSave: (group: NewGroup) => void;
  setSelectedGroupId: (groupId: string) => void;
  loadingNewGroup: boolean;
}

const GroupModalNewGroup: React.FC<GroupModalNewGroupProps> = ({
  onSave,
  setSelectedGroupId,
  loadingNewGroup = false,
}) => {
  const [name, setName] = useState('');
  const [expanded, setExpanded] = useState(false);

  const nameValid = name?.length > 0;

  const handleClickPlus = () => {
    onSave({ name });
    setName('');
    setExpanded((e) => !e);
  };

  const handlePlus = () => setExpanded((e) => !e);

  return (
    <>
      {loadingNewGroup ? (
        <StyledMenuOption>
          <StyledSpinner>
            <Spinner />
          </StyledSpinner>
        </StyledMenuOption>
      ) : (
        <StyledMenuOption>
          <StyledPlus checked={expanded} onClick={handlePlus} />
          {expanded ? (
            <>
              <StyledTextInput placeholder="Group name" value={name} onChange={setName} />
              <StyledGroupCheck checked={nameValid} onClick={nameValid ? handleClickPlus : undefined} />
            </>
          ) : (
            <>
              <StyledAddNewText onClick={handlePlus} />
              <StyledNone onClick={() => setSelectedGroupId('')} />
            </>
          )}
        </StyledMenuOption>
      )}
    </>
  );
};

export default GroupModalNewGroup;
