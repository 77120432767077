import { useLazyQuery } from '@apollo/client';
import { DownloadCVById, DownloadCVByIdVariables } from 'elitegrad-common/src/generated/DownloadCVById';
import DOWNLOAD_CV_BY_ID from 'elitegrad-common/src/queries/downloadCVById.gql';
import { downloadFile, previewFile } from '../../../../utils/FileManagement';

export const useDownloadCV = (
  uploadDocId: string,
  hexContent: string,
  setDownloadinStatus: (status: boolean) => void,
) => {
  const [downloadCV] = useLazyQuery<DownloadCVById, DownloadCVByIdVariables>(DOWNLOAD_CV_BY_ID, {
    variables: { uploadDocId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { hex_content: content, filename } = data?.grad_upload_doc[0] || {};
      if (!content || !filename) return;

      downloadFile(filename, hexContent);
      setDownloadinStatus(false);
    },
    onError: () => {
      setDownloadinStatus(false);
    },
  });
  return downloadCV;
};

export const usePreviewCV = (uploadDocId: string, setPreviewStatus: (status: boolean) => void) => {
  const [previewCV] = useLazyQuery<DownloadCVById, DownloadCVByIdVariables>(DOWNLOAD_CV_BY_ID, {
    variables: { uploadDocId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { hex_content: content, filename } = data?.grad_upload_doc[0] || {};
      if (!content || !filename) return;
      previewFile(content.replace('data:application/pdf;base64,', ''), 'application/pdf');
      setPreviewStatus(false);
    },
    onError: () => {
      setPreviewStatus(false);
    },
  });

  return previewCV;
};
