import React from 'react';
import WithSeparator from 'react-with-separator';

import Header from 'elitegrad-common/src/components/Header';
import StatusHidden from 'components/events/EventStatusHidden';
import StatusEdit from 'components/events/EventStatusEdit';
import StatusDelete from 'components/events/EventStatusDelete';

import { HeaderOptions, HeaderVerticalDivider } from './EventDetailHeader.styled';

const EventDetailHeader = ({ eventID, isHidden }) => {
  return (
    <Header backLink>
      <HeaderOptions>
        <WithSeparator separator={<HeaderVerticalDivider />}>
          <StatusHidden eventID={eventID} isHidden={isHidden} />
          <StatusEdit eventID={eventID} />
          <StatusDelete eventID={eventID} />
        </WithSeparator>
      </HeaderOptions>
    </Header>
  );
};

export default EventDetailHeader;
