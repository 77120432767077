import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../theme/colors';

interface Props {
  active?: boolean;
  disabled?: boolean;
  previous?: boolean;
  previousActive?: boolean;
  next?: boolean;
  text: string;
  onClick?: () => void;
}

type StyledPageButtonProps = Omit<Props, 'onClick' | 'text'>;

const StyledPageButton = styled.button.attrs({
  type: 'button',
})<StyledPageButtonProps>`
  min-width: 3.2rem;
  height: 3.2rem;
  padding: 0 0.5rem;
  border: 1px solid ${colors.gray_l1};
  border-right: none;

  background-color: ${colors.white};
  cursor: pointer;

  font-family: Roboto;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: ${colors.gray_dark};

  &:focus {
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;

      color: ${colors.gray_l1};
    `}

  ${({ previousActive }) =>
    previousActive &&
    css`
      border-left: none;
    `}

  ${({ active }) =>
    active &&
    css`
      border-right: 1px solid;
      border-color: ${colors.red};

      background-color: ${colors.red};

      color: ${colors.white};
    `}

  ${({ previous }) =>
    previous &&
    css`
      min-width: 10rem;

      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    `}

  ${({ next }) =>
    next &&
    css`
      min-width: 10rem;
      border-right: 1px solid ${colors.gray_l1};
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    `}
`;

const PaginationButton: React.FC<Props> = ({
  active = false,
  previous = false,
  previousActive = false,
  next = false,
  disabled = false,
  text,
  onClick,
}) => {
  return (
    <StyledPageButton
      active={active}
      disabled={disabled}
      next={next}
      previous={previous}
      previousActive={previousActive}
      onClick={onClick}
    >
      {text}
    </StyledPageButton>
  );
};

export default PaginationButton;
