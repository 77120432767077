import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { asCandidateWithProfileImage } from 'elitegrad-common/src/transformers/candidates';
import GET_CANDIDATE from 'elitegrad-common/src/queries/getCandidate.gql';
import { GetCandidate, GetCandidateVariables } from 'elitegrad-common/src/generated/GetCandidate';
import Candidate from './Candidate';

const JobController: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const { data, loading: loadingCandidate } = useQuery<GetCandidate, GetCandidateVariables>(GET_CANDIDATE, {
    skip: !userId,
    variables: { admin: false, userId: userId || '' },
  });

  const candidate = data?.grad_profile.map((c) => asCandidateWithProfileImage(c))[0] || null;

  return <Candidate candidate={candidate} loadingCandidate={loadingCandidate} />;
};

export default JobController;
