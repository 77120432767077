export interface FilterOption {
  filterValue: string;
  displayValue: string;
  id: string;
}

export type RangeType = [number, number];

export interface RangeAndBool {
  range: RangeType;
  bool: boolean;
}

export type FilterValueType =
  | null
  | FilterOption[]
  | RangeAndBool
  | RangeType
  | boolean
  | string
  | { [property: string]: FilterValueType };

export const assertRangeType = (maybeRange: FilterValueType): maybeRange is RangeType => {
  if (!(maybeRange instanceof Array)) return false;
  if (maybeRange === null) return false;

  const [maybeMin, maybeMax] = maybeRange;
  const correctTypes = typeof maybeMin === 'number' && typeof maybeMax === 'number';
  const lengthCorrect = maybeRange.length === 2;
  return correctTypes && lengthCorrect;
};

export const assertRangeBooleanType = (maybeRangeBoolean: FilterValueType): maybeRangeBoolean is RangeAndBool => {
  if (maybeRangeBoolean instanceof Array) return false;
  if (maybeRangeBoolean === null) return false;

  const hasRange = ((maybeRangeBoolean as unknown) as RangeAndBool).range !== undefined;
  const hasBool = ((maybeRangeBoolean as unknown) as RangeAndBool).bool !== undefined;

  return hasRange && hasBool;
};

export const assertFilterOption = (maybeFilterOptions: FilterValueType): maybeFilterOptions is FilterOption[] => {
  if (!(maybeFilterOptions instanceof Array)) return false;
  if (maybeFilterOptions.length === 0) return false;
  if (maybeFilterOptions === null) return false;
  if (assertRangeType(maybeFilterOptions)) return false;
  return maybeFilterOptions.every((mfo: unknown) => (mfo as FilterOption).filterValue !== undefined);
};

export const assertBoolean = (maybeBoolean: FilterValueType): maybeBoolean is boolean =>
  typeof maybeBoolean === 'boolean';
