import styled, { css } from 'styled-components';
import { MenuMoreVertical } from 'elitegrad-common/src/assets/vectors';
import { colors } from 'elitegrad-common/src/theme';

export const StyledDotMenuIcon = styled(MenuMoreVertical)<{ props: { lay: boolean } }>`
  z-index: 1;

  width: 2.4rem;
  height: 2rem;

  transition: transform 0.2s ease-in-out;

  ${({ props }) =>
    props.lay &&
    css`
      transform: rotate(90deg);
    `}
`;

export const StyledMenu = styled.div`
  position: relative;
  grid-area: menu;
`;

export const StyledOpenMenu = styled.div`
  position: absolute;
  z-index: 1;
  top: 2.4rem;
  right: 0;

  display: flex;
  flex-direction: column;

  padding: 0.5rem;
  min-width: 20rem;

  background: ${colors.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;
