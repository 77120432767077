import React from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';

import DatePicker from 'elitegrad-common/src/components/DatePicker';
import FormInput, { LabelPosition } from 'elitegrad-common/src/components/FormInput';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import TextAreaInput from 'elitegrad-common/src/components/TextAreaInput';
import TextInput from 'elitegrad-common/src/components/TextInput';
import SecondaryButton from 'elitegrad-common/src/components/SecondaryButton';
import Job from 'elitegrad-common/src/types/Job';
import JobType from 'elitegrad-common/src/types/JobType';
import Page from 'views/Page';
import RegionPickerController from './RegionPickerController';
import Header from 'elitegrad-common/src/components/Header';
import ViewTitle from 'elitegrad-common/src/components/ViewTitle';
import RequirementsSideBarController from './RequirementsSideBar';
import RadioButton from 'elitegrad-common/src/components/RadioButton';
import InputError from 'elitegrad-common/src/components/InputError';

const StyledForm = styled.form`
  display: grid;
  grid-row-gap: 2rem;

  width: 50rem;
  margin: 0 auto;
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 1rem;
`;

export interface JobProps {
  job?: Job;
  newJob?: boolean;
  onCancel?: () => void;
  onSave?: (job: Job) => void;
}

const EditJobForm: React.FC<JobProps> = ({
  newJob,
  job = {} as Job,
  onCancel = () => undefined,
  onSave = () => undefined,
}) => {
  const formik = useFormik({
    initialValues: {
      title: job.title,
      jobType: job.jobType || JobType.FULL_TIME,
      description: job.description,
      deadline: job.deadline,
      regionName: job.region,
      requirements: job.requirements,
    },
    validate: (values) => ({
      ...(!values.title && { title: 'Required' }),
      ...(!values.description && { description: 'Required' }),
      ...(!values.regionName && { regionName: 'Required' }),
    }),
    onSubmit: (values) => {
      onSave({
        ...job,
        description: values.description || '',
        title: values.title || '',
        jobType: values.jobType,
        deadline: values.deadline,
        region: values.regionName,
        requirements: values.requirements,
      });
    },
  });

  return (
    <Page
      extraSidebar={
        <RequirementsSideBarController
          onChange={(v) => formik.setFieldValue('requirements', v)}
          value={formik.values.requirements}
        />
      }
      headerComponent={<Header backLink={true} />}
    >
      <StyledForm onSubmit={formik.handleSubmit}>
        <ViewTitle>{newJob ? 'New Job' : 'Edit Job'}</ViewTitle>
        <FormInput label="Position Title">
          <TextInput value={formik.values.title} onChange={(v) => formik.setFieldValue('title', v)} />
          <InputError error={formik.errors.title} />
        </FormInput>
        <FormInput label="Place">
          <RegionPickerController
            value={formik.values.regionName}
            onChange={(v) => formik.setFieldValue('regionName', v)}
          />
          <InputError error={formik.errors.regionName} />
        </FormInput>
        <FormInput label="Deadline">
          <DatePicker
            id="jobDeadline"
            value={formik.values.deadline}
            onChange={(v) => formik.setFieldValue('deadline', v)}
          />
          <InputError error={formik.errors.deadline} />
        </FormInput>
        <FormInput label="Job Type">
          <FormInput label="Full Time" labelPosition={LabelPosition.Right}>
            <RadioButton
              checked={formik.values.jobType === JobType.FULL_TIME}
              onClick={() => formik.setFieldValue('jobType', JobType.FULL_TIME)}
            />
          </FormInput>
          <FormInput label="Internship" labelPosition={LabelPosition.Right}>
            <RadioButton
              checked={formik.values.jobType === JobType.INTERNSHIP}
              onClick={() => formik.setFieldValue('jobType', JobType.INTERNSHIP)}
            />
          </FormInput>
          <InputError error={formik.errors.jobType} />
        </FormInput>

        <FormInput label="Description">
          <TextAreaInput value={formik.values.description} onChange={(v) => formik.setFieldValue('description', v)} />
          <InputError error={formik.errors.description} />
        </FormInput>
        <StyledButtons>
          <SecondaryButton big text="Cancel" onClick={onCancel} />
          <StyledPrimaryButton big submit text="Save" />
        </StyledButtons>
      </StyledForm>
    </Page>
  );
};

export default EditJobForm;
