import React from 'react';

import { Calendar } from 'elitegrad-common/src/assets/vectors';
import ViewTitle from 'elitegrad-common/src/components/ViewTitle';

import { useFilterGetter } from 'elitegrad-common/src/utils/useFilter';
import FiltersGroup from 'elitegrad-common/src/components/filters/FiltersGroup';
import FilterItem from 'elitegrad-common/src/components/filters/FilterItem';
import {
  ACTFilter,
  GPAFilter,
  GroupFilter,
  GMATFilter,
  UniversityFilter,
  MajorFilter,
  InterestRegionsFilter,
  InterestFirmsFilter,
  InterestJobTypesFilter,
  ProfessionalDesignationFilter,
  UndergradDegreeDateFilter,
  GraduateDegreeDateFilter,
  AthleteFilter,
  LanguageFilter,
  InterestRolesFilter,
  ComputerLanguageFilter,
  GREFilter,
  RelevantYearsOfWorkFilter,
  GroupedDegreeFilter,
  NameFilter,
  LastNameFilter,
  SATFilterDetailed,
  CombineFilter,
  SponsorshipFilter,
} from 'elitegrad-common/src/components/filters/queryFilters';
import FilterClear from 'elitegrad-common/src/components/filters/FilterClear';
import InterestJobTypesToggleFilter from 'elitegrad-common/src/components/filters/queryFilters/InterestJobTypesToggleFilter';

import { StyledFilterSideBar, ChevronDownRed, StyledTitle } from './FilterSideBar.syled';

const FilterSideBar: React.FC = () => {
  const { filterActiveWithId } = useFilterGetter();

  return (
    <StyledFilterSideBar>
      <StyledTitle>
        <ViewTitle>Filters</ViewTitle>
        <FilterClear />
      </StyledTitle>

      <FiltersGroup name="Quick Filters">
        <CombineFilter name={'CombineFilter'} />
        <NameFilter name={'NameFilter'} />
        <LastNameFilter name={'LastNameFilter'} />
        <InterestJobTypesToggleFilter name={'InterestJobTypesToggleFilter'} />
      </FiltersGroup>

      <FiltersGroup>
        <FilterItem text="My Groups" icon={ChevronDownRed} filterActive={filterActiveWithId('GroupFilter')}>
          <GroupFilter name={'GroupFilter'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Legal">
        <FilterItem text="Sponsorship" icon={ChevronDownRed} filterActive={filterActiveWithId('SponsorshipFilter')}>
          <SponsorshipFilter name={'SponsorshipFilter'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Academic">
        <FilterItem text="Athlete" icon={ChevronDownRed} filterActive={filterActiveWithId('AthleteFilter')}>
          <AthleteFilter name={'AthleteFilter'} />
        </FilterItem>
        <FilterItem text="Schools" icon={ChevronDownRed} filterActive={filterActiveWithId('UniversityFilter')}>
          <UniversityFilter name={'UniversityFilter'} />
        </FilterItem>
        <FilterItem text="Degree" icon={ChevronDownRed} filterActive={filterActiveWithId('DegreeFilter')}>
          <GroupedDegreeFilter name={'DegreeFilter'} />
        </FilterItem>
        <FilterItem
          text="Year Undergrad Graduation"
          icon={Calendar}
          filterActive={filterActiveWithId('UndergradDegreeDateFilter')}
        >
          <UndergradDegreeDateFilter name={'UndergradDegreeDateFilter'} />
        </FilterItem>
        <FilterItem
          text="Year Advanced Degree Graduation"
          icon={Calendar}
          filterActive={filterActiveWithId('GraduateDegreeDateFilter')}
        >
          <GraduateDegreeDateFilter name={'GraduateDegreeDateFilter'} />
        </FilterItem>
        <FilterItem text="GPA" icon={ChevronDownRed} filterActive={filterActiveWithId('GPAFilter')}>
          <GPAFilter name={'GPAFilter'} nameNonApplicable={'GPAFilterNotApplicable'} />
        </FilterItem>
        <FilterItem text="Major" icon={ChevronDownRed} filterActive={filterActiveWithId('MajorFilter')}>
          <MajorFilter name={'MajorFilter'} />
        </FilterItem>

        <FilterItem
          text="Work Experience / Prior Internships"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('RelevantYearsOfWorkFilter')}
        >
          <RelevantYearsOfWorkFilter name={'RelevantYearsOfWorkFilter'} />
        </FilterItem>

        <FilterItem
          text="Professional Designation"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('ProfessionalDesignationFilter')}
        >
          <ProfessionalDesignationFilter name={'ProfessionalDesignationFilter'} />
        </FilterItem>

        <FilterItem text="Language" icon={ChevronDownRed} filterActive={filterActiveWithId('LanguageFilter')}>
          <LanguageFilter name={'LanguageFilter'} />
        </FilterItem>
        <FilterItem
          text="Technical Language"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('ComputerLanguageFilter')}
        >
          <ComputerLanguageFilter name={'ComputerLanguageFilter'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Test">
        <FilterItem text="SAT" icon={ChevronDownRed} filterActive={filterActiveWithId('SATFilterDetailed')}>
          <SATFilterDetailed name={'SATFilterDetailed'} />
        </FilterItem>
        <FilterItem text="ACT" icon={ChevronDownRed} filterActive={filterActiveWithId('ACTFilter')}>
          <ACTFilter name={'ACTFilter'} nameNonApplicable={'ACTFilterNotApplicable'} />
        </FilterItem>
        <FilterItem text="GMAT" icon={ChevronDownRed} filterActive={filterActiveWithId('GMATFilter')}>
          <GMATFilter name={'GMATFilter'} />
        </FilterItem>
        <FilterItem text="GRE" icon={ChevronDownRed} filterActive={filterActiveWithId('GREFilter')}>
          <GREFilter name={'GREFilter'} nameNonApplicable={'GREFilterNotApplicable'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Interest">
        <FilterItem text="Firm Types" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestFirmsFilter')}>
          <InterestFirmsFilter name={'InterestFirmsFilter'} />
        </FilterItem>
        <FilterItem text="Jobs" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestJobTypesFilter')}>
          <InterestJobTypesFilter name={'InterestJobTypesFilter'} />
        </FilterItem>
        <FilterItem text="Roles" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestRolesFilter')}>
          <InterestRolesFilter name={'InterestRolesFilter'} />
        </FilterItem>
        <FilterItem text="Regions" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestRegionsFilter')}>
          <InterestRegionsFilter name={'InterestRegionsFilter'} />
        </FilterItem>
      </FiltersGroup>
    </StyledFilterSideBar>
  );
};

export default FilterSideBar;
