import React from 'react';
import styled from 'styled-components';

import colors from '../../theme/colors';

const StyledCandidateTag = styled.span`
  display: inline;
  width: 8rem;

  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  border-radius: 3px;

  background-color: ${colors.red};

  font-size: 1.1rem;
  font-weight: 900;
  line-height: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
`;

export interface CandidateTagProps {
    text: string
}

const CandidateTag: React.FC<CandidateTagProps> = ({text}: CandidateTagProps) => {
    return <StyledCandidateTag>{text}</StyledCandidateTag>;
};

export default CandidateTag;
