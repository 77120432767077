import styled from 'styled-components';

import { colors } from 'elitegrad-common/src/theme';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 22.5rem;
  height: 20.8rem;

  padding: 1.4rem 1.8rem;
  margin: 2rem 1rem;

  box-shadow: 0 2px 17px ${colors.gray_light};
  border-radius: 8px;

  background-color: ${colors.white};
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const CardHeaderIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 4rem;
  width: 5rem;

  span {
    display: felx;
    justify-content: center;
    padding: 1px 4px;
    border-radius: 5px;
    margin-bottom: 1.5rem;
    width: 100%;
    height: 2rem;
    background-color: ${colors.red};
    color: white;
  }

  [alt='capacity'] {
    margin-right: 0.3rem;
  }
`;

export const DateContainer = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 0.95rem;
`;

export const HiddenContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.4rem;
  font-size: 1.2rem;
`;

export const HiddenTag = styled.div`
  color: ${colors.red};
`;

export const ActiveTag = styled.div`
  color: ${colors.green_light};
`;

export const Separator = styled.div`
  margin: 0.4rem 0;
`;

export const TitleContainer = styled.h1`
  font-size: 2rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-decoration: inherit;
  color: inherit;
`;
