import gql from 'graphql-tag';

import { DegreeSelectorParts } from './fragments.gql';

export default gql`
  query GetDegreeSelection($degree_name: String) {
    grad_degree_selection(where: { degree: { _ilike: $degree_name } }, limit: 1000) {
      ...DegreeSelectorParts
    }
  }
  ${DegreeSelectorParts}
`;
