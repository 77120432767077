import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GetTemplates } from '../../../generated/GetTemplates';
import GET_TEMPLATES from '../../../queries/getTemplates.gql';
import { asTemplate } from '../../../transformers/templates';
import TemplateList from './TemplateList';

const TemplateListController: React.FC = () => {
  const { data, loading } = useQuery<GetTemplates>(GET_TEMPLATES);
  const templates = useMemo(() => {
    return data?.recruit_sms_template.map(asTemplate) || [];
  }, [data]);

  return <TemplateList loading={loading} templates={templates} />;
};

export default TemplateListController;
