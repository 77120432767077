import React, { useEffect, useState } from 'react';

import { INITIAL_OFFSET, PAGE_SIZE } from 'elitegrad-common/src/components/pagination/PaginationState';
import { asCandidatesWithProfileImages } from 'elitegrad-common/src/transformers/candidates';
import { usePhoto } from 'elitegrad-common/src/utils/usePhoto';
import CandidateType from 'elitegrad-common/src/types/Candidate';
import { GetCandidatesVariables } from 'elitegrad-common/src/generated/GetCandidates';
import { generateWhereQuery } from 'elitegrad-common/src/queries/queryUtils';
import { useFilterQueries, usePagination } from 'elitegrad-common/src/utils/useFilter';

import { useDownloadCandidateByUsersId, useCountCVByCandidates, useFetchCandidates } from './CandidatesList.hook';
import CandidatesList from './CandidatesList';

const CandidatesListController: React.FC = () => {
  const [selectedCandidates, setSelectedCandidates] = useState<CandidateType[]>([]);
  const [downloadingCVs, setDownloadingCVs] = useState<boolean>(false);
  const { setOffSet, getOffset } = usePagination();

  const { getFilterQueries } = useFilterQueries();
  const dynamicQuery = Object.keys(getFilterQueries()).length > 0 ? generateWhereQuery(getFilterQueries()) : null;

  const getCandidatesVariables: GetCandidatesVariables = {
    ...(dynamicQuery && { dynamicQuery }),
    limit: PAGE_SIZE,
    offset: getOffset(),
    admin: false,
  };

  const [fetchCVsCountByUsersId, { data: dataCountResumes }] = useCountCVByCandidates();
  const fetchCVsByUsersId = useDownloadCandidateByUsersId(selectedCandidates, setDownloadingCVs);

  const handleOnDownloadCvsClick = () => {
    setDownloadingCVs(true);
    const userIds = selectedCandidates.map((candidate) => candidate.userId);
    fetchCVsByUsersId({
      variables: {
        userIds,
      },
    });
  };
  const { showPhoto } = usePhoto();
  const [{ data, loading, refetch }, imagesData] = useFetchCandidates(getCandidatesVariables, showPhoto);

  const candidates = asCandidatesWithProfileImages(
    data?.grad_profile || [],
    showPhoto ? imagesData?.grad_profile || [] : [],
  );

  const candidateQueryCount = data?.grad_profile_aggregate?.aggregate?.count || 0;

  const editSelection = (candidate: CandidateType, add: boolean) => {
    if (add) {
      setSelectedCandidates((e: CandidateType[]) => [...e, candidate]);
    } else {
      setSelectedCandidates((e: CandidateType[]) => e.filter(({ userId }) => userId !== candidate.userId));
    }
  };

  const clearSelection = () => setSelectedCandidates([]);

  const cvsCount = dataCountResumes?.grad_upload_doc_aggregate?.aggregate?.count;

  useEffect(() => {
    const userIds = selectedCandidates.map((cand) => cand.userId);
    fetchCVsCountByUsersId({
      variables: {
        userIds,
      },
    });
  }, [selectedCandidates, fetchCVsCountByUsersId]);

  const selectAllCandidates = () => setSelectedCandidates(Boolean(selectedCandidates.length) ? [] : candidates);

  return (
    <CandidatesList
      refetch={refetch}
      pagination={{
        count: candidateQueryCount,
        limit: getCandidatesVariables.limit || PAGE_SIZE,
        offset: getCandidatesVariables.offset || INITIAL_OFFSET,
      }}
      onClickDownloadCvs={handleOnDownloadCvsClick}
      onChangePaginationOffset={setOffSet}
      candidates={candidates}
      cvsCount={cvsCount ? cvsCount : 0}
      loadingCandidates={loading}
      downloadingCVs={downloadingCVs}
      editSelection={editSelection}
      clearSelection={clearSelection}
      selectedCandidates={selectedCandidates}
      selectAllCandidates={selectAllCandidates}
    />
  );
};

export default CandidatesListController;
