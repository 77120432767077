import React from 'react';
import styled from 'styled-components';

import colors from '../theme/colors';

const StyledInternshipTag = styled.span`
  display: inline-block;
  width: 8rem;

  padding: 0.2rem 0.2rem 0;
  border-radius: 3px;

  background-color: ${colors.red};

  font-size: 1.1rem;
  font-weight: 900;
  line-height: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
`;

const InternshipInterestTag: React.FC = (props) => {
  return <StyledInternshipTag {...props}>Intern</StyledInternshipTag>;
};

export default InternshipInterestTag;
