import React from 'react';
import styled from 'styled-components';

import Degree, { getMajorName } from '../../types/Degree';
import { colors } from '../../theme';
import { CandidateGPADisplay } from '../../components/candidate';
import { formatMonthYear } from '../../utils/dateUtils';

const StyledDegree = styled.div`
  height: 6.5rem;
  padding: 0 0.5rem;

  overflow-y: auto;
  overflow-x: hidden;
  overflow-wrap: break-word;
`;

const StyledDatePeriod = styled.div<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.4rem' : '1rem')};
  line-height: ${({ big }) => (big ? '1.5rem' : '1.1rem')};

  color: ${colors.gray};
`;

const StyledMajor = styled.div<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.6rem' : '1.3rem')};
  line-height: ${({ big }) => (big ? '1.8rem' : '1.6rem')};
`;

const StyledUniversity = styled.div<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.4rem' : '1rem')};
  line-height: ${({ big }) => (big ? '1.8rem' : '1.6rem')};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

interface CandidatePreviousDegreeProps {
  degree: Degree;
  big?: boolean;
}

const CandidatePreviousDegree: React.FC<CandidatePreviousDegreeProps> = ({ degree, big = false }) => {
  return (
    <StyledDegree>
      <StyledDatePeriod big={big}>
        {degree.startDate && `From ${formatMonthYear(degree.startDate)}`}
        {degree.startDate && degree.completeDate && ' to '}
        {degree.completeDate && `${formatMonthYear(degree.completeDate)}`}
      </StyledDatePeriod>
      <StyledMajor big={big}>{getMajorName(degree)}</StyledMajor>
      <StyledUniversity big={big}>
        {degree.degreeName} {degree.university?.name}
      </StyledUniversity>

      <CandidateGPADisplay
        big={big}
        gpa={degree.gpa}
        gpaOutOf={degree.gpaOutOf}
        gpaNotApplicable={degree.gpaNotApplicable}
      />
    </StyledDegree>
  );
};

export default CandidatePreviousDegree;
