import React from 'react';
import styled from 'styled-components';

import { colors } from '../theme';
import WorkExperience, { isCurrentWork } from '../types/WorkExperience';

const StyledTag = styled.span`
  display: inline-block;
  padding: 0.2rem 0.4rem 0.2rem;

  background-color: ${colors.red};
  border-radius: 2px;

  font-size: 1rem;
  font-weight: 900;

  color: ${colors.white};
  line-height: 1.1rem;
  text-transform: uppercase;
`;

export interface CurrentJobProps {
  workExperience: WorkExperience;
}

const CurrentJobTag: React.FC<CurrentJobProps> = ({ workExperience, ...props }) => {
  const opacity = isCurrentWork(workExperience) ? 1 : 0;

  return (
    <StyledTag style={{ opacity }} {...props}>
      Current
    </StyledTag>
  );
};

export default CurrentJobTag;
