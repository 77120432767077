import gql from 'graphql-tag';

import { CompanyParts } from './fragments.gql';

export default gql`
  query GetCompany($id: String!) {
    recruit_company(where: { account: { recruiters: { user_id: { _eq: $id } } } }) {
      ...CompanyParts
    }
  }
  ${CompanyParts}
`;
