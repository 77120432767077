import React, { useCallback, useMemo } from 'react';

import { ApolloProvider } from '@apollo/client';

import { createClient as createApolloClient } from './apollo';
import { useAuth } from './react-auth';

const Provider: React.FC = ({ children }) => {
  const { authToken, doLogout, role } = useAuth();

  const handleGraphQLError = useCallback(() => {
    doLogout();
  }, [doLogout]);

  const client = useMemo(() => {
    return createApolloClient(authToken, handleGraphQLError, role);
  }, [authToken, handleGraphQLError, role]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { Provider };
