import gql from 'graphql-tag';

import { UniversityParts } from './fragments.gql';

export default gql`
  query GetUniversitySelection($location_name: String!) {
    grad_university_selection(where: { location_name: { _ilike: $location_name } }, limit: 10) {
      ...UniversityParts
    }
  }
  ${UniversityParts}
`;
